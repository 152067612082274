import { Cake, Comment, Drafts, Email, Inbox, LinkedIn, MoreVert, Padding, Person, Phone, Place } from '@mui/icons-material'
import { Avatar, Box, Button, Card, CardHeader, Checkbox, Divider, Grid, IconButton, Link, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Skeleton, Typography, useMediaQuery } from '@mui/material'
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PersonalDetails from './PersonalDetails';
import { useSelector } from 'react-redux';
import badge1 from "../assets/images/badge1.svg"
import badge2 from "../assets/images/badge2.svg"
import badge3 from "../assets/images/badge3.svg"
import badge4 from "../assets/images/badge4.svg"


function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
function Profile() {
    const [value, setValue] = useState(0);
    const [loading, setLoading] = useState(true);
    const generativeAi = useSelector(state => state.genaiActiveStatus.genaiActiveStatus.genAIactiveStatus)
    const userData = useSelector(state => state.user.userListName)
    const gamificationActiveStatus = useSelector(state =>state?.gamificationActiveStatus?.gamificationActiveStatus)
    console.debug("gamificationActiveStatus", gamificationActiveStatus)
    const isSmallScreen = useMediaQuery('(max-width:600px)');
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    useEffect(function () {
        if (userData) {
            setLoading(false)
        }
    }, [Object.keys(userData).length !== 0])
    console.debug("loading", userData)
    return (
        <Box>
            <Box>
                <Typography variant="h6" pb={.7} gutterBottom>My Profile</Typography>
            </Box>
            <Box>
                <Box>
                    <Grid sx={{
                        minHeight: '100vh',
                        position: 'relative',
                        '@media screen and (min-width: 600px)': {
                            '& .grid1, & .grid2': {
                                overflowY: 'auto',
                                maxHeight: '100vh',
                                '&::-webkit-scrollbar': {
                                    width: '0.5em', // Set the width of the scrollbar
                                    display: 'none', // Hide the scrollbar
                                },
                                '&::-webkit-scrollbar-thumb': {
                                    backgroundColor: 'transparent', // Set the color of the thumb
                                },
                            },
                        }
                    }} container spacing={2}>
                        <Grid className="grid1" item xs={12} sm={12} md={4} sx={{}} xl={4}>
                      {  gamificationActiveStatus &&<Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Card sx={{ mb: 2, p: 3 }}>
                                        <Typography variant='h7' fontWeight={500}>Badges</Typography>
                                        <Grid container spacing={3} sx={{ mt: 0.5 }}>
                                            <Grid item>
                                                <img src={badge1} alt="adges" />
                                            </Grid>
                                            <Grid item>
                                                <img src={badge2} alt="adges" />
                                            </Grid>
                                            <Grid item>
                                                <img src={badge3} alt="adges" />
                                            </Grid>
                                            <Grid item>
                                                <img src={badge4} alt="adges" />
                                            </Grid>
                                        </Grid>
                                    </Card>
                                </Grid>
                            </Grid>}
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Card sx={{ mb: 2 }}>
                                        {Object.keys(userData).length == 0 ? <Grid container>
                                            <Grid xs={3}>
                                                <Skeleton variant="circular" width={105} height={105} />
                                            </Grid>
                                            <Grid xs={9} sx={{ pl: 1, pt: 1 }}>
                                                <Skeleton animation="wave" height={20} weight={10} />
                                                <Skeleton animation="wave" height={20} sx={{ mt: 1 }} weight={10} />
                                                <Skeleton animation="wave" height={20} sx={{ mt: 1 }} weight={10} />
                                            </Grid>
                                        </Grid> : <Grid container bgcolor={"rgba(207, 174, 222, 0.46)"} p={2} sx={{borderRadius:1}}>
                                            <Grid xs={3}>
                                                <Avatar sx={isSmallScreen ? { height: 50, width: 50, border: "2px solid white" } : { height: 101, width: 101, border: "2px solid white" }} aria-label="recipe" src={userData && userData.userAvatar} />
                                            </Grid>
                                            <Grid xs={9} sx={{ pl: 1, pt: 1 }}>
                                                <Typography variant="h6">{userData?.userName} {userData?.lastName}</Typography>
                                                <Typography >{userData.employeeId != null ? userData.employeeId : "-"}</Typography>
                                                <Typography>{userData?.designation?.designation || "-"}</Typography>
                                            </Grid>
                                        </Grid>}
                                        <Box sx={{ p: 1 }}>
                                            <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                                                <ListItem
                                                    secondaryAction={<>
                                                        {Object.keys(userData).length == 0 ? <Skeleton animation="wave"  sx={{ mt: 1 }} width={230}/> : <Typography sx={{ textOverflow: "ellipsis", overflow: "hidden" }} >
                                                            {userData?.email}
                                                        </Typography>}
                                                    </>    }    disablePadding >
                                                    <ListItemButton role={undefined} >
                                                        <ListItemIcon>
                                                            <Email />
                                                        </ListItemIcon>

                                                         <ListItemText primary={"Email"} />
                                                    </ListItemButton>
                                                </ListItem>
                                                <Divider />
                                                <ListItem
                                                    secondaryAction={
                                                        <>
                                                        {Object.keys(userData).length == 0 ? <Skeleton animation="wave"  sx={{ mt: 1 }} width={230}/> : <Typography sx={{ textOverflow: "ellipsis", overflow: "hidden" }} >
                                                        {userData?.contactNo}
                                                        </Typography>}
                                                    </> 
                                                       
                                                    }
                                                    disablePadding
                                                >
                                                    <ListItemButton role={undefined} >
                                                        <ListItemIcon>
                                                            <Phone />
                                                        </ListItemIcon>
                                                        <ListItemText primary={"Phone"} />
                                                    </ListItemButton>

                                                </ListItem>
                                                <Divider />
                                                <ListItem

                                                    secondaryAction={
                                                        <>
                                                        {Object.keys(userData).length == 0 ? <Skeleton animation="wave"  sx={{ mt: 1 }} width={230}/> : <Typography sx={{ textOverflow: "ellipsis", overflow: "hidden" }} >
                                                        {userData?.dateOfBirth}
                                                        </Typography>}
                                                    </> 
                                                      
                                                    }
                                                    disablePadding
                                                >
                                                    <ListItemButton role={undefined} >
                                                        <ListItemIcon>
                                                            <Cake />
                                                        </ListItemIcon>
                                                        <ListItemText primary={"Birthday"} />
                                                    </ListItemButton>
                                                </ListItem>
                                                <Divider />

                                                <ListItem
                                                    secondaryAction={
                                                        <>
                                                        {Object.keys(userData).length == 0 ? <Skeleton animation="wave"  sx={{ mt: 1 }} width={230}/> : <Typography sx={{ textOverflow: "ellipsis", overflow: "hidden" }} >
                                                        {userData?.supervisorName?.name ||"-"}
                                                        </Typography>}
                                                    </> 
                                                      
                                                    }
                                                    disablePadding
                                                >
                                                    <ListItemButton role={undefined} >
                                                        <ListItemIcon>
                                                            <Person />
                                                        </ListItemIcon>
                                                        <ListItemText primary={"Report to"} />
                                                    </ListItemButton>
                                                </ListItem>
                                                <Divider />

                                                <ListItem
                                                    secondaryAction={
                                                        <>
                                                        {Object.keys(userData).length == 0 ? <Skeleton animation="wave"  sx={{ mt: 1 }} width={230}/> : <Typography sx={{ textOverflow: "ellipsis", overflow: "hidden" }} >
                                                        {userData?.address ||"-"}
                                                        </Typography>}
                                                    </> 
                                                      
                                                    }
                                                    disablePadding
                                                >
                                                    <ListItemButton role={undefined} >
                                                        <ListItemIcon>
                                                            <Place />
                                                        </ListItemIcon>
                                                        <ListItemText primary={"Address"} />
                                                    </ListItemButton>
                                                </ListItem>
                                                <Divider />

                                                <ListItem
                                                    secondaryAction={
                                                        <>
                                                        {Object.keys(userData).length == 0 ? <Skeleton animation="wave"  sx={{ mt: 1 }} width={230}/> : <Typography sx={{ textOverflow: "ellipsis", overflow: "hidden" }} >
                                                        <Link href={userData?.linkedin} >  {userData?.linkedin ||"-"}</Link>
                                                        </Typography>}
                                                    </> 
                                                     
                                                    }
                                                    disablePadding

                                                >
                                                    <ListItemButton role={undefined} >
                                                        <ListItemIcon>
                                                            <LinkedIn />
                                                        </ListItemIcon>
                                                        <ListItemText primary={"Linkedin"} />
                                                    </ListItemButton>
                                                </ListItem>
                                            </List>
                                        </Box>
                                    </Card>

                                </Grid>
                            </Grid>
                            {generativeAi && <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Card sx={{ mb: 2, p: 3 }}>
                                        <Typography variant='body1' color={"#757575"}>
                                            Given your impressive skills, extensive experience, and expertise with various tools, we would like to propose crafting a LinkedIn post tailored to enhancing your professional network.
                                        </Typography>
                                        <Button variant='contained' sx={{ mt: 3 }} href="/generative-ai">GENERATE DAILY POST FOR LINKEDIN</Button>
                                    </Card>

                                </Grid>
                            </Grid>}
                        </Grid>
                        <Grid item className='height-2 grid2' xs={12} sm={12} md={8} xl={8}>
                            <Card>
                                <Box sx={{ width: '100%' }}>
                                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" variant="scrollable">
                                            <Tab label="Profile" {...a11yProps(0)} />
                                            <Tab label="Activity" {...a11yProps(1)} />
                                            <Tab label="Feed" {...a11yProps(2)} />
                                            <Tab label="Projects" {...a11yProps(2)} />
                                            <Tab label="Hidden Post" {...a11yProps(2)} />
                                        </Tabs>
                                    </Box>
                                    <CustomTabPanel value={value} index={0} className="profileTab">
                                        <PersonalDetails />
                                    </CustomTabPanel>
                                    <CustomTabPanel value={value} index={1}>
                                        Item Two
                                    </CustomTabPanel>
                                    <CustomTabPanel value={value} index={2}>
                                        Item Three
                                    </CustomTabPanel>
                                </Box>
                            </Card>

                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Box>
    )
}

export default Profile
