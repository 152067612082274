import React from 'react';

import {
	// Redirect,
	Navigate,
    useLocation,
} from "react-router-dom";

import { getCurrentUrl, generateUrlWithCallback , getCallback , isLogin} from 'auth-component';
import Login from './Login';

function LoginWrapper(props) {

    let location = useLocation();
	let { from } = location.state || { from: { pathname: "/" } };


    if (isLogin()) {
        if(getCallback()){
            window.location.assign(generateUrlWithCallback(getCurrentUrl()));
        }else{
			// return (<Redirect to={from.pathname} />)
			return (<Navigate to={from.pathname} />)
        }
    }else{
        return (
            <Login/>
        );
    }
}

export default LoginWrapper;