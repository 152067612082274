import React, { useState, useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import { Divider, Button, Alert } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Avatar from '@mui/material/Avatar';
import PersonIcon from '@mui/icons-material/Person';
import CardHeader from '@mui/material/CardHeader';
import { closeDialog } from "../../features/dialogBox/dialogBoxSlice";
import { openImage } from '../../features/uploadPic/ImageSlice';
import { setFormData, setFormUpdate } from '../../features/commonDataSlice/postSlice';
import { useSelector, useDispatch } from 'react-redux';
import ImageIcon from '@mui/icons-material/Image';
import PhotoComponent from '../AddPhotoComponent/PhotoComponent';
import ApiService from '../../utils/ApiService';
import ConfirmDialogBox from '../../features/confirmDialogBox/confirmDialogBox';
import { openConfirmDialog } from '../../features/confirmDialogBox/confirmDialogBoxSlice';
import { getAppToken } from '../../_helper/secureToken';
import { openSnackbar, closeSnackbar } from '../../features/snackbar/snackbarSlice';
import DynamicSnackbar from '../../features/snackbar/Snackbar';
import AddPhotoComponent from '../AddPhotoComponent/AddPhotoComponent';
import { setData } from "../../features/dialogBox/dialogBoxSlice";
import Textarea from "../forms/Textarea";
import transformationHelper from '../../utils/transformationHelper';
import { useNavigate } from 'react-router-dom';

const CreatePostDialog = ({ id, mode, genai }) => {
  let navigate =useNavigate()
  // const [snackBar, setSnackBar] = useState({ dataLoad: false, message: '' });
  const [deletedId, setDeletedId] = useState([]);
  const userData = useSelector((state) => state?.user?.userListName)
  const postData = useSelector((state) => state?.dialog?.data);
  const [image, setImage] = useState([]);
  const [selectedFile, setSelectedFile] = useState([]);
  const fileType = useRef(null);
  const deleteBtn = useRef(null);
  const [showFileError, setShowFileError] = useState(false);
  const [showError, setShowError] = useState(false);
  const [userAvatar, setAvatar] = useState(postData?.userAvatar ?? "");
  const [userName, setUserName] = useState(postData?.userName ?? "");
  const [postBody, setBody] = useState(mode == 'create' ? '' : (postData.postBody ?? ""));
  const [fileImage, setFileImage] = useState(mode == 'create' ? [] : postData?.album?.images.map((d) => {
    return d.image
  }) ?? []);

  const [linkedinShareUrl, setLinkedinShareUrl]=useState("")

  console.debug("postBody", postData)
  // let fileImage = [];
  let fileImageForm = [];
  // const [disabelPost, setDisablePost] = useState(false);

  // console.log({userData});

  const handleClose = () => {
    setShowError(false);
    setShowFileError(false);
    // setOpen(false);
  };


  useEffect(() => {
    // console.log("Change testing", { postData });
    if (mode == 'create') {
      setBody("");
    }
    return () => {
      dispatch(setData({}));
      setBody("");
    };
  }, []);

  const dispatch = useDispatch();
  const openDialogPublish = (id) => {
    dispatch(openConfirmDialog(id));
    // console.log(openDialog(id))
  }

  const handlePostDialogClose = () => {
    dispatch(closeDialog(id));
  };

  const handlePhotoClick = () => {
    dispatch(openImage());
  };

  const handleBothClicks = () => {
    handlePostDialogClose();
    handlePostButtonClick();
    handleConfirmationClick();
    handleConfirmationMessage();
  };

  const handleConfirmationClick = () => {
    setOpen(true);
  };




  const handleConfirmationMessage = () => {


  };

  // ---------------edit post -----------------
  const formLeader = transformationHelper().getTransformationFunction('FeedList')

  const handlePostButtonClick = () => {
    let formData = new FormData();

    const formToSend = {
      postBody,
      album: { images: fileImage.map((v) => ({ image: v })) },
      userAvatar: userData.userAvatar,
      userName: userData.userName,
      userId: userData.userId,
      created_at: new Date().getTime(),
      designationId: userData?.designation?.id ?? null
    };
    console.log("a3", formToSend)

    formData.append('app', getAppToken());

    if (Array.isArray(image) && image.length > 0) {
      image.forEach((item) => {
        formData.append('fileArray[]', item);
      });
      console.log('b1', image, image.length)
    }

    if (userData.userId || userData.userAvatar || userData.userName) {
      formData.append('userId', userData.userId);
      formData.append('userAvatar', userData.userAvatar);
      formData.append('userName', userData.userName);
    }

    if (postBody) {
      formData.append('postBody', postBody);
    }

    formData.append("designationId", userData?.designation?.id ?? null)

    formData.append('visibility', 'public');

    if (mode === 'edit') {
      if (deletedId.length > 0) {
        removeImageFromAPI(deletedId);
      }

      ApiService.post('savePost', formData, postData?.id)
        .then((res) => {
          ApiService.get('FeedList', {}, postData?.id)
            .then((response) => {
              // let data = [res.data.data].map(v => formTransform(v))[0];
              if (response.data.data) {
                dispatch(setFormUpdate({ id: postData?.id, formToSend: [response.data.data].map(v => formLeader(v))[0] }));
                dispatch(openSnackbar({ dataLoad: true, message: "Your post has been updated successfully!", severity: "info" }));
                setBody("");
                setFileImage([]);
                setDeletedId([]);
              }
            })
            .catch((error) => {
              // setSnackBar({ dataLoad: true, message: error.message, severity: 'error' });
              dispatch(openSnackbar({ dataLoad: true, message: error.message, severity: "error" }));
            });
        })
        .catch((error) => {
          dispatch(openSnackbar({ dataLoad: true, message: error.message, severity: "error" }));
        });
    } else {
      if (postBody || (Array.isArray(image) && image.length > 0)) {
        ApiService.post('savePost', formData)
          .then((res) => {
            let data = res.data.data;
            if (data) {
              ApiService.get('FeedList', {}, data.id)
                .then((response) => {
                  // let data = [res.data.data].map(v => formTransform(v))[0];
                  if (response.data.data) {
                    dispatch(setFormData([response.data.data].map(v => formLeader(v))[0]));
                    dispatch(openSnackbar({ dataLoad: true, message: "Your post has been added successfully!", severity: "info" }));
                    setBody("");
                    setFileImage([]);
                    setDeletedId([]);
                  }
                })
                .catch((error) => {
                  // setSnackBar({ dataLoad: true, message: error.message, severity: 'error' });
                  dispatch(openSnackbar({ dataLoad: true, message: error.message, severity: "error" }));
                });
            }
          })
          .catch((error) => {
            dispatch(openSnackbar({ dataLoad: true, message: error.message, severity: "error" }));
          });
      }
    }
  };


  function removeImageFromAPI(imageId) {
    let prarmTempObj = {};
    imageId.forEach((element, i) => {
      prarmTempObj['ids[' + i + ']'] = element;
    })
    ApiService.delete('deleteImage', prarmTempObj)
      .then((res) => {
        // console.log({ res });
        dispatch(openSnackbar({ dataLoad: true, message: "Post the Data", severity: "info" }));
      })
      .catch((error) => {
        // console.log({ error });
        dispatch(openSnackbar({ dataLoad: true, message: error.message, severity: "error" }));
      });
  }

  const [open, setOpen] = React.useState(false);
  // console.debug("aaaaaaaaa createpostdialog", postData)

  // function shareOnLinkedin(text) {
  //   const encodedText = encodeURIComponent(text);
  //   const linkedInShareUrl = `https://www.linkedin.com/shareArticle?mini=true&summary=${encodedText}&title="Newtitle "`;
  //   window.open(linkedInShareUrl, '_blank');
  // }
  return (
    <Box sx={{ width: '-webkit-fill-available', '& .css-1u51ebs-MuiPaper-root-MuiDialog-paper': { margin: '0px !important' } }}>
      <Box sx={{ p: 1, backgroundColor: '#00aeef36', '& .css-1u51ebs-MuiPaper-root-MuiDialog-paper': { margin: '0px !important' } }}>
        <Stack>
          <Grid container direction="row" alignItems="center">
            <Grid item xs={10}>
              <Box display='flex' sx={{ flexDirection: 'column', ml: '7px' }}>
                <Typography variant="body1" color="text.primary" fontWeight="var(--font-weight-7)" display="block">{mode == 'create' ? "Create " : "Edit "} Post</Typography>
              </Box>
            </Grid>
            <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <IconButton aria-label="upload picture" component="label" color='var(--color-black)' onClick={() => handlePostDialogClose()}>
                <CloseIcon sx={{ color: 'var(--color-black)' }} />
              </IconButton>
            </Grid>
          </Grid>
        </Stack>
      </Box>
      <Divider />
      <Box className='height-dialog' sx={{ p: 'var(--padding-all-1)' }}>
        <Box sx={{ width: '100%' }}>
          <CardHeader sx={{ p: 0, my: 0 }}
            avatar={
              <Avatar
                src={userData.userAvatar}
                alt={(userData?.userName)}
                sx={{
                  backgroundColor: 'transparent !Important',
                  height: '40px',
                  boxShadow: 'none',
                  width: '40px',
                  '& .MuiAvatar-img': {
                    height: '40px',
                    width: '40px',
                    textAlign: 'center',
                    objectFit: 'cover',
                    color: 'transparent',
                    textIndent: '10000px'
                  }
                }} />
            }
            title={
              <Typography variant='defineSubtitle2' color='primary.headingDark'>{userData['userName'] + (userData['middleName'] && userData['middleName'] != '--' ? ' ' + userData['middleName'] : ' ') + (userData['lastName'] ? ' ' + userData['lastName'] : '')}</Typography>}
          />
        </Box>
        <Box sx={{ mt: 1 }}>
          <Stack>
            <Grid container direction="row" alignItems="center" id='postImage' sx={{ maxHeight: "20rem", overflow: "auto" }}>
              <Grid item xs={12}>
                <Box sx={{ maxWidth: '100%', '& textarea:focus-visible': { border: '0px solid', outline: 'none' } }}>
                  <Textarea label={"What's on your mind, " + userData['userName'] + '?'} setTextField={setBody} value={postBody} sx={{ width: '100%', border: '0px solid', resize: 'none' , height:"400px !important"}} />
                </Box>
                <Box sx={{ display: 'flex' }}>
                  <PhotoComponent setImage={setImage} mode={mode} setDeletedId={setDeletedId} deletedId={deletedId} setFileImage={setFileImage} fileImage={fileImage} selectedFile={selectedFile} setSelectedFile={setSelectedFile} fileType1={fileType} deleteBtn={deleteBtn} showFileError={showFileError} setShowFileError={setShowFileError} showError={showError} setShowError={setShowError} />
                </Box>
              </Grid>
            </Grid>
          </Stack>
        </Box>
      </Box>
      <AddPhotoComponent selectedFile={selectedFile} setSelectedFile={setSelectedFile} fileType={fileType} deleteBtn={deleteBtn} />
      <Box pt={1}>
        {showError && (
          <Stack sx={{ width: '100%' }} spacing={2} pl={2} pr={2}>
            <Alert severity="error" onClose={handleClose}>
              <Typography variant='body2'>
                You have reached your exceeded limit size 20 mb, Please reduce the no. of images to upload the post.
              </Typography>
            </Alert>
          </Stack>
        )}
        {!fileImage.length <= 0 || showFileError && (
          <Stack sx={{ width: '100%' }} spacing={2} pl={2} pr={2}>
            <Alert severity="error" onClose={handleClose}>
              <Typography variant='body2'>
                Only Image Can Upload.
              </Typography>
            </Alert>
          </Stack>
        )}
      </Box>
    
     {genai ?<Box sx={{ display: 'flex', justifyContent: 'space-between', p: 2 }}>
    
    <Button
     variant="text"
      onClick={handleBothClicks}
    >
    Share Internally
    </Button>
    <Button
     variant="outlined"
      // onClick={handleBothClicks}

    >
    Copy To Clipboard
    </Button>
    <Button
      variant="contained"
  //  onClick={shareOnLinkedin("Shweta Sgarma share somdthing")}

    >
     Share on Linkedin
    </Button>
  </Box>:  <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
        <Button
          variant="contained"
          onClick={handleBothClicks}
          disabled={!postBody?.length && (!fileImage || fileImage.length <= 0)}
        >
          Post
        </Button> 
      </Box>
      } 
      <ConfirmDialogBox
        id='ConfirmDialog'
        title="Confirmation Dialog"
        body={<p>Are you sure you want to proceed?</p>}
        titleOne="Cancel"
        titleTwo="Confirm"
      >
      </ConfirmDialogBox>
      <DynamicSnackbar />
    </Box >
  );
};

export default CreatePostDialog;
