import { default as axios } from 'axios';
import { getAppToken, getSelfToken } from "../_helper/secureToken"
const BASE_URL = 'https://gatewayapi.eela.tech/api';

class APIService {
  constructor(baseURL) {
    this.baseURL = baseURL || BASE_URL;
    this.api = axios.create({
      baseURL: this.baseURL
    });

    // Define your endpoints here
    this.endpoints = {
      menuSetting: "v1/menu-setting",
      comapnyLog: "v1/company/1",
      myReferral: "v2/application",
      applyJob: "v1/application/gateway",
      referJob: 'v1/application/gateway',

      // ----------- Filter Category -------
      userInfo: "v1/userinfo",

      FeedList: 'v1/posts',
      FeedAnnouncements: 'v1/notice',
      LeaderSpeak: 'v1/leader-speak',
      CompanyWithAHeart: 'v1/social',
      Events: 'v1/events',
      Social: 'v1/social',
      Awards: "v1/award-user",
      Birthday: "v1/user-birth",
      Joiners: "v1/user-joining",
      Promotions: "v1/promotion",
      Accolades: "v1/accolades",
      WorkAniversary: "v1/user-work-anniversary",
      birthAnnouncement: "v1/childs",
      Stories: "v1/recipes",
      Weddings: "v1/user-weddings",
      AssociateSpotlight: "v1/associates-feedback",

      //-----Get----///
      savePost: 'v1/posts',
      getReaction: 'v1/postsreaction',

      //-------delete--//
      deletePost: 'v1/posts',
      deletePostsReaction: 'v1/postsreaction',

      //---------save post-----//
      savePostUser: 'v1/save-post',
      saveFavoriteFeeds: 'v1/favorite-feeds',

      //---------unsave post-----//
      unsavePostUser: 'v1/unsave-post',
      unsaveFavoriteFeeds: 'v1/favorite-feed-remove',

      //------post-//
      getPostsReaction: 'v1/postsreaction',
      submitComment: 'v1/feed-comments',

      //--------update-------------//
      updateReaction: 'v1/postsreaction',
      // -------reaction ------- ///
      reactPost: 'v1/postsreaction',
      reactPostPages: 'v1/reaction',

      // ------- Org Chart -------//
      orgUsers: 'v1/users/hierarchy',
      orgDepartment: 'v1/department',
      orgDepartmentUser: 'v1/users',
      user: 'v1/user',
      branch: 'v1/branch',
      skills: 'v1/skills',
      jobs: 'v1/jobs/filter',
      getAllCity: 'v1/city',
      getAllCountry: 'v1/country',
      jobPriority: 'v1/job-priority',
      contactDirectory: 'v1/contact-directory',
      favouriteJob: 'v1/favorite-jobs',
      unsaveJob: 'v1/favorite-jobs-remove',
      jobDetails: 'v1/job',
      usersFilter: 'v1/users-filter',
      jobFilter: 'v1/jobs/new-filter',

      //  ------delete image edit post -------
      deleteImage: 'v1/images',
      deleteComment: 'v1/feed-comments',
      updateComment: 'v1/feed-comments',
      submitComment: 'v1/feed-comments',
      DeleteComment: 'v1/comment',
      UpdateComment: 'v1/comment',

      // --------- Referal ---------------///
      referalJob: 'v1/job',
      importantLinks: 'v1/link-type',
      // --------- Saved Posts ---------------///
      savedPosts: 'v1/saved-posts',
      favoriteFeeds: 'v1/favorite-feeds',
      HiddenPost: 'v1/hide-post',
      HiddenPostUser: 'v1/hide-post-user',
      ReportPost: 'v1/post-report',
      CommentReport: 'v1/comment-report',
      PostReport: 'v1/post-report',
      userCommentReport: 'v1/user-comment-report',
      userPostReport: 'v1/user-post-report',


      // -----------Generative AI ----------//
      genaiPromptCategory: "v1/genai-prompt-category",
      // genaiPromptCategory: "/genai-prompt-category",
      toneOfVoice:"v1/genai-tone-of-voice",
      wordLimit:"v1/genai-word-limit/1",
      genaiSetting:"v1/genai-settings/1",
      gamificationSetting:"v1/gamification-service-status",  //  send identifier=gamification,
      genaiResponse:"v1/genai-response"
    };




  }

  async get(endpointKey, params = {}, searchParam = null) {
    // console.debug("aaaaaaaa", { params, searchParam })
    try {
      let endpoint = this.endpoints[endpointKey];
      if (searchParam) {
        endpoint = endpoint + '/' + searchParam;
      }
      params.app = getAppToken();

      const response = await this.api.get(endpoint, {
        params,
        headers: { Authorization: 'Bearer ' + getSelfToken() },
      });
      return response;
    } catch (error) {
      throw new Error('API request failed:', error);
    }
  }

  async getExternalApi(endpointKey, params = {}, searchParam = null) {
    // console.debug("aaaaaaaa", { params, searchParam })
    try {
      let endpoint = this.endpoints[endpointKey];
      if (searchParam) {
        endpoint = endpoint + '/' + searchParam;
      }
      params.app = getAppToken();

      const response = await this.api.get(endpointKey, {
        params,
        headers: { Authorization: 'Bearer ' + getSelfToken() },
      });
      return response;
    } catch (error) {
      throw new Error('API request failed:', error);
    }
  }

  async post(endpointKey, data = null, searchParam = null) {
    try {
      //console.debug(data)
      let endpoint = this.endpoints[endpointKey];
      if (searchParam) {
        endpoint = endpoint + '/' + searchParam;
      }
      data.app = getAppToken();
      const response = await this.api.post(endpoint, data, {
        headers: { Authorization: 'Bearer ' + getSelfToken() }
      });
      return response;
    } catch (error) {
      throw new Error('API request failed:', { error });
    }
  }

  async delete(endpointKey, params = {}, searchParam = null) {
    try {
      let endpoint = this.endpoints[endpointKey];
      if (searchParam) {
        endpoint = endpoint + '/' + searchParam;
      }
      params.app = getAppToken();
      const response = await this.api.delete(endpoint, {
        params,
        headers: { Authorization: 'Bearer ' + getSelfToken() }
      });
      return response;
    } catch (error) {
      throw new Error('API request failed:', error);
    }
  }

  async put(endpointKey, data = null, searchParam = null) {
    try {
      let endpoint = this.endpoints[endpointKey];
      if (searchParam) {
        endpoint = endpoint + '/' + searchParam;
      }
      data['app'] = getAppToken();
      const response = await this.api.put(endpoint, data, {
        headers: { Authorization: 'Bearer ' + getSelfToken() }
      });
      return response;
    } catch (error) {
      throw new Error('API request failed:', { error });
    }
  }

  // Because some APIs are not in proper format we need to impliment another methods to handle 
  // The API's which are not following conventions, by which other APIs are following,
  // are :- unsaveFavoriteFeeds, 
  async postAdopter(endpointKey, data = null, searchParam = null) {
    try {
      //console.debug(data)
      let endpoint = this.endpoints[endpointKey];
      if (searchParam) {
        endpoint = endpoint + '/' + searchParam;
      }
      endpoint = endpoint + '?app=' + getAppToken();
      const response = await this.api.post(endpoint, data, {
        headers: { Authorization: 'Bearer ' + getSelfToken() }
      });
      return response;
    } catch (error) {
      throw new Error('API request failed:', { error });
    }
  }

}



export default new APIService(BASE_URL);
