import React, { useState, useRef } from "react";
import ReactDOM from "react-dom";
import shortid from "shortid";
import { Button, Card, CardContent, Grid, Box, Typography, IconButton, Hidden } from "@mui/material";
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector, useDispatch } from 'react-redux';
import { closeImage } from "../../features/uploadPic/ImageSlice";
import ImageIcon from '@mui/icons-material/Image';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import Tooltip from '@mui/material/Tooltip';
import { useEffect } from "react";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Stack from '@mui/material/Stack';
import ConfirmDialogBox from "../../features/confirmDialogBox/confirmDialogBox";
import { openConfirmDialog, closeConfirmDialog } from "../../features/confirmDialogBox/confirmDialogBoxSlice";

const PhotoComponent = ({ setImage, mode, setDeletedId, deletedId, setFileImage, fileImage, selectedFile, setSelectedFile, fileType1, deleteBtn, showFileError, setShowFileError, showError, setShowError }) => {
  // const [selectedFile, setSelectedFile] = useState([]);
  const [files, setFiles] = useState([]);
  const showImages = useSelector(state => state.image.isOpen);
  // const fileType1 = useRef(null);
  const fileType2 = useRef(null);
  const dispatch = useDispatch();
  const postData = useSelector((state) => state?.dialog?.data);
  const [ currentDeleteId, setCurrentDeleteId] = useState('');
  const [ currentImages, setCurrentImages] = useState([]);
  // const [showFileError, setShowFileError] = useState(false);
  // const [showError, setShowError] = useState(false);
  // const handleClose = () => {
  //   setShowError(false);
  //   setShowFileError(false);
  // };

  React.useEffect(() => {
    // console.log("showImages", showImages)
    if (showImages && fileType1.current) {
      fileType1.current.click(); // Trigger the click event
      dispatch(closeImage());
    }
  }, [showImages])

  const fileSizes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  };

  useEffect(() => {
    if (mode == 'edit' && postData?.album?.images.length) {
      let imageFile = postData?.album?.images.map((d) => {
        return {
          id: d.id,
          filename: d.image,
          filetype1: d.type ? d.type : "",
          fileimage: d.image,

        }
      })
      // console.log("Hit Sensei", { imageFile })
      setSelectedFile(imageFile)
    }
  }, [])

  useEffect(() => {
    setImage(currentImages);
  }, [currentImages])

  const allowedImageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'svg', 'jfif', 'webp'];

  const handleInputChange = (e) => {
    let images = currentImages;
    let totalSize = selectedFile.reduce((acc, file) => {
      // console.log({acc,file})
      return acc + file.size;
    }, 0);
    // console.log({totalSize})
  
    for (let i = 0; i < e.target.files.length; i++) {
      const file = e.target.files[i];
      const fileExtension = file.name.split('.').pop().toLowerCase();
  
      if (!allowedImageExtensions.includes(fileExtension)) {
        setShowFileError(true);
        return;
      }

      
      if (!isFileAlreadySelected(file)) {
        totalSize = totalSize + file.size;
        console.log({totalSize,file});
        if (totalSize > 20 * 1024 * 1024) {
          setShowError(true);
          return;
        }
        // totalSize += file.size;
        images.push(file);
        console.log({selectedFile,images})

        // setImage(images)
        setCurrentImages(images);
  
        // let images = [];
        let reader = new FileReader();
  
        reader.onloadend = () => {
          // setImage(images);
          setSelectedFile((prevValue) => [
            ...prevValue,
            {
              id: shortid.generate(),
              filename: file.name,
              filetype1: file.type,
              fileimage: reader.result,
              datetime: file.lastModifiedDate.toLocaleString('en-IN'),
              filesize: fileSizes(file.size),
              size: file.size
            },
          ]);
          setFileImage((prevValue) => [...prevValue, reader.result]);
        };
  
        reader.readAsDataURL(file);
      }
    }
  };
  
  const closeDialogPublish = (id) => {
    dispatch(closeConfirmDialog(id));
  }
  
  const handleButtonClick = () => {
    // console.log("Checking");
    fileType1.current.click();
  };

  const isFileAlreadySelected = (file) => {
    return selectedFile.some((selected) => selected.filename === file.name);
  };

  const deleteSelectedFile = (id) => {
    // console.log('b1', deleteSelectedFile)
    setCurrentDeleteId(id);
    dispatch(openConfirmDialog('deleteOneConfirm')); 
  };
 

  const handleFileUploadSubmit = async (e) => {
    e.preventDefault();
    e.target.reset();
    if (selectedFile.length > 0) {
      for (let index = 0; index < selectedFile.length; index++) {
        setFiles((prevValue) => [
          ...prevValue,
          selectedFile[index]
        ]);
      }
      setSelectedFile([]);
    } else {
      alert('Please select a file');
    }
  };


  const deleteAllFiles = () => {
    dispatch(openConfirmDialog('deleteAllConfirm'));
  };

  const handleDialogAffermation = (model) => {
    if(model == 'deleteOneConfirm')
    {
      let index = selectedFile.findIndex((data) => data.id === currentDeleteId);
      let fileImageTemp = JSON.parse(JSON.stringify(fileImage));
      fileImageTemp.splice(index,1);
      setFileImage(fileImageTemp);
      // let imageTemp = JSON.parse(JSON.stringify(currentImages));
      let imageTemp = [...currentImages];
      imageTemp.splice(index,1);
      setCurrentImages(imageTemp);
      const result = selectedFile.filter((data) => data.id !== currentDeleteId);
      setDeletedId([...deletedId, currentDeleteId])
      setSelectedFile(result);
      console.log('b1',deletedId,currentDeleteId,result,model)
      if(fileType1.current){
        fileType1.current.value = '';
      }
      dispatch(closeConfirmDialog(model));
    } 
    else  
    {
      setDeletedId([...deletedId, currentDeleteId]);
      setFileImage([]);
      setSelectedFile([]);
      setCurrentImages([]);
      setFiles([]);
      console.log('b1', setDeletedId,setFileImage,setCurrentImages,deletedId,currentDeleteId,model);
      if(fileType1.current)
      {
        fileType1.current.value = '';
      }
      dispatch(closeConfirmDialog(model));
    }
  }

  return (
    <Box>
      <Box>
        <form onSubmit={handleFileUploadSubmit}>
          <Box className="chill" sx={{ my: 2, mx: 2, maxHeight: '8rem' }}>
            <Grid
              container
              rowSpacing={2}
              columnSpacing={{ xs: 2 }}
            >
              {/* Render selected files */}
              {selectedFile.map((data,fileIndex) => {
                const { id, filename, fileimage } = data;
                console.log({data,calculated: ('' + id + fileIndex + (data.size ?? '')),id,fileIndex});
                // console.debug("aaaaaaaa", data)
                return (
                  <Grid item xs={4} key={(id + fileIndex + (data.size ?? ''))} sx={{ maxWidth: 'none !important' }}>
                    <Box className="img-close-style">
                      <Box sx={{ position: "relative" }}>
                        {filename.match(
                          /\.(jpg|jpeg|png|gif|svg|jfif|WEBP)$/i
                        ) ? (
                          <Box sx={{ position: "relative", display: "inline-block" }}>
                            <Box>
                              <img
                                className="img-style"
                                src={fileimage}
                                alt=""
                                sx={{ height: "10rem", width: "10rem" }}
                                onLoad={(e) => {
                                  // Get the image data as a blob
                                  if(data.size){
                                    return;
                                  }
                                  const imageBlob = new Blob([e.target.src], { type: e.target.type });
                                  const reader = new FileReader();
                                  reader.onload = (event) => {
                                    let allFiles = [...selectedFile];
                                    allFiles[fileIndex]['size'] = event.target.result.byteLength;
                                    setSelectedFile(allFiles);
                                  };
                                  reader.readAsArrayBuffer(imageBlob);
                                }}
                              />
                            </Box>
                            <Box className="close-icon-container" sx={{ position: "absolute", top: '0.4rem', right: '0.4rem' }}>
                              <Tooltip title="DELETE PHOTO" arrow placement="top-end">
                              <IconButton
                                className="btn-delete"
                                aria-label="Delete Photo"
                                size="small"
                                onClick={() => deleteSelectedFile(id)}
                                >
                               
                                <CloseIcon fontSize="small" sx={{ fontSize: '1rem' }} />
                              </IconButton>
                              </Tooltip>
                            </Box>
                          </Box>
                        ) : null
                        }
                      </Box>
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
         
          <Box sx={{ visibility: 'hidden', height: '0px', overflow: "hidden" }}>
            <Box sx={{ display: "flex", my: 1 }}>
              <Grid container>
              <Grid item  xs={12} sm={2} sx={{}}>
              <Box>
                <input
                  ref={fileType1}
                  type="file"
                  accept=".jpg,.jpeg,.png,.gif,.svg,.jfif,.webp"
                  id="fileupload1"
                  style={{ display: "none", visibility: "hidden" }}
                  onChange={handleInputChange}
                  multiple
                />
                <label htmlFor="fileupload1" onClick={handleButtonClick} >
                  <Button startIcon={<ImageOutlinedIcon />}>ADD MORE PHOTOS</Button>
                </label>
              </Box>
              </Grid>
              <Grid item  xs={12} sm={8} sx={{}}>
              <Box>
                <Button ref={deleteBtn} startIcon={<DeleteOutlineOutlinedIcon />} onClick={deleteAllFiles} >
                  DELETE ALL
                </Button>
              </Box>
              </Grid>
              </Grid>
              {/* <Box>
                <input
                  ref={fileType1}
                  type="file"
                  accept=".jpg,.jpeg,.png,.gif,.svg,.jfif,.webp"
                  id="fileupload1"
                  style={{ display: "none", visibility: "hidden" }}
                  onChange={handleInputChange}
                  multiple
                />
                <label htmlFor="fileupload1" onClick={handleButtonClick} >
                  <Button startIcon={<ImageOutlinedIcon />}>ADD MORE PHOTOS</Button>
                </label>
              </Box> */}
              {/* <Box>
                <Button ref={deleteBtn} startIcon={<DeleteOutlineOutlinedIcon />} onClick={deleteAllFiles} >
                  DELETE ALL
                </Button>
              </Box> */}
            </Box>
            <Box sx={{ mt: 1 }}>
              <Box>
                <input
                  ref={fileType2}
                  type="file"
                  id="fileupload"
                  style={{ display: "none", }}
                  onChange={handleInputChange}
                  multiple
                />
                <label htmlFor="fileupload">
                  <IconButton
                    aria-label="Add Photo"
                    size="large"
                    component="span"
                  >
                    <ImageIcon fontSize="small" color="primary" />
                  </IconButton>
                  
                </label>
              </Box>
            </Box>
         
          </Box>
        </form>
      </Box>
      <ConfirmDialogBox
        id='deleteOneConfirm'
        title="Confirmation Dialog"
        body={<p>Are you sure you want to delete this image</p>}
        titleOne="Cancel"
        titleTwo="Confirm"
        onCancel={() => {closeDialogPublish('deleteOneConfirm')}}
        onConfirm={() => {handleDialogAffermation('deleteOneConfirm')}}
      >
      </ConfirmDialogBox>
      <ConfirmDialogBox
        id='deleteAllConfirm'
        title="Confirmation Dialog"
        body={<p>Are you sure you want to delete all images?</p>}
        titleOne="Cancel"
        titleTwo="Confirm"
        onCancel={() => {closeDialogPublish('deleteAllConfirm')}}
        onConfirm={() => {handleDialogAffermation('deleteAllConfirm')}}
      >
      </ConfirmDialogBox>
    </Box>
  );
};
export default PhotoComponent;
