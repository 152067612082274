import { createSlice } from '@reduxjs/toolkit';

const genaiWithPostTypeSlice = createSlice({
    name: 'genaiWithPostType',
    initialState: {
        genaiWithPostType: "",
    },
    reducers: {
        setGenaiWithPostType: (state, action) => {
            state.genaiWithPostType = action.payload;
        },
    },
});

export const { setGenaiWithPostType } = genaiWithPostTypeSlice.actions;
export default genaiWithPostTypeSlice.reducer;
