import { FormControl, Grid, InputLabel, MenuItem, Select, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import ApiService from '../../utils/ApiService'
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useSelector } from 'react-redux';
import { GenaiContext } from '../../context/GenaiContext';

function SelectPurposeComponent(props) {
    const [selectOptions, setSelectOptions] = useState()
    const usersList = useSelector(state => state?.allUsersList?.allUsers)
    const userSkillList = useSelector(state => state?.user?.userListName?.skills)
    const contextValue= useContext(GenaiContext)
    function handleChange(e) {
        const promptData = props.purposeList.find((data) => data.id === e.target.value)
        props.setSelectedPurpose(e.target.value)
        const type = promptData.name.toLowerCase().replace(/\s/g, '_')
        props.setPromptList(promptData?.promptCategoryData)
        if (promptData) {
            switch (type) {
                case 'daily_post':
                    setSelectOptions({
                        label: "Skill",
                        optionData: [],
                        type: "autocomplete"
                    })
                    break;
                case 'birthday':
                    setSelectOptions({
                        label: "Select Employee",
                        optionData: [],
                        type: "singleSelect"
                    })
                    break;
                case 'work_anniversaries':
                    setSelectOptions({
                        label: "Select Employee",
                        optionData: [],
                        type: "singleSelect"

                    })
                    break;
                case 'awards':
                    setSelectOptions({
                        label: "Select Employee",
                        optionData: []
                    })
                    break;
                case 'promotions':
                    setSelectOptions({
                        label: "Select Employee",
                        optionData: []
                    })
                    break;
                // Add more cases as needed
                default:
                    // Default case
                    break;
            }
        }
    }
    return (
        <>
            <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Select purpose</InputLabel>
                    {props.loadingPurposeList ? <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Select purpose"
                    >
                        <Typography sx={{ p: 2 }}>Loading purpose...</Typography>
                    </Select>
                        :
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={props.selectedPurpose}
                            label="Select Purpose"
                            onChange={(e) => handleChange(e)}
                        >
                            {props.purposeList.map((data) => <MenuItem value={data.id}>{data.name}</MenuItem>)}
                        </Select>}
                </FormControl>
            </Grid>
            {selectOptions && (!(selectOptions.type == "autocomplete") ? <Grid item xs={12} md={6}>
                <Autocomplete
                    // freeSolo
                    id="combo-box-demo"
                    disableClearable
                    options={usersList.map((option) => option.userName)}
                    onChange={(event, newValue) => {
                        // setOptions(newValue ? [newValue, ...options] : options);
                        contextValue.setSelectedEmployee(newValue);
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Select Employee"
                            InputProps={{
                                ...params.InputProps,
                                type: 'search',
                            }}
                        />
                    )}
                />
            </Grid>
                :

                <Grid item xs={12} md={6}>
                    {userSkillList.length > 0 &&
                        <Autocomplete
                            multiple
                            id="combo-box-demo"
                            disableClearable
                            options={userSkillList?.map((option) => option.skill)}
                            renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                    <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                                ))
                            }
                            onChange={(event, newValue) => {
                                // setOptions(newValue ? [newValue, ...options] : options);
                                contextValue.setSelectedSkills(newValue);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Select Skills"
                                    InputProps={{
                                        ...params.InputProps,
                                        type: 'search',
                                    }}
                                />
                            )}
                        />}
                </Grid>)}
        </>
    )
}

export default SelectPurposeComponent
