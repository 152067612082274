import { East } from '@mui/icons-material'
import { Avatar, Box, Button, Chip, Divider, Grid, Skeleton, Typography, useMediaQuery } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import ApiService from '../utils/ApiService'

function PersonalDetails() {
    // const userData = useSelector(state => state?.user?.userListName)
    const userData = useSelector(state => state.user.userListName)

    const isSmallScreen = useMediaQuery('(max-width:600px)');
    // useEffect(function () {
    //     ApiService.get('userInfo', { toolsUser: 'null' }).then(res => {
    //         let response = res.data.data;
    //         console.log('a5', response);

    //         if (response) {
    //             setUserData(response)
    //         }
    //     });
    // })

    return (
        <>
            <Box sx={{ border: "1.5px solid rgba(227, 227, 227, 1)", borderRadius: 2, mb: 3, boxShadow: 1 }}>
                <Box sx={{ p: 2 }}>
                    <Typography variant='h7' fontWeight={500}>Skills</Typography>
                </Box>
                <Divider />
                {console.debug("userData?.skills", userData)}
                <Box p={2}>
                    {Object.keys(userData).length == 0 ? <>
                        <Skeleton animation="wave" sx={{ mt: 1 }} width={230} />
                    </> : <>
                        {userData && userData.skills && userData.skills.length > 0 ? userData.skills.map((data, index) => (
                            <Chip key={index} label={data?.skill} sx={{ mr: 1, borderRadius: 1, mb: 1 }} />
                        )) : <Typography>No Data Found</Typography>}
                    </>}
                </Box>
            </Box>
            <Box sx={{ border: "1.5px solid rgba(227, 227, 227, 1)", borderRadius: 2, mb: 3, boxShadow: 1 }}>
                <Box sx={{ p: 2 }}>
                    <Typography variant='h7' fontWeight={500}>Tools</Typography>
                </Box>
                <Divider />
                <Box p={2}>
                    {Object.keys(userData).length == 0 ? <>
                        <Skeleton animation="wave" sx={{ mt: 1 }} width={230} />
                    </> : <>
                        {userData && userData.toolsUser && userData.toolsUser.length > 0 && userData.toolsUser.map((data, index) => (
                            <Chip key={index} label={data?.tool} sx={{ mr: 1, borderRadius: 1, mb: 1 }} />
                        ))} </>}
                </Box>
            </Box>
            <Box sx={{ border: "1.5px solid rgba(227, 227, 227, 1)", borderRadius: 2, mb: 3, boxShadow: 1 }}>
                <Box sx={{ p: 2 }}>
                    <Typography variant='h7' fontWeight={500} >Education</Typography>
                </Box>
                <Divider />
                <Box p={2}>
                    {Object.keys(userData).length == 0 ? <>
                        <Grid container sx={{ pb: 3 }}>
                            <Grid xs={12} sm={12} md={4}>
                                <Skeleton animation="wave" sx={{ mt: 1 }} width={230} />
                                <Skeleton animation="wave" sx={{ mt: 1 }} width={230} />
                            </Grid>
                            <Grid xs={12} sm={12} md={4}>
                                <Skeleton animation="wave" sx={{ mt: 1 }} width={230} />
                                <Skeleton animation="wave" sx={{ mt: 1 }} width={230} />
                            </Grid>
                        </Grid>
                    </> : <>
                        {userData && userData?.userEducation.map((data) => <Grid container sx={{ pb: 3 }}>
                            <Grid xs={12} sm={12} md={4}>
                                <Typography variant='body1'>{`${data?.toYear} - ${data?.fromYear}`}</Typography>
                                <Typography variant='body2' color="#757575" mt={1.5}>{data?.degree}</Typography>
                            </Grid>
                            <Grid xs={12} sm={12} md={4}>
                                <Typography variant='body1'>{data?.fieldOfStudy}</Typography>
                                <Typography variant='body2' color="#757575" mt={1.5}>{`${data?.universityName}`}</Typography>
                            </Grid>
                        </Grid>)} </>}
                </Box>
            </Box>
            <Box sx={{ border: "1.5px solid rgba(227, 227, 227, 1)", borderRadius: 2, mb: 3, boxShadow: 1 }}>
                <Box sx={{ p: 2 }}>
                    <Typography variant='h7' fontWeight={500} >Employment</Typography>
                </Box>
                <Divider />
                <Box p={2}>
                    {Object.keys(userData).length == 0 ? <>
                        <Grid container sx={{ pb: 3 }}>
                            <Grid xs={12} sm={12} md={4}>
                                <Skeleton animation="wave" sx={{ mt: 1 }} width={230} />
                                <Skeleton animation="wave" sx={{ mt: 1 }} width={230} />
                            </Grid>
                            <Grid xs={12} sm={12} md={4}>
                                <Skeleton animation="wave" sx={{ mt: 1 }} width={230} />
                                <Skeleton animation="wave" sx={{ mt: 1 }} width={230} />
                            </Grid>
                        </Grid>
                    </> : <>
                        {userData && userData?.userEducation.map((data) => <Grid container sx={{ pb: 3 }}>
                            <Grid xs={12} sm={12} md={4}>
                                <Typography variant='body1'>{`${data?.toYear} - ${data?.fromYear}`}</Typography>
                                <Typography variant='body2' color="#757575" mt={1.5}>{data?.degree}</Typography>
                            </Grid>
                            <Grid xs={12} sm={12} md={4}>
                                <Typography variant='body1'>{data?.fieldOfStudy}</Typography>
                                <Typography variant='body2' color="#757575" mt={1.5}>{`${data?.universityName}`}</Typography>
                            </Grid>
                        </Grid>)}
                    </>}
                </Box>
            </Box>
            <Box sx={{ border: "1.5px solid rgba(227, 227, 227, 1)", borderRadius: 2, mb: 3, boxShadow: 1 }}>
                <Box sx={{ p: 2 }}>
                    <Typography variant='h7' fontWeight={500} >Licenses & certifications</Typography>
                </Box>
                <Divider />
                <Box p={2}>
                    {Object.keys(userData).length == 0 ? <>
                        <Grid container sx={{ pb: 3 }}>
                            <Grid xs={3} md={1}>
                                <Skeleton animation="wave" sx={{ mt: 1 }} width={50} height={50} variant='circular' />

                            </Grid>
                            <Grid xs={9} md={11}>
                                <Skeleton animation="wave" sx={{ mt: 1 }} width={230} />
                                <Skeleton animation="wave" sx={{ mt: 1 }} width={230} />
                            </Grid>
                        </Grid>
                    </> : <>
                        {userData && userData?.certificate.map((data) => <Grid container sx={{ pb: 3 }}>
                            <Grid xs={3} md={1}>
                                <Avatar aria-label="recipe" sx={isSmallScreen ? { height: 40, width: 40 } : { height: 50, width: 50 }}>
                                    R
                                </Avatar>
                            </Grid>
                            <Grid xs={9} md={11}>
                                <Typography variant='h7'>{data.title}</Typography>
                                <Typography variant='body2' color={"#757575"}>{data.description}</Typography>
                                <Button sx={{ pl: 0, mt: 1, textTransform: 'lowercase' }} >SHOW CREDENTIALS <East sx={{ pl: 1 }} /></Button>
                            </Grid>
                        </Grid>)}
                    </>}
                </Box>
            </Box>
        </>
    )
}

export default PersonalDetails
