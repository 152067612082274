import { configureStore } from '@reduxjs/toolkit';
import drawerReducer from './features/drawers/drawerSlice';
import dialogReducer from './features/dialogBox/dialogBoxSlice';
// import wheelSlice from './features/wheel/wheelSlice';
import uploadSlice from './features/uploadPic/uploadSlice';
import confirmDialogBoxSlice from './features/confirmDialogBox/confirmDialogBoxSlice';
import ImageSlice from './features/uploadPic/ImageSlice';
import commonSlice from './features/commonDataSlice/commonSlice';
import sidebarSelectionSlice from './features/sidebarRouting/sidebarSelectionSlice';
import headerSearch from './features/headerSearch/headerSearch';
import permissionData from './features/permission/permissionData';
import LoaderDashboard from './features/loader/loaderDashboard';
import dashboardData from './features/dashboardData/dashboardData';
import postReducer from './features/commonDataSlice/postSlice';
import userInfo from './features/userInfoStore/userSlice';
import shareInfo from './features/shareInfoStore/shareInfoSlice';
import menuSetting from './features/menusStore/menuSlice';
import snackbarReducer from './features/snackbar/snackbarSlice';
import BreadcrumbsReducer from './features/breadcrumbs/BreadcrumbsSlice';
import genaiActiveStatusReducer from './features/generativeAiActive/genaiActiveStatusSlice';
import gamificationActiveStatusReducer from './features/gamificationSettingStatus/gamificationSettingStatusSlice';
import allUsersReducer from "./features/userInfoStore/allUsersSlice"
import genaiWithPostTypeReducer from './features/notifications/genaiWithPostTypeSlice';



const store = configureStore({
  reducer: {
    drawer: drawerReducer,
    snackbar: snackbarReducer,
    dialog: dialogReducer,
    image: ImageSlice,
    confirmDialog: confirmDialogBoxSlice,
    // wheel: wheelSlice,
    profile: commonSlice,
    imageCropper: uploadSlice,
    routing: sidebarSelectionSlice,
    search: headerSearch,
    permission: permissionData,
    loader: LoaderDashboard,
    dashboardScroll: dashboardData,
    formData:postReducer,
    user: userInfo,
    menu: menuSetting,
    sharedInfo: shareInfo,
    visitedLinks: BreadcrumbsReducer,
    genaiActiveStatus:genaiActiveStatusReducer,
    gamificationActiveStatus:gamificationActiveStatusReducer,
    allUsersList:allUsersReducer,
    genaiPostType:genaiWithPostTypeReducer
  },
});

export default store;
