import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
} from "react-router-dom";
import { history } from "./_helper";
import RouteComponet from './RouteComponent';
// import GlobalComponent from './GlobalComponent';



function AppComponent(props) {
    return (
        <div className="App" id="back-to-top-anchor">
          
            <Router history={history}>
                <RouteComponet teams={props.teams} />
            </Router>
        </div>
    );
}

export default AppComponent;