import React, { useState, useEffect } from 'react'
import { Box, Grid, Button, Card, Chip, Typography, Avatar, TextField, Divider } from '@mui/material'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import { useLocation } from 'react-router-dom';
import MenuList from '@mui/material/MenuList';
import Menu from '@mui/material/Menu';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ListItemIcon from '@mui/material/ListItemIcon';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ReportIcon from '@mui/icons-material/Report';
import HideSourceIcon from '@mui/icons-material/HideSource';
import SaveIcon from '@mui/icons-material/Save';
import LinkIcon from '@mui/icons-material/Link';
import { LocationDisabledSharp } from '@mui/icons-material';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import Like from '../../../src/assets/images/like.svg'
import Mood from '../../../src/assets/images/mood.svg'
import Clapping from '../../../src/assets/images/clapping.svg'
import Favourite from '../../../src/assets/images/favourite.svg'
import Popover from '@mui/material/Popover';
import DOMPurify from 'dompurify';
import SimpleImageSlider from "react-simple-image-slider";
import PhotoCollage from '../PhotoCollage';
import listRoutes from '../../layouts/sidebar/listRoutes.json'
import ApiService from '../../utils/ApiService';
import { useSelector, useDispatch } from 'react-redux';
import { openDialog, setData } from "../../features/dialogBox/dialogBoxSlice";
import transformationHelper from '../../utils/transformationHelper';
import Comment from './Comment';
import ConfirmDialogBox from '../../features/confirmDialogBox/confirmDialogBox';
import { closeConfirmDialog, openConfirmDialog } from '../../features/confirmDialogBox/confirmDialogBoxSlice';
import { openSnackbar, closeSnackbar } from '../../features/snackbar/snackbarSlice';
import SharedDialog from '../../features/dialogBox/dialogBox';
import ReactionPostDialog from '../dialogBox/ReactionPostDialog';
import PhotoComponent from '../PhotoComponent';
import Picker from "emoji-picker-react";
import DynamicSnackbar from '../../features/snackbar/Snackbar';
import encriptionHelper from '../../utils/encriptionHelper';
import ReportTextField from '../../component/forms/TextField';
import { useNavigate } from 'react-router-dom';
import CreatePostDialog from '../dialogBox/CreatePostDialog';
import SinglePostSkeleton from '../../component/CommonPage/SinglePostSkeleton'
// const SinglePost = (post,formTransform) => {


const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#2563EB',
    color: 'white',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

const SinglePost = () => {
  const dispatch = useDispatch();
  // console.log({ location: window.location.href })
  // const searchParams = window.location.search.substring(1).split("~~")[0].split("&").reduce((prev,curr) => {
  //   let obj = curr.split("~");
  //   prev[obj[0]] = atob(decodeURIComponent(obj[1]));
  //   return prev;
  // }, {});
  // const searchParams = encriptionHelper().decryptURLParams(window.location.search.substring(1).split("&")[0].split("=")[1]);
  const searchParams = "";
  console.log({ searchParams })
  const isAnnouncementPage = searchParams['indivisualAPI'] == "FeedAnnouncements";
  const feedName = isAnnouncementPage ? 'Announcement' : 'Your Feed Name';
  const [snackBar, setSnackBar] = useState({ dataLoad: false, message: '' });
  const location = useLocation()
  const [feedPostData, setFeedPostData] = useState((state) => state?.user?.feedPostData ?? {});
  const feedPostName = listRoutes.filter((route) => route.path == (searchParams['feedPostName'] ?? location.state?.feedPostName))?.[0]?.label ?? listRoutes.filter((route) => route.path == ((typeof searchParams['feedPostName'] != 'string') ? '/' : searchParams['feedPostName']) ?? searchParams['feedPostName'])?.[0]?.label ?? searchParams['feedPostName'];
  console.log({ first: listRoutes.filter((route) => route.path == location.state?.feedPostName ?? searchParams['feedPostName'])?.[0]?.label, second: ((typeof searchParams['feedPostName'] != 'string') ? 'Home' : searchParams['feedPostName']), third: searchParams })
  const indivisualAPI = searchParams['indivisualAPI'] ?? location.state?.indivisualAPI ?? 'FeedList';
  const reactPost = searchParams['reactPost'] ?? location?.reactPost ?? 'reactPost';
  const [anchorE4, setAnchorE4] = React.useState(null);
  const [commentText, setCommentText] = useState('');
  const [showComment, setShowComment] = useState(false);
  const [feedPostComments, setFeedPostComments] = useState(feedPostData?.comments ?? []);
  const [isLoading, setIsLoading] = useState(false);
  const userData = useSelector((state) => state?.user?.userListName)
  const formTransform = transformationHelper().getTransformationFunction(indivisualAPI)
  const isXsScreen = window.innerWidth < 370;
  // const [menus, setMenus] = useState(getMenuItem);
  // const [commentText, setCommentText] = useState('');
  // const [commentCount, setCommentCount] = useState('');
  const [isTextFieldFocused, setIsTextFieldFocused] = useState(false);
  const [showEmoji, setShowEmoji] = useState(false);
  const [currentCommentId, setCurrentCommentId] = useState(false);
  const [reportTextComment, setReportTextComment] = useState('');
  const [reportPostComment, setReportPostComment] = useState('');
  const [currentPostDelete, setCurrentPostDelete] = useState('');
  const [currentPostReport, setCurrentPostReport] = useState('');
  const [currentCommentReport, setCurrentCommentReport] = useState('');


  // const menuSetting = useSelector((state) => state?.menu?.menuSetting ?? [])
  const navigate = useNavigate();
  const isMoreSection = ["LeaderSpeak", "FeedList", "Social", "Events"].indexOf(searchParams['indivisualAPI']) == -1;
  const showPostData = useSelector((state) => state.formData.formToSend);
  const showSetPostData = useSelector((state) => state.formData.formToUpdate);
  const [isLoadingFeedPost, setIsLoadingFeedPost] = useState(false);

  // code will run when someone comments or give reaction
  useEffect(() => {
    if (showSetPostData) {
      console.log({ showSetPostData });
      const transformedData = formTransform(showSetPostData.formToSend);
      console.log({ transformedData });
      console.debug("transformedData",showSetPostData)
      setFeedPostData(transformedData);
      setAllComments(transformedData)
    }
  }, [showPostData, showSetPostData])

  const handleTextFieldFocus = () => {
    setIsTextFieldFocused(true);
    setShowEmoji(true);
  };

  const handleCancelClick = () => {
    setCommentText('');
    setIsTextFieldFocused(false);
  };

  // const closeDialogPublish = () => {
  //   dispatch(closeConfirmDialog('ConfirmDialog'));
  // };

  // const handleReportDialog = () => {
  //   dispatch(openConfirmDialog('ReportDialog'));
  // }

  // const handleCommentReport = (currentComment, reportComment) => {
  //   console.log('z1', currentComment,reportComment);
  //   const api = 'CommentReport';
  //   const payload = {
  //     userId: currentComment.id,
  //     commentId: currentComment.userId,
  //     report: reportComment,
  //   };
  //   console.log('z1',{api,payload});

  // };

  const getQuarterYear = (date) => {
    // Get the month of the date
    const month = date.getMonth();

    // Calculate the quarter
    let quarter;
    if (month < 3) {
      quarter = 4;
    } else if (month < 6) {
      quarter = 1;
    } else if (month < 9) {
      quarter = 2;
    } else {
      quarter = 3;
    }

    // Get the full year
    let year = date.getFullYear();

    // Adjust year for quarter 4 of previous year
    if (quarter === 4) {
      year -= 1;
    }

    // Format the output string
    return `Q${quarter} Updates ${year}`;
  }

  // console.log({ formTransform })
  // console.log('aaaaa', feedPostData?.comments)

  const setAllComments = (data) => {
    let comment = (feedPostData.type == 'userJoining' ? data.comment : data.comments);
    comment = comment.sort((a, b) => {
      // Check if both objects have created_at field
      if (!a.created_at || !b.created_at) {
        // Keep original order if no created_at field
        return 0;
      }

      // Compare timestamps in descending order
      return new Date(b.created_at) - new Date(a.created_at);
    })
    setFeedPostComments(comment)
  }

  const getPostFeedData = () => {
    if (!userData?.userId) {
      return;
    }
    window.scroll(0, 0);
    setIsLoading(true);
    if (!feedPostData || !feedPostData.id) {
      let postFeed = window.location.pathname.split('/')[window.location.pathname.split('/').length - 1]
      console.log(postFeed)
      ApiService.get(indivisualAPI, { userId: userData.userId }, postFeed)
        .then((res) => {
          const data = res.data.data;
          if (data) {
            const transformedData = formTransform(data);
            // console.log({ transformedData })
            setFeedPostData(transformedData);
            setAllComments(transformedData)
            // setFeedPostComments(transformedData.comments)
            setIsLoading(false);
            setIsLoadingFeedPost(true)

          } else {
            // console.error("Data is missing or not in the expected format.");
          }
        })
        .catch((error) => {
          // console.error("API request error:", error);
          setIsLoading(false);
        });
    } else {
      if (indivisualAPI) {
        ApiService.get(indivisualAPI, { userId: userData.userId }, feedPostData.id)
          .then((res) => {
            let data = [res.data.data].map(v => formTransform(v))[0];
            if (data) {
              setFeedPostData(data);
              setAllComments(data)
              // setFeedPostComments(data.comments)
              setIsLoading(false);
              setIsLoadingFeedPost(true)
            }
          })
          .catch((error) => {
            setSnackBar({ dataLoad: true, message: error.message, severity: 'error' });
            setIsLoading(false);
          });
      }

    }
    setReactionBTN();
  }

  useEffect(() => {
    getPostFeedData();
  }, [userData]);

  const handleDialogDeletePostPublish = () => {
    ApiService.delete('deletePost', {}, feedPostData.id)
      .then((response) => {
        if (response.status === 200) {
          dispatch(closeConfirmDialog('ConfirmDeletePost'));
          navigate('/');
        }
        dispatch(openSnackbar({ dataLoad: true, message: response?.data?.data[0].title, severity: "info" }));
        // console.log('aaaa', response?.data?.data)
        // } 
      })
      .catch((error) => {
        dispatch(openSnackbar({ dataLoad: true, message: error.message, severity: "error" }));
      }
      );
  }

  const handlePostClick = (event) => {
    setAnchorE4(event.currentTarget);
  };

  const [selectedOption, setSelectedOption] = useState(null);

  const getUserReaction = () => {
    // fetch last reaction index of current user if any
    let reactions = feedPostData?.post_reactions?.reduce((prev, curr) => {
      if (prev == -1 && (curr.userId == userData.userId || curr.pivot?.user_id == userData.userId)) {
        prev = [curr];
      }
      return prev;
    }, -1) ?? -1;
    if (reactions != -1) {
      reactions = JSON.parse(JSON.stringify(reactions)).map(v => { v.reactionId = v.reactionId ?? parseInt(v.pivot?.reaction_id); return v; })[0]
    }
    return reactions;
  }

  const setReactionBTN = () => {
    let findUserReaction = getUserReaction();
    if (findUserReaction != -1) {
      setSelectedOption(findUserReaction?.reactionId ?? parseInt(findUserReaction?.pivot?.reaction_id))
    } else {
      setSelectedOption(null)
    }
  }

  useEffect(() => {
    setReactionBTN();
  }, [feedPostData])

  const handlePostClose = () => {
    setAnchorE4(null);
  };

  const formattedDate = feedPostData?.created_at
    ? new Date(feedPostData.created_at).toLocaleDateString(undefined, {
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    })
    : null;
  // console.log ('a1',feedPostData.created_at)

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [menus, setMenus] = useState([]);
  const open = Boolean(anchorEl);

  const handleClick = (event, menus) => {
    // console.log("sfkdj", { currentTarget: event.currentTarget })
    setMenus(menus);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // const [anchorE2, setAnchorE2] = React.useState(null);
  // const openMore = Boolean(anchorE2);
  // const handleMoreClick = (event) => {
  //   setAnchorE2(event.currentTarget);
  // };
  // const handleMoreClose = () => {
  //   setAnchorE2(null);
  // };

  const [form, setForm] = useState([]);
  const [likeCount, setLikeCount] = useState(0);
  const [ApplaudCount, setApplaudCount] = useState(0);
  const [heartCount, setHeartCount] = useState(0);
  const [smileCount, setSmileCount] = useState(0);

  const handleLikeClick = () => {
    setLikeCount(likeCount + 1);
  };

  const handleApplaudClick = () => {
    setApplaudCount(ApplaudCount + 1);
  };

  const handleHeartClick = () => {
    setHeartCount(heartCount + 1);
  };

  const handleSmileClick = () => {
    setSmileCount(smileCount + 1);
  };

  const openPost = Boolean(anchorE4);
  const id = openPost ? 'simple-popover' : undefined;


  const updateReaction = (updatedPost) => {
    // console.log({ feedPostData })
    setFeedPostData(updatedPost)
    setAllComments(updatedPost)
    // setFeedPostComments(updatedPost.comments)
  }

  // if()
  const album = feedPostData?.album?.images || feedPostData?.album?.carouselItems?.map(v => { v.url = v.carouselItem;; return v; }) || [];
  const formattedAlbum = Array.isArray(album)
    ? album.map(imageUrl => ({ url: imageUrl?.carouselItem || imageUrl?.image }))
    : [];

  // console.log({formattedAlbum,album});
  const goBack = () => {
    window.history.back();
  }

  ///////////like////////////
  const [throttleOptionClick, setThrottleOptionClick] = useState(false);

  const handleOptionClick = (option) => {
    if (throttleOptionClick)
      return;
    setThrottleOptionClick(true);

    let reactionId = 4;

    if (option === 'Like') {
      reactionId = 1;
    } else if (option === 'Love') {
      reactionId = 2;
    } else if (option === 'Applaud') {
      reactionId = 3;
    }

    // deducing case for posting reaction, deleting reaction or changing reaction

    let caseHere = 'PUT';

    let findUserReaction = getUserReaction();
    // console.log({ findUserReaction })

    if (findUserReaction == -1) {
      caseHere = "POST";
    } else if (findUserReaction.reactionId == reactionId) {
      caseHere = "DELETE";
    }

    let payload = { userId: userData?.userId, reactionId: reactionId, postId: feedPostData.id }
    if (reactPost != 'reactPost') {
      payload = { userId: userData?.userId, reactionId: reactionId, reactionType: feedPostData.type }
      payload[feedPostData.type + "Id"] = feedPostData.id;
      if (feedPostData.type == 'reward')
        payload['awardUserId'] = feedPostData.id;
      if (feedPostData.type == 'userWedding')
        payload['weddingId'] = feedPostData.id;
    }

    // console.log({ payload })
    switch (caseHere) {
      case "POST": ApiService.post(reactPost, payload)
        .then((response) => {

          ApiService.get(indivisualAPI, {}, feedPostData.id).then((res) => {
            const data = [res.data.data].map(v => { return formTransform(v) })[0];
            // console.log(data)
            if (data) {
              updateReaction(data);
              setThrottleOptionClick(false);
            }

          })
            .catch((error) => {
              // console.error('Error:', error);
              setSnackBar({ dataLoad: true, message: error.message, severity: 'error' });
              setThrottleOptionClick(false);
            });
        })
        .catch((error) => {
          // console.error('Error:', error);
          setSnackBar({ dataLoad: true, message: error.message, severity: 'error' });
          setThrottleOptionClick(false);
        });
        break;
      case "DELETE": ApiService.delete(reactPost, { reactionId: reactionId }, reactPost == 'reactPost' ? findUserReaction.id : findUserReaction.pivot?.id)
        .then((response) => {
          ApiService.get(indivisualAPI, {}, feedPostData.id).then((res) => {
            const data = [res.data.data].map(v => formTransform(v))[0];
            // console.log(res.data.data)
            if (data) {
              updateReaction(data);
              setThrottleOptionClick(false);
              // setPostsData(post);
            }

          })
            .catch((error) => {
              setSnackBar({ dataLoad: true, message: error.message, severity: 'error' });
              setThrottleOptionClick(false);
            });
        })
        .catch((error) => {
          setSnackBar({ dataLoad: true, message: error.message, severity: 'error' });
          setThrottleOptionClick(false);
        }
        );
        break;
      case "PUT": ApiService.put(reactPost, { reactionId: reactionId }, reactPost == 'reactPost' ? findUserReaction.id : findUserReaction.pivot?.id)
        .then((response) => {
          ApiService.get(indivisualAPI, {}, feedPostData.id).then((res) => {
            const data = [res.data.data].map(v => formTransform(v))[0];
            if (data) {
              updateReaction(data);
              setThrottleOptionClick(false);
            }

          })
            .catch((error) => {
              setSnackBar({ dataLoad: true, message: error.message, severity: 'error' });
              setThrottleOptionClick(false);
            });
        })
        .catch((error) => {
          setSnackBar({ dataLoad: true, message: error.message, severity: 'error' });
          setThrottleOptionClick(false);
        });
        break;
      default: setThrottleOptionClick(false);
    }

    handlePostClose();
  };

  const showLikes = (post) => {
    if (!post?.reactionCount) {
      return (<></>)
    }

    let userName = post.post_reactions.filter(p => p.userId == userData.userId || p.pivot?.user_id == userData.userId).length ? 'You' : '';

    let reactions = post.post_reactions.reduce((prev, curr) => {
      let reactionId = curr.reactionId ?? curr.pivot?.reaction_id;
      if (prev.indexOf(parseInt(reactionId)) == -1) {
        prev.push(parseInt(reactionId))
      }
      return prev;
    }, []).sort();

    if (post.reactionCount == 1) {
      return (
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Box sx={{ mr: 1 }}>{showReaction(reactions)}</Box>
          <Box>
            <Typography variant="defineCaption" color="secondary.semiHeading2">
              {userName}
            </Typography>
          </Box>
        </Box>
      )
    }

    return (
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        fontSize: 'medium',
        wordBreak: 'break-word',
        '@media (min-width: 600px)': {
          flexDirection: 'row',
        },
      }}>
        <Box>{showReaction(reactions)}</Box>
        <Box sx={{
          display: 'flex',
          cursor: 'pointer',
          mb: 1,
          '&:hover span': { color: 'black' },
        }} onClick={() => handleReactionDialog(post)}>
          <Box>
            <Typography variant="defineCaption" color="secondary.semiHeading2">
              {userName.length ? userName + ' ' + 'and' + ' ' : ' '}
            </Typography>
          </Box>
          <Box sx={{ ml: 0.5 }}>
            <Typography variant="defineCaption" color="secondary.semiHeading2">
              {post.reactionCount - (userName.length ? 1 : 0)} Others
            </Typography>
          </Box>
        </Box>
      </Box>
    )
  }


  const showReaction = (reactions) => {
    return (
      <>
        {reactions.map(v => {
          switch (v) {
            case 1: return (<img src={Like} alt="Like" title="Like" key="Like" className='icon-size-1' />)
            case 2: return (<img src={Favourite} alt="Heart" title="Heart" key="Heart" className='icon-size-1' />)
            case 3: return (<img src={Clapping} alt="Applaud" title="Applaud" key="Applaud" className='icon-size-1' />)
            case 4: return (<img src={Mood} alt="Curious" title="Curious" key="Curious" className='icon-size-1' />)
          }
        })}
      </>)
  }

  const handleReactionDialog = (post) => {
    dispatch(setData(post))
    dispatch(openDialog('reactionDialog'));
  }

  ////////////////--------------comments function-------------//////////////

  const handleTextFieldBlur = () => {
    setShowComment(false);
  };
  ///////////////////////Report Comment////////////////////////////////////////

  const reportComment = (commentId, postData) => {
    setCurrentCommentId(commentId);
    dispatch(setData(postData));
    dispatch(openConfirmDialog('ReportDialog'));
    // setFeedPostComments(feedPostComments.filter(v => v.id != commentId))
  }

  const handleReport = () => {
    if (currentCommentId.id == currentCommentReport) {
      return;
    }
    setCurrentCommentReport(currentCommentId.id);
    let api = reactPost == 'reactPost' ? 'CommentReport' : 'userCommentReport';
    let payload = new FormData();
    payload.append('userId', userData.userId);
    payload.append('commentId', currentCommentId.id);
    payload.append('report', reportTextComment);
    ApiService.postAdopter(api, payload)
      .then((response) => {
        if (response.status === 200) {
          reportComment(currentCommentId.id);
          dispatch(openSnackbar({ dataLoad: true, message: response?.data?.data[0].title, severity: "info" }));
          // setIsLoading(true);
          closeConfirmDialog();
          setCurrentCommentReport('');
          setReportTextComment('');
        }
        closeDialogPublish();
        dispatch(closeConfirmDialog('ReportDialog'));
      })
      .catch((error) => {
        dispatch(openSnackbar({ dataLoad: true, message: error.message, severity: "error" }));
        setCurrentCommentReport('');
      });
  };

  // Date format for announcement page

  // function formatDateAnnouncement(date) {
  //   // Extract date and time components
  //   const monthShort = [
  //     "", "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  //   ];
  //   const month = date.getMonth() + 1; // Months are 0-indexed
  //   const day = date.getDate();
  //   const year = date.getFullYear();
  //   const hours = date.getHours();
  //   const minutes = date.getMinutes();
  //   const ampm = hours >= 12 ? 'pm' : 'am';

  //   console.log(monthShort[month], monthShort, month)
  //   // Format the date and time
  //   let formattedDate = `${monthShort[month]} ${day} `;
  //   // if (year !== new Date().getFullYear()) {
  //   //   formattedDate += ` ${year}`;
  //   // }
  //   formattedDate += ` at ${hours % 12}:${minutes.toString().padStart(2, '0')} ${ampm}`;

  //   return formattedDate;
  // }

  //Date Format
  const formatDate = (dateOfBirth) => {
    const options = { day: 'numeric', month: 'long' };
    const date = new Date(dateOfBirth);
    return date.toLocaleDateString(undefined, options);
  };
  ////////
  // const handleReportClick = () => {
  //   handleReport()
  // }


  ///////////////////////End Report Comment////////////////////////////////////////
  const updateComment = (commentId, updatedComment) => {
    setFeedPostComments(feedPostComments.map(v => v.id == commentId ? { ...v, commentBody: updatedComment } : v))
  }

  const deleteComment = (commentId, postData) => {
    setCurrentCommentId(commentId);
    dispatch(setData(postData));
    dispatch(openConfirmDialog('ConfirmDialog'));
    setFeedPostComments(feedPostComments.filter(v => v.id != commentId))
  }

  const handleConfirm = () => {
    let api = reactPost == 'reactPost' ? 'deleteComment' : 'DeleteComment';
    if (currentCommentId.id == currentPostDelete || !currentCommentId.id) {
      return;
    }
    setCurrentPostDelete(currentCommentId.id);
    console.log({ currentCommentId })
    ApiService.delete(api, {}, currentCommentId.id)
      .then((response) => {
        if (response.status === 200) {
          deleteComment(currentCommentId.id);
          dispatch(openSnackbar({ dataLoad: true, message: response?.data?.data[0].title, severity: "info" }));
          closeConfirmDialog();
        }
        closeDialogPublish();
        dispatch(closeConfirmDialog('ConfirmDialog'));
      })
      .catch((error) => {
        dispatch(openSnackbar({ dataLoad: true, message: error.message, severity: "error" }));
        setCurrentPostDelete('');
      });
      setAnchorEl(null);
  };

  const handleCommentButtonClick = (feedPostComments, postData) => {
    // Pass the clicked comment object to the handleConfirm function
    // handleConfirm(feedPostComments);
    handleConfirm()
  };

  const handleComment = async () => {
    // if (commentText.trim() !== '') {
    //   setIsLoading(true);
    if (commentText.trim() !== '') {
      setIsLoading(true);
      const newComment = {
        userName: userData.userName,
        userAvatar: userData.userAvatar,
        commentBody: commentText,
        created_at: new Date().toISOString(),
      };
      try {
        let payload = {
          userName: userData.userName,
          userId: userData.userId,
          postId: feedPostData.id,
          commentBody: commentText,
        };
        if (reactPost != 'reactPost') {
          payload = { userId: userData?.userId, comment: commentText, commentType: feedPostData.type, status: 1 }
          payload[feedPostData.type + "Id"] = feedPostData.id;
          if (feedPostData.type == 'reward')
            payload['awardUserId'] = feedPostData.id;
          if (feedPostData.type == 'userJoining')
            payload['joiningId'] = feedPostData.id;
          if (feedPostData.type == 'userWedding')
            payload['weddingId'] = feedPostData.id;
        }
        // }
        // console.log({ reactPost })
        let api = reactPost == 'reactPost' ? 'submitComment' : 'UpdateComment';
        const response = await ApiService.post(api, payload);
        if (response.status === 200) {
          ApiService.get(indivisualAPI, { userId: userData?.userId }, feedPostData.id)
            .then((res) => {
              let data = [res.data.data].map(v => formTransform(v))[0];
              if (data) {
                setAllComments(data)
                dispatch(openSnackbar({ dataLoad: true, message: 'Your comment has been submitted successfully!', severity: 'info' }));
              }
            })
          // .catch((error) => {
          //   // setSnackBar({ dataLoad: true, message: error.message, severity: 'error' });
          // });

        }
        // else {
        //   // console.error('Error:', response.data);
        // }
      } catch (error) {
        setSnackBar({ dataLoad: true, message: 'API request failed', severity: 'error' });
      } finally {
        setIsLoading(false); // Set loading state to false after API request completes
      }
      setCommentText('');
      handleTextFieldBlur();
    }
    setAnchorEl(null);
  };




  const handleSnakbarClick = () => {
    dispatch(openSnackbar({ dataLoad: true, message: 'This is a success message!', severity: "info" }));
  };


  const handleSnakbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

  };


  const closeDialogDeletePostPublish = () => {
    dispatch(closeConfirmDialog('ConfirmDeletePost'));
  }

  const closeDialogPublish = () => {
    dispatch(closeConfirmDialog('ConfirmDialog'));
  }

  const closeDialogPostReportPublish = () => {
    setReportPostComment('');
    dispatch(closeConfirmDialog('ReportPostDialog'));
  }

  const closeDialogCommentReportPublish = () => {
    dispatch(closeConfirmDialog('ReportDialog'));
  }

  // const renderMoreIcons = (menus) => {
  //   console.log('aa', menus)
  // };

  // const handleCopyLink = (url) => {
  //   navigator.clipboard.writeText(url)
  //     .then(() => {
  //       console.log('Link copied:', url);

  //       dispatch(openSnackbar({ dataLoad: true, message: 'Link Copied.', severity: "info" }));
  //     })
  //     .catch((error) => {
  //       console.error('Copy failed: ', error);
  //     });
  // };

  const [showPicker, setShowPicker] = useState(false);

  const onEmojiClick = (event, emojiObject) => {
    setCommentText((prevInput) => prevInput + emojiObject.emoji);
    setShowPicker(false);
    console.log('c1',emojiObject.emoji);
  };

  const handleEmojiClick = (emojiObject) => {
    setShowPicker(emojiObject.currentTarget);
    console.log('c1',setShowPicker(emojiObject.currentTarget));
  };

  const handleEmojiClose = () => {
    setShowPicker(false);
  };

  const openEmoji = Boolean(showPicker);
  const idEmoji = openEmoji ? 'simple-popover' : undefined;

  /////////////////////////////
  const [showFullContent, setShowFullContent] = useState(true);
  console.log(showFullContent)
  const toggleShowFullContent = () => {
    setShowFullContent(!showFullContent);
  };

  const sanitizedPostBody = DOMPurify.sanitize(feedPostData?.postBody);
  // const paragraphs = sanitizedPostBody.split(/<\/p>|<br\s*\/?>/);
  // const maxParagraphs = 2;

  // const truncatedContent = paragraphs.slice(0, maxParagraphs).join('</p>') + '</p>';

  const words = sanitizedPostBody.split(' ');

  const maxWords = 20;
  const truncatedContent = words.slice(0, maxWords).join(' ');
  const showReadMore = words.length > maxWords;

  // const showReadMore = paragraphs.length > maxParagraphs;
  // console.warn(showReadMore)

  ///////////////////////////////////////////////////////
  const renderMoreIcons = (menus) => {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <IconButton
          aria-label="more"
          id="long-button"
          onClick={(e) => handleClick(e, menus)}
        >
          <MoreVertIcon />
        </IconButton>
      </Box>
    )
  }

  const openReportPostClick = () => {
    dispatch(openConfirmDialog('ReportPostDialog'));
  }

  const handleReportPostClick = () => {
    if (feedPostData.id == currentPostReport) {
      return;
    }
    setCurrentPostReport(feedPostData.id);
    let api = reactPost == 'reactPost' ? 'ReportPost' : 'userPostReport';
    let payload = new FormData();
    payload.append('userId', userData.userId);
    if (reactPost == 'reactPost') {
      payload.append('postId', feedPostData.id);
    } else {
      payload.append('post_id', feedPostData.id);
      payload.append('post_type', feedPostData.type);
    }
    payload.append('report', reportPostComment);
    ApiService.postAdopter(api, payload)
      .then((response) => {
        console.log({ response })
        if (response.status === 200) {
          // reportPost(currentPostId.id);
          dispatch(openSnackbar({ dataLoad: true, message: "Post reported successfully.", severity: "info" }));
          // closeConfirmDialog();
          setCurrentPostReport('');
          setReportPostComment('');
        }
        closeDialogPublish();
        dispatch(closeConfirmDialog('ReportPostDialog'));
      })
      .catch((error) => {
        dispatch(openSnackbar({ dataLoad: true, message: error.message, severity: "error" }));
        setCurrentPostReport('');
      });
      setAnchorEl(null);
  }

  const handleEditDialog = (postData) => {
    console.log({ postData })
    dispatch(setData(postData))
    dispatch(openDialog('editDialog'));
  }

  const handleConfirmDialog = (id, postData) => {
    dispatch(setData(postData));
    dispatch(openConfirmDialog(id));
    setAnchorEl(null);
  }

  const getCurrentMenu = (currPost) => {
    // console.log('a1', currPost, feedPostData.postSaved)
    if (currPost.userId == feedPostData.userId) {
      let currentUserMenu = feedPostData.userId == userData.userId;
      let saveOption = feedPostData.postSaved
        ? [{ type: 'Unsave', onClick: () => { handleUnsavePost(feedPostData) } }]
        : [{ type: 'Save', onClick: () => { handleSavePost(feedPostData) } }];

      let currentMenus = [...saveOption, { type: 'Copy', onClick: handleSnakbarClick }];
      if (!currentUserMenu && indivisualAPI == 'FeedList') {
        currentMenus = [
          ...currentMenus,
          { type: 'Report', onClick: () => { openReportPostClick(currPost) } },
          { type: 'Hide', onClick: () => { handleHidePost(currPost) } }
        ];
      } else if (currentUserMenu && indivisualAPI == 'FeedList') {
        currentMenus = [{ type: 'Edit', onClick: () => handleEditDialog(feedPostData) }, { type: 'Delete', onClick: () => { handleConfirmDialog('ConfirmDeletePost', feedPostData) } }, ...currentMenus]
      }

      return currentMenus;
    }
  }


  const getNormalMenu = () => {
    if (feedPostData.postSaved)
      return [
        { type: 'Save', onClick: () => { handleSavePost(feedPostData) } },
        { type: 'Copy', onClick: handleSnakbarClick },
        { type: 'Hide', onClick: handleSnakbarClick },
        { type: 'Report', onClick: handleSnakbarClick },
      ]
    else
      return [
        { type: 'Unsave', onClick: () => { handleUnsavePost(feedPostData) } },
        { type: 'Copy', onClick: handleSnakbarClick }
      ]
  }

  //////////
  const getMenuItem = (menu) => {
    switch (menu.type) {
      case 'Save': return (
        <MenuItem key="Save" onClick={(e) => { menu.onClick(); }}>
          <ListItemIcon>
            <BookmarkBorderIcon fontSize="medium" />
          </ListItemIcon>
          <Typography variant="body1">Save</Typography>
        </MenuItem>)
      case 'Unsave': return (<MenuItem key="Unsave" onClick={(e) => { menu.onClick(); }}>
        <ListItemIcon>
          <BookmarkIcon fontSize="medium" />
        </ListItemIcon>
        <Typography variant="body1">Unsave</Typography>
      </MenuItem>)
      case 'Copy': return (<MenuItem key="Copy" onClick={() => handleCopyLink(window.location.origin + encodeUrl())}>
        <ListItemIcon>
          <LinkIcon fontSize="medium" />
        </ListItemIcon>
        <Typography variant="body1">Copy Link</Typography>
      </MenuItem>)
      case 'Edit': return (<MenuItem key="Edit" onClick={(e) => { menu.onClick(); }}>
        <ListItemIcon>
          <EditIcon fontSize="medium" />
        </ListItemIcon>
        <Typography variant="body1">Edit</Typography>
      </MenuItem>)
      case 'Delete': return (<MenuItem key="Delete" onClick={(e) => { menu.onClick(); }}>
        <ListItemIcon>
          <DeleteIcon fontSize="medium" />
        </ListItemIcon>
        <Typography variant="body1">Delete</Typography>
      </MenuItem>)
      case 'Report': return (<MenuItem key="Report" onClick={(e) => { menu.onClick(); }}>
        <ListItemIcon >
          <ReportIcon fontSize="medium" />
        </ListItemIcon>
        <Typography variant="body1">Report</Typography>
      </MenuItem>)
      case 'Hide': return (<MenuItem key="Hide" onClick={(e) => { menu.onClick(); }}>
        <ListItemIcon>
          <VisibilityOffIcon fontSize="medium" />
        </ListItemIcon>
        <Typography variant="body1">Hide</Typography>
      </MenuItem>)
    }
  }

  const encodeUrl = () => {
    const queryStringObject = {
      indivisualAPI: indivisualAPI,
      reactPost: reactPost,
      feedPostName: feedPostName,
    };
    const encodedQueryString = encriptionHelper().encodeAndEncryptURLParams(queryStringObject);
    return `/feed/${feedPostData.id}`;
    // return `/feed/${feedPostData.id}?query=${encodedQueryString}`;
  }

  useEffect(() => {
    setReactionBTN();
  }, [feedPostData])

  const getMenus = (menus) => {
    return menus.map(menu => {
      return getMenuItem(menu)
    });
  }

  const handleHidePost = (currentPost) => {
    const api = 'HiddenPost';
    const payload = {
      userId: userData.userId,
      postId: currentPost.id,
    };
    // console.log('abc', currentPost.id)
    ApiService.postAdopter(api, payload)
      .then((response) => {
        dispatch(openSnackbar({ dataLoad: true, message: 'Your post has been hidden successfully!', severity: 'info' }));
      })
      .catch((error) => {
        setSnackBar({ dataLoad: true, message: error.message, severity: 'error' });
      });
  };

  const handleReportPost = (currentPost) => {
    const api = 'ReportPost';
    const payload = {
      userId: userData.userId,
      postId: currentPost.id,
      report: reportTextComment,
      // report:
    };
    // console.log('abc', currentPost.id)
    ApiService.postAdopter(api, payload)
      .then((response) => {
        dispatch(openSnackbar({ dataLoad: true, message: 'Your post has been report successfully.!', severity: 'info' }));
      })
      .catch((error) => {
        setSnackBar({ dataLoad: true, message: error.message, severity: 'error' });
      });
      setAnchorEl(null);
  };



  const handleCopyLink = (url) => {
    navigator.clipboard.writeText(url)
      .then(() => {
        dispatch(openSnackbar({ dataLoad: true, message: 'Your link has been copied successfully..', severity: "info" }));
      })
      .catch((error) => {
      });
  };

  const handleSavePost = (currerntPost) => {
    let api = reactPost == 'reactPost' ? 'savePostUser' : 'saveFavoriteFeeds';
    let payload = { userId: userData.userId, postId: currerntPost.id };
    const formData = new FormData();
    if (reactPost != 'reactPost') {
      // payload = {userId: userData.userId, type: post.type};
      if (feedPostData.type == 'reward')
        formData.append('awardUserId', feedPostData.id);
      else
        formData.append(feedPostData.type + "Id", feedPostData.id);
      formData.append('userId', userData.userId);
      formData.append('type', feedPostData.type);
    } else {
      formData.append('userId', userData.userId);
      formData.append('postId', currerntPost.id);
    }
    ApiService.postAdopter(api, formData)
      .then((response) => {
        let data = response.data.data;
        if (data) {
          dispatch(openSnackbar({ dataLoad: true, message: 'Your post has been saved successfully!', severity: "info" }));
          ApiService.get(indivisualAPI, { userId: userData.userId }, feedPostData.id).then((res) => {
            const data = [res.data.data].map(v => formTransform(v))[0];
            if (data) {
              updateReaction(data);
            }
          })
            .catch((error) => {
            })
        }
      })
      .catch((error) => {
        // Handle error
        setSnackBar({ dataLoad: true, message: error.message, severity: 'error' });
      });
    setAnchorEl(null);
  }

  const handleUnsavePost = (currerntPost) => {
    let api = reactPost == 'reactPost' ? 'unsavePostUser' : 'unsaveFavoriteFeeds';
    let payload = { userId: userData.userId, postId: currerntPost.id };
    if (reactPost != 'reactPost') {
      const formData = new FormData();
      formData.append('userId', userData.userId);
      formData.append('type', feedPostData.type);
      formData.append('postId', currerntPost.id);
      ApiService.postAdopter(api, formData)
        .then((response) => {
          dispatch(openSnackbar({ dataLoad: true, message: 'Your post has been unsaved successfully!', severity: "info" }));
          ApiService.get(indivisualAPI, { userId: userData.userId }, feedPostData.id).then((res) => {
            const data = [res.data.data].map(v => formTransform(v))[0];
            if (data) {
              updateReaction(data);
            }
          })
            .catch((error) => {
              // console.error('Error:', error);
            });
        })
        .catch((error) => {
          // console.error('Error:', error);
          setSnackBar({ dataLoad: true, message: error.message, severity: 'error' });
        });
    } else {
      ApiService.get(api, payload)
        .then((response) => {
          dispatch(openSnackbar({ dataLoad: true, message: 'Your post has been unsaved successfully!', severity: "info" }));
          ApiService.get(indivisualAPI, { userId: userData.userId }, feedPostData.id).then((res) => {
            const data = [res.data.data].map(v => formTransform(v))[0];
            if (data) {
              updateReaction(data);
            }
          })
            .catch((error) => {
              // console.error('Error:', error);
            });
        })
        .catch((error) => {
          // console.error('Error:', error);
          setSnackBar({ dataLoad: true, message: error.message, severity: 'error' });
        });
    }
    setAnchorEl(null);
  }



  //////////////////////////////////////////////////////
  return (

    <Box sx={{
      minHeight: 'calc(95vh - 90px)',
      position: 'relative',
      '& .grid1, & .grid2': {
        '@media screen and (min-width: 900px)': {
          overflowY: 'auto',
          maxHeight: '100vh',
          '&::-webkit-scrollbar': {
            width: '0.5em', // Set the width of the scrollbar
            display: 'none', // Hide the scrollbar
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'transparent', // Set the color of the thumb
          },
        }
      }
    }}>
      {isLoadingFeedPost ?
        <Grid container spacing={2}>
          <Grid className='grid1' item lg={8} md={7} sm={12} xs={12}>
            <Box sx={{ display: 'flex', color: '#2563EB', cursor: 'pointer', alignItems: 'center' }}>
              <Box sx={{ marginTop: '0.4rem' }} onClick={goBack}>
                {/* <ArrowBackIosIcon fontSize='1rem' onClick={goBack} /> */}
              </Box>
              {isMoreSection && (<>
                <Box sx={{ color: '#212121', '&:hover': { color: '#2563EB', } }} onClick={() => { navigate('/more') }}><Typography variant="h6">More</Typography></Box>
                <Typography px={1} variant="h6" sx={{ color: '#212121' }}>{' / '}</Typography>
              </>)}
              <Box sx={{ color: '#212121', '&:hover': { color: '#2563EB', } }} onClick={goBack}><Typography variant="h6">{isAnnouncementPage ? 'Announcements' : feedPostName}</Typography></Box>
            </Box>
            <Box sx={{ mt: 2 }}>
              <Card>
                {(feedPostData.userName && feedPostData.type != 'userBirth' && feedPostData.type != 'userWedding' && feedPostData.type != 'workAnniversary' && feedPostData.type != 'promotion' && feedPostData.type != 'reward' && feedPostData.type != 'child' && feedPostData.type != 'userJoining') && (
                  <Box>
                    <Grid container spacing={2}>
                      {/* <Grid item xs={2} sm={2} md={1.5} lg={1}>
                        <Box sx={{}}>
                          <Avatar
                            src={feedPostData.userAvatar}
                            alt={(feedPostData?.userName)}
                            sx={{
                              backgroundColor: 'transparent !Important',
                              height: '40px',
                              boxShadow: 'none',
                              width: '40px',
                              '& .MuiAvatar-img': {
                                height: '40px',
                                width: '40px',
                                textAlign: 'center',
                                objectFit: 'cover',
                                color: 'transparent',
                                textIndent: '10000px'
                              }
                            }} />
                        </Box>
                      </Grid> */}
                      <Grid item xs={10} sm={10} md={10} lg={10} display='flex'>
                      <Avatar
                            src={feedPostData.userAvatar}
                            alt={(feedPostData?.userName)}
                            // sx={{
                            //   backgroundColor: 'transparent !Important',
                            //   width: '62px',
                            //   height: '63px',
                            //   boxShadow: 'none',
                            //   '& .MuiAvatar-img': {
                            //     height: '40px',
                            //     width: '40px',
                            //     textAlign: 'center',
                            //     objectFit: 'cover',
                            //     color: 'transparent',
                            //     textIndent: '10000px'
                            //   }
                            // }}
                            sx={{
                              backgroundColor: 'transparent !Important',
                              width: {xs:'45px',sm:'62px'},
                              height: {xs:'45px',sm:'63px'},
                              boxShadow: 'none',
                              '& .MuiAvatar-img': {
                                  height: {xs:'45px',sm:'63px'},
                                  width: {xs:'45px',sm:'62px'},
                                  textAlign: 'center',
                                  objectFit: 'cover',
                                  color: 'transparent',
                                  textIndent: '10000px'
                              }
                          }} />
                        {feedPostData.type !== 'associate' && feedPostData.type !== 'leader' ? (
                          <Box sx={{ml:2}}>
                            
                            <Box sx={{ display: 'flex' }}>
                              <Typography variant="defineSubtitle2" color="primary.heading">{feedPostData['userName'] ? (feedPostData['userName'] + (feedPostData['middleName'] && feedPostData['middleName'] != '--' ? feedPostData['middleName'] : ' ') + (feedPostData['lastName'] ? feedPostData['lastName'] : ' ')) : feedPostData['name']}</Typography>
                            </Box>
                            <Box>
                              <Typography variant="Caption" color="secondary.semiHeading2">{formattedDate}</Typography>
                            </Box>
                            {feedPostData.designation && (
                              <Box>
                                <Typography variant='defineCaption' color="secondary.Heading2">Promoted as {feedPostData.designation} on {feedPostData?.promotedDate}</Typography>
                              </Box>
                            )}
                          </Box>
                        ) : null}
                        {feedPostData.type === 'associate' || feedPostData.type === 'leader' ? (
                          <Box sx={{ml:2}}>
                            <Box sx={{ display: 'flex' }}>
                              <Typography variant="defineSubtitle2" color="primary.heading">{feedPostData['userName'] ? (feedPostData['userName'] + (feedPostData['middleName'] && feedPostData['middleName'] != '--' ? feedPostData['middleName'] : ' ') + (feedPostData['lastName'] ? feedPostData['lastName'] : ' ')) : feedPostData['name']}</Typography>
                            </Box>
                            <Box>
                              <Typography variant='defineCaption' color="secondary.Heading2">{feedPostData.designation}</Typography>
                            </Box>
                            <Box>
                              <Typography variant="Caption" color="secondary.semiHeading2">{formattedDate}</Typography>
                            </Box>
                          </Box>
                        ) : null}
                      </Grid>
                      <Grid item xs={2} sm={2} md={2} lg={2}>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }} key={"menu" + feedPostData.id}>
                          {renderMoreIcons(getCurrentMenu(feedPostData))}
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                )}
                <Box sx={{ mt: 2 }}>
                  {!feedPostData.childName && (
                    <Box>
                      <Grid container spacing={2}>
                        <Grid item xs={10} sm={10} md={10} lg={10}>
                          <Box>
                            <Typography sx={{ mt: 1 }} color="primary.heading" variant='defineSubtitle2' />
                            {feedPostData.title}
                          </Box>
                          {(['event' , 'social' , 'notice'].indexOf(feedPostData.type ) != -1) &&
                            <Box>
                              {formattedDate != 'Invalid Date' && (

                                <Typography variant="Caption" color="secondary.semiHeading2">
                                  {formattedDate}
                                </Typography>
                              )}
                            </Box>
                          }
                          {/* <Box>
                            {isAnnouncementPage || && (
                              <Typography variant="Caption" color="secondary.semiHeading2">{formattedDate}</Typography>)
                            }
                          </Box> */}
                        </Grid>
                        {(feedPostData.title && ['leader', 'recipe', 'child', 'reward', 'associate'].indexOf(feedPostData.type) == -1) && (
                          <Grid item xs={2} sm={2} md={2} lg={2}>
                            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }} key={"menu" + feedPostData.id}>
                              {renderMoreIcons(getCurrentMenu(feedPostData))}
                            </Box>
                          </Grid>
                        )}
                      </Grid>
                    </Box>
                  )}

                  {feedPostData.type === 'child' ? (
                    <Box>
                      <Grid container spacing={2}>
                        <Grid item xs={10} sm={10} md={10} lg={10}>
                          <Box>
                            <Typography variant='defineSubtitle2' color="primary.heading">Child of {feedPostData?.userName} {feedPostData?.middleName} {feedPostData?.lastName}</Typography>
                          </Box>
                          <Box>
                            <Typography sx={{ mt: 1 }} variant='defineCaption' color="secondary.Heading2">
                              Born on {feedPostData.dateOfBirth}</Typography>
                          </Box>
                          <Box>
                            <Typography sx={{ mt: 1 }} variant='defineSubtitle2' color="primary.heading" >
                              {feedPostData.childName}</Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  ) : null}


                  {feedPostData.type === 'userBirth' ? (
                    <Box>
                      <Box>
                        <Typography variant='defineSubtitle2' color="primary.heading">
                          Happy Birthday {feedPostData?.userName}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography variant='defineCaption' color="secondary.Heading2">
                          Born On {feedPostData?.dateOfBirth ? formatDate(feedPostData.dateOfBirth) : ''}
                        </Typography>
                      </Box>
                    </Box>
                  ) : null}
                  {feedPostData.type === 'userWedding' ? (
                    <Box>
                      <Box>
                        <Typography variant='defineSubtitle2' color="primary.heading">{feedPostData?.userName} With {feedPostData?.lastName}</Typography>
                      </Box>
                      <Box>
                        <Typography variant='defineCaption' color="secondary.Heading2">Married On-{feedPostData.weddingDate}</Typography>
                      </Box>
                    </Box>
                  ) : null}
                  {feedPostData.type === 'workAnniversary' ? (
                    <Box>
                      <Box>
                        <Typography variant='defineSubtitle2' color="primary.heading">Happy Work Anniversary {feedPostData?.userName} {feedPostData?.middleName} {feedPostData?.lastName}</Typography>
                      </Box>
                      <Box>
                        <Typography variant='defineCaption' color="secondary.Heading2">Completed-{feedPostData.totalWorkExperience} Years</Typography>
                      </Box>
                    </Box>
                  ) : null}
                  {feedPostData.type === 'userJoining' ? (
                    <Box>
                      <Grid container spacing={2}>
                        <Grid item xs={10}>
                          <Box>
                            <Box>
                              <Typography variant='defineSubtitle2' color="primary.heading">Welcome {feedPostData?.userName}</Typography>
                            </Box>
                            <Box>
                              <Typography variant='defineCaption'>
                                Designation-{feedPostData?.degination}
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={2} key={"menu" + feedPostData.id}>
                          {renderMoreIcons(getCurrentMenu(feedPostData))}
                        </Grid>
                      </Grid>
                    </Box>
                  ) : null}
                  {feedPostData.type === 'promotion' ? (
                    <Box>
                      <Box>
                        <Typography variant='defineSubtitle2' color="primary.heading">Congratulation {feedPostData?.userName} {feedPostData?.middleName} {feedPostData?.lastName}</Typography>
                      </Box>
                      <Box>
                        <Typography variant='defineCaption' color="secondary.Heading2">Promoted as {feedPostData.designation} on {feedPostData?.promotedDate}</Typography>
                      </Box>
                    </Box>
                  ) : null}
                  {console.log({ feedPostData })}
                  {feedPostData.type === 'reward' ? (
                    <Box>
                      <Grid container spacing={2}>
                        <Grid item xs={10}>
                          <Box>
                            <Chip label={getQuarterYear(new Date(feedPostData.created_at))} sx={{ backgroundColor: '#ED6C02', color: '#fff' }} />
                          </Box>
                          <Box sx={{ mt: 2 }}>
                            <Typography fontWeight='var(--font-weight-4)' color="secondary.Heading2" variant='body2'>
                              Awarded to <Typography component="span" sx={{ color: 'blue' }}>{feedPostData?.awardName}</Typography> on {feedPostData?.receivedDate}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={2} key={"menu" + feedPostData.id}>
                          {renderMoreIcons(getCurrentMenu(feedPostData))}
                        </Grid>
                      </Grid>
                    </Box>
                  ) : null}
                </Box>
                <Box sx={{ mt: 2 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={feedPostData.type === 'accolade' ? 10 : 12}>
                      <Box>
                        <Typography sx={{ mt: 1, wordBreak: 'break-word' }} color="secondary.Heading2" variant="body2" dangerouslySetInnerHTML={{ __html: showFullContent ? sanitizedPostBody : truncatedContent }} />
                        {showReadMore && (
                          <Button onClick={toggleShowFullContent} sx={{ textTransform: 'unset !important' }}>
                            {showFullContent ? 'Read less' : 'Read more'}
                          </Button>
                        )}
                      </Box>
                    </Grid>
                    {(feedPostData.type == "accolade") && (
                      <Grid item xs={2}>
                        <Box sx={{ justifyContent: "flex-end", display: 'flex', marginTop: '0.3rem' }}>
                          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }} key={"menu" + feedPostData.id}>
                            {renderMoreIcons(getCurrentMenu(feedPostData))}
                          </Box>
                        </Box>
                      </Grid>
                    )}
                  </Grid>
                </Box>
                <Box sx={{ my: 1 }} className="text-center">
                  {feedPostData?.album?.carouselItems?.length > 1 ? (
                    <Box sx={{ borderRadius: '2rem' }}>
                      {/* <SimpleImageSlider width={680} height={328} images={formattedAlbum} bgColor='#FFFFFF' showNavs={true} /> */}
                      <PhotoComponent
                        // width={680} 
                        width={isXsScreen ? '100%' : '100%'}
                        height={isXsScreen ? '100vh' : '100vh'} images={formattedAlbum} bgColor='#FFFFFF' />
                    </Box>
                  ) : feedPostData?.album?.carouselItems?.length === 1 ? (
                    <Box>
                      {/* <img src={feedPostData?.album?.images[0]?.image} className="single-post img-fluid" /> */}
                      <PhotoComponent
                        // width={680} 
                        width={isXsScreen ? '100%' : '100%'}
                        height={isXsScreen ? '100vh' : '100vh'} images={[{ url: feedPostData?.album?.carouselItems?.[0]?.carouselItem }]} bgColor='#FFFFFF' />
                    </Box>
                  ) : feedPostData?.album?.images.length > 1 ? (
                    <Box sx={{ borderRadius: '2rem' }}>
                      {/* <SimpleImageSlider width={680} height={328} images={formattedAlbum} bgColor='#FFFFFF' showNavs={true} /> */}
                      <PhotoComponent
                        // width={680} 
                        width={isXsScreen ? '100%' : '100%'}
                        height={isXsScreen ? '100vh' : '100vh'} images={formattedAlbum} bgColor='#FFFFFF' />
                    </Box>
                  ) : feedPostData?.album?.images.length === 1 ? (
                    <Box>
                      {/* <img src={feedPostData?.album?.images[0]?.image} className="single-post img-fluid" /> */}
                      <PhotoComponent
                        // width={680} 
                        width={isXsScreen ? '100%' : '100%'}
                        height={isXsScreen ? '100vh' : '100vh'} images={[{ url: feedPostData?.album?.images[0]?.image }]} bgColor='#FFFFFF' />
                    </Box>
                  ) : feedPostData?.image ? (
                    <Box>
                      {/* <img src={feedPostData.image} className="single-post img-fluid" /> */}
                      <PhotoComponent
                        // width={680} 
                        width={isXsScreen ? '100%' : '100%'}
                        height={isXsScreen ? '100vh' : '100vh'} images={[{ url: feedPostData.image }]} bgColor='#FFFFFF' />
                    </Box>
                  ) : null}
                </Box>

                <Box>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Box>
                        {showLikes(feedPostData)}
                      </Box>
                    </Grid>
                    <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <Box>
                        {feedPostComments && feedPostComments.length ? (
                          <Button variant="text" sx={{ textTransform: 'unset !important' }}>
                            <Typography align='right' variant='body2' color='secondary'>{feedPostComments && feedPostComments.length} Comment{feedPostComments && feedPostComments.length !== 1 ? 's' : ''}</Typography>
                          </Button>
                        ) : (
                          null
                        )}

                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                <Box>
                  <Divider />
                </Box>
                <Box>
                  <Grid container spacing={2} sx={{ display: 'flex', alignItems: 'center' }}>
                    <Grid item xs={6}>
                      <Box display="flex" alignItems="center">
                        <Box mr={4}>
                          <IconButton aria-describedby={id} onMouseEnter={handlePostClick}>
                            {selectedOption ? (
                              <>
                                {selectedOption === 1 && <Box sx={{ display: 'flex', alignItems: 'center' }}><Box sx={{ mr: 1 }}><img src={Like} alt="Like" title="Like" className='icon-size' /></Box><Box sx={{ mb: 0.5 }}><Typography variant='subtitle2' color='secondary'>Like</Typography></Box></Box>}
                                {selectedOption === 2 && <Box sx={{ display: 'flex', alignItems: 'center' }}><Box sx={{ mr: 1 }}><img src={Favourite} alt="Heart" title="Heart" className='icon-size' /></Box><Box sx={{ mb: 0.5 }}><Typography variant='subtitle2' color='secondary'>Love</Typography></Box></Box>}
                                {selectedOption === 3 && <Box sx={{ display: 'flex', alignItems: 'center' }}><Box sx={{ mr: 1 }}><img src={Clapping} alt="Applaud" title="Applaud" className='icon-size' /></Box><Box sx={{ mb: 0.5 }}><Typography variant='subtitle2' color='secondary'>Applaud</Typography></Box></Box>}
                                {selectedOption === 4 && <Box sx={{ display: 'flex', alignItems: 'center' }}><Box sx={{ mr: 1 }}><img src={Mood} alt="Curious" title="Curious" className='icon-size' /></Box><Box sx={{ mb: 0.5 }}><Typography variant='subtitle2' color='secondary'>Curious</Typography></Box></Box>}
                              </>
                            ) : (
                              <Box sx={{ display: 'flex' }}>
                                <Box sx={{ mr: 1 }}><ThumbUpOffAltIcon /></Box>
                                <Box><Typography variant='subtitle2' color='secondary'>Like</Typography></Box>
                              </Box>

                            )}
                          </IconButton>
                          <Popover
                            id={id}
                            open={openPost}
                            anchorEl={anchorE4}
                            onClose={handlePostClose}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'left',
                            }}
                          >
                            <Box sx={{ p: 1, display: 'flex', alignItems: "center" }} onMouseLeave={handlePostClose}>
                              <LightTooltip title="Like" placement="top-end" >
                                <Box
                                  aria-label="Like"
                                  sx={{}}
                                  onClick={() => handleOptionClick('Like')}
                                // title="Like"
                                >
                                  <img src={Like} />

                                </Box>
                              </LightTooltip>
                              <LightTooltip title="Applaud" placement="top-end">
                                <Box
                                  aria-label="Applaud"
                                  sx={{ mx: 1 }}
                                  onClick={() => handleOptionClick('Applaud')}
                                // title="Applaud"
                                >
                                  <img src={Clapping} />
                                </Box>
                              </LightTooltip>
                              <LightTooltip title="Love" placement="top-end">
                                <Box
                                  aria-label="Love"
                                  sx={{}}
                                  onClick={() => handleOptionClick('Love')}
                                // title="Love"
                                >
                                  <img src={Favourite} />
                                </Box>
                              </LightTooltip>
                              <LightTooltip title="Curious" placement="top-end">
                                <Box
                                  aria-label="Curious"
                                  sx={{ mx: 1 }}
                                  onClick={() => handleOptionClick('Curious')}
                                // title="Curious"
                                >
                                  <img src={Mood} />
                                </Box>
                              </LightTooltip>
                            </Box>
                          </Popover>
                        </Box>
                        <Box>
                          <Button variant="text" color='secondary' startIcon={<ChatBubbleOutlineIcon color='secondary' />} sx={{ textTransform: 'unset !important' }}>
                            Comment
                          </Button>
                        </Box>

                      </Box>
                    </Grid>
                    <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>

                      {/* <Button variant="text" color='secondary' startIcon={<ChatBubbleOutlineIcon color='secondary' />} sx={{ textTransform: 'unset !important' }}>
                      Comment
                    </Button> */}

                    </Grid>
                  </Grid>
                </Box>
              </Card>
            </Box>
          </Grid>
          <Grid className='grid2' item lg={4} md={5} sm={12} xs={12}>
            <Box sx={{ p: 1, mt: 1, backgroundColor: '#ECEFF1', display: 'flex' }}>
              <Box sx={{ color: '#546E7A' }}><ChatBubbleOutlineIcon /></Box>
              <Box sx={{ color: '#546E7A', mx: 1 }}><Typography variant='subtitle2'>Comments</Typography></Box>
            </Box>
            <Box sx={{}}>
              <Card>
                <Box>
                  <Grid container spacing={2}>
                    <Grid item xs={2}>
                      <Box sx={{ mt: 1, mr: 1 }}>
                        <Avatar
                          src={userData.userAvatar}
                          alt={(userData?.userName)}
                          sx={{
                            backgroundColor: 'transparent !Important',
                            height: '40px',
                            boxShadow: 'none',
                            width: '40px',
                            '& .MuiAvatar-img': {
                              height: '40px',
                              width: '40px',
                              textAlign: 'center',
                              objectFit: 'cover',
                              color: 'transparent',
                              textIndent: '10000px'
                            }
                          }} />
                      </Box>
                    </Grid>
                    <Grid item xs={10}>
                      <Box sx={{ '.MuiTextField-root': { width: '100%' } }}>
                        <TextField
                          id="outlined-basic"
                          name="commentText"
                          variant="outlined"
                          placeholder="Write a Comment"
                          size="medium"
                          value={commentText}
                          onChange={(e) => setCommentText(e.target.value)}
                          onFocus={handleTextFieldFocus}
                          onBlur={handleTextFieldBlur}
                        />
                      </Box>
                      {isTextFieldFocused && (
                        <Box sx={{ my: 1 }}>
                          <Grid container spacing={2}>
                            <Grid item xs={2}>
                              <Box sx={{ color: '#6995D3' }} onClick={handleEmojiClick}>
                                <EmojiEmotionsIcon />
                              </Box>
                              <Popover
                                id={idEmoji}
                                open={openEmoji}
                                anchorEl={showPicker}
                                onClose={handleEmojiClose}
                                anchorOrigin={{
                                  vertical: 'bottom',
                                  horizontal: 'right',
                                }}
                              >
                                  <Picker Style={{ width: '100%', padding: '6px' }} onEmojiClick={onEmojiClick} />
                              </Popover>
                            </Grid>

                            <Grid item xs={10}>
                              <Box sx={{ justifyContent: 'flex-end', display: 'flex' }}>
                                <Button variant="text" size="small" onClick={handleCancelClick}>
                                  Cancel
                                </Button>
                                <Button variant="contained" size="small" onClick={handleComment} sx={{ ml: 2 }} disabled={isLoading}>
                                  Comment
                                </Button>
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                </Box>
                <Box sx={{ my: 1 }}>
                  <Divider />
                </Box>
                <Box>
                  {feedPostComments.map((comment, index) => (
                    <Comment
                      key={comment.id}
                      comment={comment}
                      index={index}
                      handleSnakbarClick={handleSnakbarClick}
                      updateComment={updateComment}
                      reactPost={reactPost}
                      deleteComment={deleteComment}
                      reportComment={reportComment}
                      feedPostComments={feedPostComments}
                    />
                  ))}
                </Box>
              </Card>

            </Box>
          </Grid>
        </Grid>
        :
        <SinglePostSkeleton />
      }
      <SharedDialog id="reactionDialog">
        <ReactionPostDialog id="reactionDialog" mode={'Reaction'} />
      </SharedDialog>
      <DynamicSnackbar />
      <ConfirmDialogBox
        id='ConfirmDialog'
        title="Delete"
        body={<p>Are you sure you want to delete this comment? This will remove the comment and can't be undone.</p>}
        onCancel={closeDialogPublish}
        onConfirm={handleCommentButtonClick}
        titleOne="Cancel"
        titleTwo="Delete"
      />

      <ConfirmDialogBox
        id='ConfirmDeletePost'
        title="Delete"
        body={<p>Are you sure you want to delete this post? This will remove the post and can't be undone.</p>}
        onCancel={closeDialogDeletePostPublish}
        onConfirm={handleDialogDeletePostPublish}
        titleOne="Cancel"
        titleTwo="Delete"
      />

      <ConfirmDialogBox
        id='ReportDialog'
        title="Report the comment"
        body={
          <Box sx={{ '& .MuiFormControl-root': { mt: 2, width: "-webkit-fill-available" } }}>
            <ReportTextField id="outlined-basic" label="Description" multiline maxRows={4} variant="outlined" setTextField={setReportTextComment} value={reportTextComment} />
          </Box>}
        onCancel={closeDialogCommentReportPublish}
        onConfirm={handleReport}
        titleOne="Cancel"
        titleTwo="Report"
        disableConfirmBtn={reportTextComment}
      />

      <ConfirmDialogBox
        id='ReportPostDialog'
        title="Report the post"
        body={
          <Box sx={{ '& .MuiFormControl-root': { mt: 2, width: "-webkit-fill-available" } }}>
            <ReportTextField id="outlined-basic" label="Description" multiline maxRows={4} variant="outlined" setTextField={setReportPostComment} value={reportPostComment} />
          </Box>}
        onCancel={closeDialogPostReportPublish}
        onConfirm={handleReportPostClick}
        // onConfirm={() => handleCommentReport(comments, reportComment)} 
        disableConfirmBtn={reportPostComment}
        titleOne="Cancel"
        titleTwo="Report"
      />
      <Menu
        id={"long-menu" + feedPostData.id}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuList>
          {getMenus(menus)}
        </MenuList>
      </Menu>

      <SharedDialog id="editDialog">
        <CreatePostDialog id="editDialog" mode={'edit'} />
      </SharedDialog>
    </Box>


  )
}

export default SinglePost