import React, { useState, useEffect } from 'react'
import ApiService from '../../../utils/ApiService';
import {
    Card, Typography, Grid, Box, styled, Chip, MoreVert, Divider, Tooltip, RadioGroup, Radio, FormLabel,
    MenuItem, FormControl, InputLabel, Checkbox, Select, FormGroup, Stack, FilterList, IconButton, PeopleOutline, FormControlLabel,
    WatchLaterOutlined, LocationOnOutlined, ArrowForwardOutlined, Button, Close,
    ListItemAvatar
} from '../../../utils/AllImportsHelper'
import Menu from '@mui/material/Menu';

import { useDispatch, useSelector } from 'react-redux';
import { closeDrawer } from '../../../features/drawers/drawerSlice';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/Inbox';
import DraftsIcon from '@mui/icons-material/Drafts';
import Avatar from '@mui/material/Avatar';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import avatar from "../../../assets/images/Avtaar.png"
import Link from '@mui/material/Link';
import { ContentCut, Delete, NotificationsOff } from '@mui/icons-material';
import { setGenaiWithPostType } from '../../../features/notifications/genaiWithPostTypeSlice';

const NotificationDrawer = ({ onUpdateFilters, filters, parentId }) => {
    const [unReadNotificationList, setUnReadNotificationList] = React.useState([]);
    let navigate = useNavigate()
    const [value, setValue] = React.useState(0);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    // const genaiActiveStatus=useSelector((state)=>console.debug("genaiActiveStatus", state))
    const generativeAiStatus = useSelector(state => state.genaiActiveStatus.genaiActiveStatus.genAIactiveStatus)
    // const generativeAiStatus = "1"

    console.debug("generativeAiStatus", generativeAiStatus)

    function CustomTabPanel(props) {
        const generativeAi = useSelector(state => state.genaiActiveStatus.genaiActiveStatus.genAIactiveStatus)

        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }
    CustomTabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }
    // const [departmentData, setDepartmentData] = useState(filters.departmentData ?? '');
    // console.log({departmentData})
    const [branch, setBranch] = useState([]);
    // const [branchChange, setBranchChange] = useState(filters.branchChange ?? []);

    // useEffect(() => {
    //     ApiService.get('orgDepartment').then(department => {
    //         setDepartment(department.data.data)
    //             //  console.log(department.data.data)
    //           })

    //           ApiService.get('branch').then(branch => {
    //             setBranch(branch.data.data)
    //                 //  console.log(branch.data.data)
    //               })

    //     }, []);

    // const changeDepartmentSelectValue = (event) => {
    //     setDepartmentData(event.target.value);
    // };

    // const handleBranchChange = (event) => {
    //     setBranchChange(event.target.value);
    //     // console.log(event.target.value,{event})
    // };

    const dispatch = useDispatch();
    const handleDrawerClose = (parentId) => {
        dispatch(closeDrawer(parentId));
    };

    // const handleFilterSubmit = () => {
    //     if (
    //         departmentData ||
    //         branchChange !== '' 
    //         ) {
    //     const selectedFilters = {
    //         departmentData,
    //       branchChange,
    //    };

    //     // Pass the selected filter values to the parent component
    //     onUpdateFilters(selectedFilters);
    //     dispatch(closeDrawer());
    //   }
    //   else {
    //     // Optionally, you can show a message or prevent the submission if no options are selected
    //     // For example, display a message to the user or prevent the submission
    //     console.log('Please select at least one filter option.');
    //   }}

    const bull = (
        <Box
            component="span"
            sx={{ display: 'inline-block', mx: '2px', transform: 'scale(2.8)', color: "#1D76FE" }}
        >
            •
        </Box>
    );

    const notificationList = [
        { id: 1, img: avatar, title: "Hardeep Srity has birthday today, do you want to send wish?", time: "10 min ago", type: "birthday", read: 0 },
        { id: 2, img: avatar, title: "Prakash Jain has completed 2 years today, do you want to wish?", time: "20 min ago", type: "work anniversaries", read: 0 },
        { id: 3, img: avatar, title: "Sanya Gowshami commented on your post", time: "15 min ago", type: "comment", read: 0 },
        { id: 4, img: avatar, title: "Rakul kumar posted: Today I earned my Describe the benefits of using cloud services badge! I’m so proud to be celebrating this achi", time: "5 min ago", type: "leaderspeak", read: 1 },
        { id: 5, img: avatar, title: "Join Diwali Vogue 2024", time: "40 min ago", type: "event", read: 1 },
    ]
    useEffect(function () {
        const unRead = notificationList.filter((data) => data.read === 0)
        setUnReadNotificationList(unRead)
    }, [])
    function generatePost(type) {
        dispatch(closeDrawer("notification"))
        let convertToLowercase=type.toLowerCase().replace(/\s/g, '_')
        navigate(`/generative-ai?type=${convertToLowercase}`)
        dispatch(setGenaiWithPostType(convertToLowercase))
    }
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <>
            {/* <Box sx={{minWidth:{sm:'var(--width-drawer-sm)', md:'var(--width-drawer-sm)', lg:'var(--width-drawer-sm)'}}}> */}
            <Box sx={{ width: { sm: '50rem' } }}>
                <Stack sx={{ backgroundColor: "#DBEAFE" }}>
                    <Box sx={{ p: '.5rem .1rem' }}>
                        <Grid container direction="row" alignItems="center">
                            <Grid item xs={10}>
                                <Box pl='1rem' display='flex'>
                                    <Typography variant="body1" color="text.primary" fontWeight="700" display="block" >Notifications ({notificationList?.length})</Typography>
                                </Box>
                                <Box pl='1rem' display='flex'>
                                    <Typography variant="body2" color="text.primary" display="block" >You have {unReadNotificationList.length} unread messages</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <IconButton aria-label="Close Drawer" component="label" onClick={handleDrawerClose} color='black'>
                                    <Close sx={{ color: 'black' }} />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Box>
                </Stack>
                <Box sx={{ height: '80vh', overflow: 'auto', p: 3 }}>
                    <Box sx={{ width: '100%' }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                <Tab label="Recent" {...a11yProps(0)} />
                                <Tab label="All" {...a11yProps(1)} />
                            </Tabs>
                        </Box>
                        <CustomTabPanel value={value} index={0}>
                            <Link >Mark all as read</Link>
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={1}>
                            <Link>Mark all as read</Link>
                        </CustomTabPanel>
             
                    </Box>
                    <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
                        <nav aria-label="main mailbox folders">
                            <List>
                                {notificationList.map((data) => <ListItem disablePadding sx={data.read === 0 ? { backgroundColor: "#ECEFF1", borderRadius: 1, mb: 2 } : { border: "2px solid #DBEAFE", borderRadius: 1, mb: 2 }}>
                                    <ListItemButton sx={{ p: 2 }}>
                                        <ListItemAvatar sx={{ alignSelf: "baseline", display: "flex" }}>
                                            {data.read === 0 && <span>{bull}</span>}
                                            <Avatar alt="Cindy Baker" src={data.img} sx={{ mx: 1, mt: 1, height: 50, width: 50 }} />
                                        </ListItemAvatar>
                                        <ListItemText primary={<Box display={'flex'} justifyContent={'space-between'}>
                                            <Typography>
                                                {data.title}
                                            </Typography>
                                            <MoreVert onClick={handleClick} />
                                            <Menu
                                                id="basic-menu"
                                                anchorEl={anchorEl}
                                                open={open}
                                                onClose={handleClose}
                                                MenuListProps={{
                                                    'aria-labelledby': 'basic-button',
                                                }}
                                            >
                                                <MenuItem onClick={handleClose}>
                                                    <ListItemIcon>
                                                        <Delete fontSize="small" />
                                                    </ListItemIcon>
                                                    Delete Notification</MenuItem>
                                                <MenuItem onClick={handleClose}>
                                                    <ListItemIcon>
                                                        <NotificationsOff fontSize="small" />
                                                    </ListItemIcon>
                                                    Mute Notification</MenuItem>
                                            </Menu>
                                        </Box>}
                                            secondary={
                                                <Box>
                                                    <Typography my={1}>10 min ago</Typography>
                                                    {generativeAiStatus === '1' && <Box mt={2} >
                                                        <Button variant='contained' onClick={()=>generatePost(data.type)}>Generate {data.type} Post</Button>
                                                        <Button sx={{ ml: 4 }}>Decline</Button>
                                                    </Box>}
                                                </Box>
                                            } />
                                    </ListItemButton>
                                </ListItem>)}
                            </List>
                        </nav>
                    </Box>
                </Box>
            </Box>
        </>
    )
}

export default NotificationDrawer