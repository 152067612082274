import React, { useEffect, useState } from 'react';
import { Box, Grid, Card } from '@mui/material';
import Post from '../../component/FeedPost.js/Post';
import CreatePost from '../../component/FeedPost.js/CreatePost';
import AnnouncementComponent from '../FeedPost.js/AnnouncementComponent';
import Advertisment from '../../component/FeedPost.js/Advertisment';
import ApiService from '../../utils/ApiService';
import { useSelector, useDispatch } from 'react-redux';
import SharedDialog from '../../features/dialogBox/dialogBox';
import CreatePostDialog from '../../component/dialogBox/CreatePostDialog';
import DynamicSnackbar from '../../features/snackbar/Snackbar';
import { closeConfirmDialog, openConfirmDialog } from '../../features/confirmDialogBox/confirmDialogBoxSlice';
import ConfirmDialogBox from '../../features/confirmDialogBox/confirmDialogBox';
import ReactionPostDialog from '../dialogBox/ReactionPostDialog';
import { openSnackbar, closeSnackbar } from '../../features/snackbar/snackbarSlice';
import ReportTextField from '../../component/forms/TextField';

const MyConnectLayout = ({ setFlag, posts, announcements, isFeed, isHeader, isMore, isAwardHeader, isCelebration, isAwardTo, isBirthday, isJoiner, isPromotions, isAccolades, isWorkAniversary, isWeddings, isbirthAnnouncement, isStories, isCompany, isEvent, isLeaderSpeak, setPostsList, indivisualAPI, formTransform, reactPost, isOrganisationalFeed, isHiddenPost, isFavoriteFeeds }) => {
  const dispatch = useDispatch();
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const user = useSelector((state) => state.user?.userListName)
  const currentPost = useSelector((state) => state?.dialog?.data);
  const [reportTextComment, setReportTextComment] = useState('');
  const [currentPostId, setCurrentPostId] = useState(false);
  const [currentPostDelete, setCurrentPostDelete] = useState('');
  const [currentPostReport, setCurrentPostReport] = useState('');

  const clearReportTextComment = () => {
    setReportTextComment('');
  };
  const handleConfirm = () => {
    if (currentPost.id == currentPostDelete) {
      return;
    }
    setCurrentPostDelete(currentPost.id);
    ApiService.delete('deletePost', {}, currentPost.id)
      .then((response) => {
        if (response.status === 200) {
          dispatch(closeConfirmDialog('ConfirmDialog'));
          if (setPostsList) {
            let i = posts.reduce((prev, curr, index) => {
              if (curr.id == currentPost.id) {
                prev = index;
              }
              return prev;
            }, -1)
            let updatedPostData = [...posts];
            updatedPostData.splice(i, 1);
            setPostsList(updatedPostData);
          }
        }
        dispatch(openSnackbar({ dataLoad: true, message: response?.data?.data[0].title, severity: "info" }));
        // console.log('aaaa', response?.data?.data)
        // } 
      })
      .catch((error) => {
        dispatch(openSnackbar({ dataLoad: true, message: error.message, severity: "error" }));
        setCurrentPostDelete('');
      }
      );
    // setAnchorEl(null);
  };

  const closeDialogPublish = () => {
    dispatch(closeConfirmDialog('ConfirmDialog'));
    setReportTextComment('');
  }
  /////////////////////Update Hide Posts/////////////////////////////////

  const updateHidePost = (updatedPost) => {
    let updatedPostData = JSON.parse(JSON.stringify(posts));
    setPostsList(updatedPostData.filter(v => v.id != updatedPost.id).map(d => formTransform(d)));
  }
  ////////////////////End Update Hide Posts/////////////////////////////////

  const updateUnsavedPost = (id) => {
    if (isFavoriteFeeds) {
      let updatedPostData = JSON.parse(JSON.stringify(posts));;
      setPostsList(updatedPostData.filter(v => v.id != id).map(d => formTransform(d)));
    }
  }

  const updateReaction = (updatedPost) => {
    console.log(isFavoriteFeeds || isOrganisationalFeed, { updatedPost })
    if (isFavoriteFeeds || isOrganisationalFeed) {
      setPostsList(updatedPost.map(d => formTransform(d)));
    } else {
      let i = posts.reduce((prev, curr, index) => {
        if (curr.id == updatedPost.id) {
          prev = index;
        }
        return prev;
      }, -1)
      let updatedPostData = JSON.parse(JSON.stringify(posts));;
      updatedPostData.splice(i, 1, updatedPost)
      // if(isHiddenPost){
      //   setPostsList(updatedPostData);
      // } else {
      setPostsList(updatedPostData.map(d => formTransform(d)));
      // }
    }
  }

  ///////////////////////Report Post////////////////////////////////////////


  const reportPost = (postId, post) => {
    setCurrentPostId(post);
    dispatch(openConfirmDialog('ReportDialog'));
  }

  const handleReport = () => {
    if (currentPost.id == currentPostReport) {
      return;
    }
    setCurrentPostReport(currentPost.id);
    let api = reactPost == 'reactPost' ? 'ReportPost' : 'userPostReport';
    let payload = new FormData();
    payload.append('userId', user.userId);
    if (reactPost == 'reactPost') {
      payload.append('postId', currentPostId.id);
    } else {
      payload.append('post_id', currentPostId.id);
      payload.append('post_type', currentPostId.type);
    }

    payload.append('report', reportTextComment);
    console.log("a5", { currentPost, payload })
    ApiService.postAdopter(api, payload)
      .then((response) => {
        console.log({ response })
        if (response.status === 200) {
          reportPost(currentPostId.id);
          dispatch(openSnackbar({ dataLoad: true, message: response?.data?.data[0].title, severity: "info" }));
          setCurrentPostReport('');
          // setIsLoading(false);
          setReportTextComment('');
        }
        closeDialogPublish();
        dispatch(closeConfirmDialog('ReportDialog'));
      })
      .catch((error) => {
        dispatch(openSnackbar({ dataLoad: true, message: error.message, severity: "error" }));
        setCurrentPostReport('');
      });
  };

  const handleReportPostClick = () => {
    handleReport()
  }

  ///////////////////////End Report Post////////////////////////////////////////

  return (
    <Box sx={{
      // minHeight: 'calc(95vh - 90px)',
      // position: 'relative',
      // '& .grid1, & .grid2': {
      //   overflowY: 'auto',
      //   maxHeight: 'calc(95vh - 90px)',
      //   '&::-webkit-scrollbar': {
      //     width: '0.5em', // Set the width of the scrollbar
      //     display: 'none', // Hide the scrollbar
      //   },
      //   '&::-webkit-scrollbar-thumb': {
      //     backgroundColor: 'transparent', // Set the color of the thumb
      //   },
      // },
    }}>
      <Grid sx={{
        minHeight: '100vh',
        position: 'relative',
        '@media screen and (min-width: 600px)': {
          '& .grid1, & .grid2': {
            overflowY: 'auto',
            maxHeight: '100vh',
            '&::-webkit-scrollbar': {
              width: '0.5em', // Set the width of the scrollbar
              display: 'none', // Hide the scrollbar
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'transparent', // Set the color of the thumb
            },
          },
        }
      }}

        container spacing={2}>
        <Grid className="grid1" item xs={12} sm={setFlag !== false ? 7 : 12} md={setFlag !== false ? 8.5 : 12} sx={{}} xl={setFlag !== false ? 9 : 12}>
          <Grid container spacing={2}>
            {isFeed &&
              <Grid item xs={12}>
                <Card>
                  <CreatePost />
                </Card>
              </Grid>
            }
            <Grid item xs={12}>
              {posts.map((post, index) => {
                return (
                  <Card key={"" + (post?.userName ?? post?.id) + index} sx={{ mb: 2 }}>
                    <Post post={post} isHeader={isOrganisationalFeed ? (post.type == 'leader' || post.type == 'associate') : isHeader} isMore={isOrganisationalFeed ? post.type == 'social' : isMore} isAwardTo={isAwardTo} isAwardHeader={isOrganisationalFeed ? post.type == 'reward' : isAwardHeader}
                      isCelebration={isCelebration} isBirthday={isBirthday} isJoiner={isJoiner} isPromotions={isPromotions} isCompany={isCompany}
                      isAccolades={isOrganisationalFeed ? post.type == 'accolade' : isAccolades} isWorkAniversary={isWorkAniversary} isWeddings={isWeddings} isbirthAnnouncement={isbirthAnnouncement}
                      isStories={isOrganisationalFeed ? post.type == 'recipe' : isStories} user={user} isEvent={isOrganisationalFeed ? (post.type == 'event' || post.type == 'notice') : isEvent} isLeaderSpeak={isOrganisationalFeed ? post.type == 'leader' : isLeaderSpeak} updateReaction={updateReaction} updateHidePost={updateHidePost} indivisualAPI={indivisualAPI} reactPost={reactPost} formTransform={formTransform} reportPost={reportPost} isOrganisationalFeed={isOrganisationalFeed} isHiddenPost={isHiddenPost} isFavoriteFeeds={isFavoriteFeeds} updateUnsavedPost={updateUnsavedPost} />
                  </Card>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
        {setFlag != false &&
          <Grid item className='height-2 grid2' xs={12} sm={5} md={3.5} xl={3}>
            <Grid container spacing={2}>
              {isFeed &&
                <Grid item xs={12}>
                  <AnnouncementComponent notice={announcements} />
                </Grid>
              }

              {!isFavoriteFeeds && !isOrganisationalFeed &&
                <Grid item xs={12}>
                  <Box sx={{ mb: 2 }}>
                    <Advertisment />
                  </Box>
                </Grid>
              }
            </Grid>
          </Grid>
        }
      </Grid>
      <SharedDialog id="editDialog">
        <CreatePostDialog id="editDialog" mode={'edit'} />
      </SharedDialog>

      <SharedDialog id="reactionDialog">
        <ReactionPostDialog id="reactionDialog" mode={'Reaction'} />
      </SharedDialog>

      <ConfirmDialogBox id='ConfirmDialog'
        sx={{ p: 2 }}
        title="Delete"
        body={<p>Are you sure you want to delete this post? This will remove the post and can't be undone.</p>}
        onCancel={closeDialogPublish}
        onConfirm={() => handleConfirm()}
        titleOne="Cancel"
        titleTwo="Delete">
      </ConfirmDialogBox>
      <DynamicSnackbar />
      <ConfirmDialogBox
        id='ReportDialog'
        title="Report the post"
        body={
          <Box sx={{ '& .MuiFormControl-root': { mt: 2, width: "-webkit-fill-available" } }}>
            <ReportTextField id="outlined-basic" label="Description" multiline maxRows={4} variant="outlined" setTextField={setReportTextComment} value={reportTextComment} />
          </Box>}
        onCancel={closeDialogPublish}
        onConfirm={handleReportPostClick}
        disableConfirmBtn={reportTextComment}
        // onEnter={clearReportTextComment}
        // onConfirm={() => handleCommentReport(comments, reportComment)} 
        titleOne="Cancel"
        titleTwo="Report"
      />
    </Box>
  );
};

export default MyConnectLayout;