import { createSlice } from '@reduxjs/toolkit';

const allUsersSlice = createSlice({
    name: 'allUsers',
    initialState: {
        allUsers: [],
    },
    reducers: {
        setUsersList: (state, action) => {
            state.allUsers = action.payload;
        },
    },
});

export const { setUsersList } = allUsersSlice.actions;
export default allUsersSlice.reducer;
