import { createSlice } from '@reduxjs/toolkit';

const gamificationSettingStatusSlice = createSlice({
    name: 'gamificationStatus',
    initialState: {
        gamificationActiveStatus: {},
    },
    reducers: {
        setGamificationStatus: (state, action) => {
            state.gamificationActiveStatus = action.payload;
        },
    },
});

export const { setGamificationStatus } = gamificationSettingStatusSlice.actions;
export default gamificationSettingStatusSlice.reducer;
