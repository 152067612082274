import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Grid from '@mui/material/Grid';
// import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Header from '../header/Header'
import Footer from '../../layouts/footer/Footer';
import { Link, useNavigate } from 'react-router-dom';
import { Typography, Button, Card, Menu, CardContent, Divider } from '@mui/material';
// import DashboardIcon from '@mui/icons-material/Dashboard';

// import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentRoute } from '../../features/sidebarRouting/sidebarSelectionSlice';
import './Sidebar.css'
// import Tooltip from '@mui/material/Tooltip';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { searchValueSet } from '../../features/headerSearch/headerSearch';
import { setPageNo } from '../../features/dashboardData/dashboardData';
// import { useMenuContext } from '../../utils/MenuContext';
import listRoutes from './listRoutes.json';
import Icons from './Icons';
import { setMenuSetting } from '../../features/menusStore/menuSlice';
import ApiService from '../../utils/ApiService';
import { ArrowBack, Drafts, Help, Inbox, Search, Settings } from '@mui/icons-material';
import genaiIcon from "../../assets/images/genaiIcon.svg"
import { setGenaiWithPostType } from '../../features/notifications/genaiWithPostTypeSlice';

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: '#2563EB',

    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#fff',
        color: '#000000',
        fontWeight: '400',
        boxShadow: '-1px 0px 6px #aaa',
        // padding: "15px",
        fontSize: theme.typography.pxToRem(14),
        border: '1px solid #2563EB',
        borderRadius: 2,
        // zIndex:10000
    },
}));

const drawerWidth = 240;

const openedMixin = (theme) => ({
    width: drawerWidth,
    // height: `calc(100vh - 0px - ${theme.spacing(9)})`,
    // height: '100%',
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
    // borderTopRightRadius:'15px',
    borderRight: 0,
    // position: 'relative',
    top: 61,
    paddingRight: 15,
});

const closedMixin = (theme) => ({
    // height: `calc(100vh - ${theme.mixins.toolbar.minHeight}px - ${theme.spacing(9)})`,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    borderRight: 0,
    background: '#ffffff',
    top: 61,
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('xs')]: {
        width: `calc(${theme.spacing(0)} + 1px)`,
    },
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
    [theme.breakpoints.up('md')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
    [theme.breakpoints.up('lg')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});


const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => (prop !== 'open' || prop !== 'mobileOpen'),
})(({ theme, open, mobileOpen }) => ({
    // zIndex: theme.zIndex.drawer - 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...((open || mobileOpen) && {
        // width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => (prop !== 'open' || prop !== 'mobileOpen') })(
    ({ theme, open, mobileOpen }) => ({
        flexShrink: 0,
        whiteSpace: 'nowrap',
        zIndex: 0,
        boxSizing: 'border-box',
        ...((open || !mobileOpen) && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...((!open || mobileOpen) && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

export default function Sidebar({ isTrash, children, ...props }) {
    const menus = useSelector((state) => state?.menu?.menuSetting ?? [])
    const topLeftMenus = useState([]);
    const [open, setOpen] = React.useState(true);
    // const [currentRoute, setcurrentRoute] = useState(0);
    const { currentRoute } = useSelector(state => state.routing)
    console.log("saurabh the coder", currentRoute)
    const { window } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [selectedTab, setSelectedTab] = useState(localStorage.getItem('selectedTab') || '/');
    const [itemsList, setItemsList] = useState([]);
    let navigate = useNavigate()

    const dispatch = useDispatch();

    useEffect(() => {
        ApiService.get('menuSetting').then(menuSetting => {
            dispatch(setMenuSetting(menuSetting.data.data));
        })

        const handleStorageChange = (event) => {
            console.log("More hata diya", { event })
            if (event.key === 'selectedTab') {
                // Handle the change in localStorage for the specific key
                // setSelectedTab(event.newValue);
            }
        };

        // console.log("MewOne",{window});
        // Add event listener when the component mounts
        window.addEventListener('storage', handleStorageChange);

        // Clean up the event listener when the component unmounts
        return () => {
            // console.log("MewTwo");
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []); // Empty dependency array means this effect runs once when the component mounts

    useEffect(() => {
        if (menus.length) {
            console.log('aaa', menus)
            let filterredMenu = menus.filter((menu) => menu.parentMenu === "0" && menu.subMenu.length == 0);
            let filterredMenuName = filterredMenu.map(v => v.identifier);
            let sideMenu = listRoutes.filter(routes => filterredMenuName.indexOf(routes.label) != -1);
            console.log(sideMenu)
            let sideMenuList = sideMenu.map(v => {
                let name = filterredMenu.filter((menu) => menu.identifier == v.label)[0].menuName;
                return { ...v, name }
            })
            let more = listRoutes[listRoutes.length - 1];
            sideMenuList = [...sideMenuList, { ...more, name: "More" }]
            setItemsList(sideMenuList);
            console.log(sideMenuList)
        }
    }, [menus])

    useEffect(() => {
        dispatch(setCurrentRoute(selectedTab));
        console.log('ok', selectedTab)
    }, [selectedTab]);

    const handleDrawerOpen = () => {
        // handleDrawerToggle();
        if (open === true) {
            setOpen(false);
        }
        else {
            setOpen(true);
        }
        //console.log({ open })
    };

    const handleDrawerToggle = () => {
        // handleDrawerToggle();
        if (mobileOpen === false) {
            setMobileOpen(true);
        }
        else {
            setMobileOpen(false);
        }
    };

    const container = window !== undefined ? () => window.document.body : undefined;

    const handleListItemClick = (e, path) => {
        // setcurrentRoute(path);
        dispatch(setPageNo(0))
        dispatch(setCurrentRoute(path));
        dispatch(searchValueSet(''))
        setSelectedTab(path);
        localStorage.setItem('selectedTab', path);

    };
    function onRedirectToGenai() {
        navigate(`/generative-ai`)
        dispatch(setGenaiWithPostType(""))
    }
    return (
        <>
            <Box>
                <Box sx={{ display: 'flex' }}>
                    <AppBar position="fixed" open={mobileOpen} sx={{ background: "#EEF6FF", boxShadow: 'none' }}>
                        <Toolbar>
                            {console.log("Working Count 1")}
                            <IconButton
                                color="black"
                                aria-label="open drawer"
                                onClick={(window.innerWidth > 1024) ? handleDrawerOpen : handleDrawerToggle}
                                edge="start"
                                sx={{
                                    mb: isTrash ? '2rem !important' : '',
                                    // marginBottom: { xs: '2rem', sm: '2rem' },
                                    // position:'fixed',
                                    marginRight: 0,
                                    ...(open),
                                    color: '#000000',
                                    display: { xs: 'block', sm: 'block' },
                                    '& .MuiDrawer-paper': { boxSizing: 'border-box' },
                                }}
                            >
                                {/* <MenuIcon /> */}
                                <MenuOpenIcon />
                            </IconButton>
                            <Header />
                        </Toolbar>
                    </AppBar>



                    <Drawer
                        className="sidebar-drawer"
                        variant={(window.innerWidth > 1024) ? "permanent" : "temporary"}
                        open={(window.innerWidth > 1024) ? open : mobileOpen}
                        onClose={(window.innerWidth > 1024) ? handleDrawerOpen : handleDrawerToggle}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}


                        sx={{
                            top: 60,
                            // display: { xs: 'none', sm:'block' },
                            backgroundColor: "#ffffff",
                            '& .MuiDrawer-paper': { boxSizing: 'border-box' },
                            '& .MuiDrawer-paperAnchorLeft:not(.MuiDrawer-paperAnchorDockedLeft)': { width: { xs: "100%" } }
                        }}

                    >
                        <List sx={{ textAlign: '-webkit-center', position: 'relative', height: 'calc(100vh - 60px)', '& a:last-child': { position: 'absolute', bottom: 60 } }}>
                            {itemsList.map((text, index) => (
                                <ListItem key={text.name} disablePadding sx={{ display: 'block' }}
                                    component={Link}
                                    to={text.path}
                                    onClick={(e) => handleListItemClick(e, text.path)}
                                    className={(mobileOpen || open) ? "open-selected-tab" : "close-selected-tab"}

                                    style={
                                        selectedTab === text.path || (selectedTab == '/announcements' && text.path == '/more')
                                            ? {
                                                background: '#DBEAFE',
                                                // boxShadow: "2px 3px 6px rgba(0, 0, 0, 0.3)",
                                            }
                                            : {}
                                    }
                                >
                                    <HtmlTooltip title={open ? '' : text.label} placement="right" arrow>
                                        <ListItemButton sx={{ minHeight: 48, justifyContent: (mobileOpen || open) ? 'initial' : 'center', px: 2.5, }} onClick={() => { setMobileOpen(false); }}>
                                            <ListItemIcon style={selectedTab === text.path || (selectedTab == '/announcements' && text.path == '/more') ? { color: "#3C82F5" } : { color: '#96A5B8' }}
                                                sx={{ minWidth: 0, mr: (mobileOpen || open) ? 1 : 'auto', justifyContent: 'center' }}>
                                                <Icons icon={text.icon} />
                                            </ListItemIcon>
                                            <ListItemText disableTypography primary={<Typography variant="body1" color="text.primary" style={selectedTab === text.path || (selectedTab == '/announcements' && text.path == '/more') ? { color: 'rgba(4, 5, 7, 0.87)', fontWeight: 700 } : { color: '#000000' }}>{text.label}</Typography>} sx={{ opacity: (mobileOpen || open) ? 1 : 0 }} />
                                        </ListItemButton>
                                    </HtmlTooltip>
                                </ListItem>
                            ))}
                        </List>
                    </Drawer>
                    {/* <Tooltip title={text.label} placement="right" arrow> */}
                    <Box component="main" sx={{ flexGrow: 1, width: { sm: `calc(100% - ${drawerWidth}px)` }, pt: 8, pl: 2, pr: 2, background: '#EEF6FF', minHeight: '100vh', minWidth: 0 }}>
                        <Box>
                            {children}
                        </Box>
                        <Grid item sx={{ sm: `calc(100% - ${drawerWidth}px)`, margin: '5px 0 5px' }}>
                            <AppBar position="static" sx={{ background: "#FFFFFF" }}>
                                <Footer />
                            </AppBar>
                        </Grid>
                    </Box>
                    <Drawer
                        sx={{
                            width: 60,
                            flexShrink: 0,
                            borderTopLeftRadius:3,
                            '& .MuiDrawer-paper': {
                                width: 60,
                                p: 0,
                                // borderTopLeftRadius:3,
                                // borderRadius: 3.
                            },
                        }}
                        variant="persistent"
                        anchor="right"
                        open={true}
                    >

                    <List sx={{ pr: 1 }}>
                        <ListItem disablePadding sx={{ mb: 1 }}>
                            <IconButton sx={{ ml: 1 }}>
                                <ArrowBack />
                            </IconButton>
                        </ListItem>
                        <Divider sx={{ mb: 1 }} />
                        <ListItem disablePadding sx={{ mb: 1 }}>
                            <IconButton sx={{ ml: 1 }}>
                                <Settings />
                            </IconButton>
                        </ListItem>
                        <ListItem disablePadding sx={{ mb: 1 }}>
                            <IconButton sx={{ ml: 1 }}>
                                <Help />
                            </IconButton>
                        </ListItem>
                        <ListItem disablePadding sx={{ mb: 1 }}>
                            <IconButton sx={{ ml: 1 }} onClick={onRedirectToGenai}>
                                <img src={genaiIcon} alt="genai" />
                            </IconButton>
                        </ListItem>
                    </List>
                    </Drawer>
                    {/* </Tooltip> */}
                </Box>

                {/* <Grid item xs={12}>
                <AppBar position="static" sx={{ background: "#FFFFFF" }}>
                    <Footer />
                </AppBar>
            </Grid> */}

            </Box>

        </>
    );
}