import React, { useEffect, useState } from 'react';
import { Box, Grid, Card, Typography, ButtonGroup, Button, Slider, FormControlLabel, Switch, Pagination, IconButton, CardHeader, CardContent, FormControl, InputLabel, Select, MenuItem, List, ListItem, ListItemButton, ListItemText, Link } from '@mui/material';
import ApiService from '../../utils/ApiService';
import { useSelector, useDispatch } from 'react-redux';
import SharedDialog from '../../features/dialogBox/dialogBox';
import CreatePostDialog from '../../component/dialogBox/CreatePostDialog';
import DynamicSnackbar from '../../features/snackbar/Snackbar';
import ReactionPostDialog from '../dialogBox/ReactionPostDialog';
import genaiImg1 from "../../assets/images/genaiImg1.svg"
import genaiImg2 from "../../assets/images/genaiImg2.svg"
import genaiImg3 from "../../assets/images/genaiImg3.svg"
import genaiImg4 from "../../assets/images/genaiImg4.svg"
import { ArrowForward, AutoAwesome, ChevronRight, ForkLeft, KeyboardArrowLeft } from '@mui/icons-material';
import SelectPurposeComponent from '../GenerativeAiComponent/SelectPurposeComponent';
import { openDialog, setData } from '../../features/dialogBox/dialogBoxSlice';
import { openSnackbar } from '../../features/snackbar/snackbarSlice';
import { GenaiContext } from '../../context/GenaiContext';
const GenerativeAILayout = ({ setFlag, posts, announcements, isFeed, isHeader, isMore, isAwardHeader, isCelebration, isAwardTo, isBirthday, isJoiner, isPromotions, isAccolades, isWorkAniversary, isWeddings, isbirthAnnouncement, isStories, isCompany, isEvent, isLeaderSpeak, setPostsList, indivisualAPI, formTransform, reactPost, isOrganisationalFeed, isHiddenPost, isFavoriteFeeds }) => {
  const dispatch = useDispatch();
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const user = useSelector((state) => state.user?.userListName)
  const currentPost = useSelector((state) => state?.dialog?.data);
  const [currentPage, setCurrentPage] = useState(1)
  const [toneOfVoiceList, setToneOfVoiceList] = useState([])
  const [selectedTone, setSelectedTone] = useState(1)
  const [selectedImage, setSelectedImage] = useState()
  const [wordLimit, setWordLimit] = useState()
  const [promptList, setPromptList] = useState([])
  const [generatedData, setGeneratedData] = useState("")
  const [purposeList, setPurposeList] = useState([])
  const [totalPages, setTotalPages] = useState(5)
  const [loadingPurposeList, setLoadingPurposeList] = useState(true)
  const [loadingPromptList, setLoadingPromptList] = useState(false)
  const [selectedPurpose, setSelectedPurpose] = useState("")
  const [selectWordLimit, setSelectWordLimit] = React.useState();
  const [generatePromptLoading, setGeneratePromptLoading] = React.useState(false);
  const [generateButtonValue, setGenerateButtonValue] = useState("Generate with AI");
  const [selectedEmployee, setSelectedEmployee] = useState();
  const [selectedSkills, setSelectedSkills] = useState();

  console.debug("userkjnwjjwr", user)

  const genaiWithPurposeType = useSelector((state) => state?.genaiPostType?.genaiWithPostType)


  const generateImage = [{ id: 1, img: genaiImg1 }, { id: 2, img: genaiImg2 }, { id: 3, img: genaiImg3 }, { id: 4, img: genaiImg4 }];
  ///////////////////////End Report Post////////////////////////////////////////
  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  useEffect(function () {
    ApiService.get('toneOfVoice').then(response => {
      setToneOfVoiceList(response.data.data)
    }).catch((error) => {
      console.log(error)
    })

    ApiService.get('wordLimit').then(response => {
      setWordLimit(response.data.data)
    }).catch((error) => {
      console.log(error)
    })
    ApiService.get('genaiPromptCategory').then(response => {
      setLoadingPurposeList(false)
      setPurposeList(response.data.data)

    }).catch((error) => {
      setLoadingPurposeList(false)
      dispatch(openSnackbar({ dataLoad: true, message: error.message, severity: "error" }));
      console.log(error)
    })

  }, [])
  useEffect(function () {
    if (purposeList.length) {
      setLoadingPromptList(true)
      console.debug("promptData:purposeList", purposeList)

      const promptData = purposeList?.find((data) => data.identifier.toLowerCase().replace(/\s/g, '_') === genaiWithPurposeType)
      setPromptList(promptData?.promptCategoryData)
      setTotalPages(promptData?.promptCategoryData?.length)
      setLoadingPromptList(false)
      console.debug("promptData", promptData)
    }
  }, [genaiWithPurposeType])
  // useEffect(function () {
  //   if (promptList.length) {
  //     setTotalPages(promptList.length)
  //   }
  // }, [promptList])
  function onSelectTone(id) {
    setSelectedTone(id)
  }
  function onSelectImage(id) {
    setSelectedImage(id)
  }
  function onGenerateContentWithAI() {
    let params = {
      skills: []
    };

    if (selectedPurpose) {
      params.genAIpromptCategoryId = selectedPurpose
    }
    if (promptList) {
      params.promptCategoryDataId = promptList && promptList[currentPage - 1]?.id
    }
    if (selectedTone) {
      params.genAItoneOfVoiceId = selectedTone
    }
    if (selectWordLimit) {
      params.wordLimit = selectWordLimit
    }
    if (selectedEmployee) {
      params.userId = selectedEmployee
    }
    if (selectedSkills) {
      selectedSkills.forEach((skill, index) => {
        params.skills[index] = skill;
      });
    }


    setGeneratePromptLoading(true)
    ApiService.get('genaiResponse', params).then(response => {
      console.debug("genaiResponse", response.data.data)
      // setLoadingPurposeList(false)
      // setPurposeList(response.data.data)
      setGeneratedData(response.data.data.prompt)
      setGeneratePromptLoading(false)
      if (response.data.data) {
        setGenerateButtonValue("Re-Generate")
      }
    }).catch((error) => {
      setGeneratePromptLoading(false)
      dispatch(openSnackbar({ dataLoad: true, message: error.message, severity: "error" }));

      // setLoadingPurposeList(false)
      console.log(error)
    })
    // setGeneratedData(promptList && promptList[currentPage - 1]?.promptData)
  }

  const openDialogPublish = (id) => {
    dispatch(openDialog(id));
    dispatch(setData({ postBody: generatedData }));
    // console.log(openDialog(id))
  }


  const handleSliderChange = (event, newValue) => {
    setSelectWordLimit(newValue);
  };

  const marks = [
    {
      value: 0,
      label: 'Min:0',
    },

    {
      value: 100,
      label: 'Max:100',
    },
  ];
  console.debug("setSelectedEmployee", selectedEmployee)
  const genaiContextValue = {
    setSelectedSkills,      // UserSkills list 
    setSelectedEmployee     // Set Selected Employee from dropdown
  }
  return (
    <GenaiContext.Provider value={genaiContextValue}>
      <Box >
        <Grid sx={{
          minHeight: '100vh',
          position: 'relative',
          '@media screen and (min-width: 600px)': {
            '& .grid1, & .grid2': {
              overflowY: 'auto',
              maxHeight: '100vh',
              '&::-webkit-scrollbar': {
                width: '0.5em', // Set the width of the scrollbar
                display: 'none', // Hide the scrollbar
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'transparent', // Set the color of the thumb
              },
            },
          }
        }}

          container spacing={2}>
          {/* <Grid className="grid1" item xs={12} sm={setFlag !== false ? 7 : 12} md={setFlag !== false ? 3 : 12} sx={{}} xl={setFlag !== false ? 9 : 12}> */}
          <Grid className="grid1" item xs={12} sm={7} md={8} sx={{}}>
            <Grid container spacing={2}>

              <Grid item xs={12}>
                {/* {[1,2,3].map((post, index) => {
                return ( */}
                <Card sx={{ mb: 2, p: 3 }}>
                  <Grid container spacing={2}>
                    {!genaiWithPurposeType && <SelectPurposeComponent setPromptList={setPromptList} purposeList={purposeList} loadingPurposeList={loadingPurposeList} setSelectedPurpose={setSelectedPurpose} selectedPurpose={selectedPurpose} />}
                    <Grid item xs={12}>
                      <Box sx={{ position: "relative" }}>
                        <Typography variant="h7" gutterBottom fontWeight={'550'}>Your Prompt</Typography>
                        <Box sx={{ border: "1px solid #C5C5C5", p: 1.5, borderRadius: 1, mt: 1, height: 300 }}>
                          {loadingPromptList ? <><Typography>Loading...</Typography></> : <>
                            {promptList && promptList.length ? promptList[currentPage - 1]?.promptData :
                              <Typography variant='body1' color={"#9e9e9e"}>Prompt will generate here</Typography>}
                            {promptList?.length > 0 && <Box sx={{ position: "absolute", bottom: 0, right: 0 }}>
                              <Box sx={{ width: 120 }}>
                                <IconButton onClick={handlePrevious} disabled={currentPage === 1}>
                                  <KeyboardArrowLeft />
                                </IconButton>
                                <span>{currentPage}</span>/<span>{totalPages}</span>
                                <IconButton onClick={handleNext} disabled={currentPage === totalPages}>
                                  <ChevronRight />
                                </IconButton>
                              </Box>
                            </Box>}
                          </>}
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box >
                        <Typography variant="h7" gutterBottom fontWeight={'550'}>Select Generated image</Typography>
                        <Box sx={{ border: "1px solid #C5C5C5", p: 1.5, borderRadius: 1, mt: 1 }}>
                          <Grid container spacing={1}>
                            {generateImage.map((data, index) => ( // Added parentheses and 'index' argument
                              <Grid item key={index} spacing={4}>
                                <Button variant={selectedImage === data.id && 'contained'} sx={{ p: 0.5 }} onClick={() => onSelectImage(data.id)} >{/* Added 'key' prop for React performance */}
                                  <img src={data.img} alt="generateImage" width={72} height={73} />
                                </Button> {/* Access 'img' property */}
                              </Grid>
                            ))}
                          </Grid>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box >
                        <Typography variant="h7" fontWeight={'550'} gutterBottom>Tone of voice</Typography>
                        <Box my={2}>
                          {toneOfVoiceList.map((data) => <Button variant={selectedTone === data.id && 'contained'} onClick={() => onSelectTone(data.id)} sx={selectedTone === data.id ? { textTransform: 'capitalize', mr: 1.5, mb: 1.5 } : { mr: 1.5, backgroundColor: "rgba(0, 0, 0, 0.08)", color: "black", textTransform: 'capitalize', fontWeight: 450, mb: 1.5 }}>{data.name}</Button>)}
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Box md={6} xs={12}>
                        <Typography variant="h7" fontWeight={'550'} gutterBottom>Approximate words</Typography><br />
                        <Slider defaultValue={50} aria-label="Default" valueLabelDisplay="auto"
                          value={selectWordLimit}
                          min={0}
                          max={100}
                          // marks={marks}
                          // min={wordLimit?.minLimitWord}
                          //   max={wordLimit?.maxLimitWord} 
                          onChange={handleSliderChange} />
                      </Box>
                    </Grid>

                    <Grid item xs={12}>
                      <Box md={6} xs={6}>
                        <FormControlLabel
                          control={
                            <Switch checked={true} onChange={handleChange} name="gilad" />
                          }
                          label={<Typography variant='h5' fontWeight={'400'}>Generate hashtags</Typography>}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box md={6} xs={6}>
                        <Button variant='contained' disabled={generatePromptLoading ? true : false} startIcon={<AutoAwesome />} onClick={onGenerateContentWithAI}>{generateButtonValue}</Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Card>
                {/* );
              })} */}
              </Grid>
            </Grid>
          </Grid>
          <Grid item className='height-2 grid2' xs={12} sm={5} md={4} >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Card sx={{ p: 0 }}>
                  <Box sx={{ backgroundColor: "#ECEFF1", p: 2 }}>
                    <Typography variant='h7' color={"#546E7A"}>Get Started</Typography>
                  </Box>
                  <CardContent>
                    {generatedData ? <>
                      <List>
                        <ListItem disablePadding sx={{ border: "1px solid #DBEAFE", borderRadius: 2 }}>
                          <ListItemButton>
                            <ListItemText primary={generatedData}
                              secondary={<div style={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                                <div style={{ flexGrow: 1 }}></div> {/* Empty div to push the secondary text to the right */}
                                <Link href="#" style={{ display: 'block' }} onClick={() => openDialogPublish('createPostWithAI')}>POST PREVIEW</Link>
                                <ArrowForward fontSize='small' sx={{ mx: 1 }} />
                              </div>} />
                          </ListItemButton>
                        </ListItem>
                      </List>
                    </> :
                      <>
                        <Typography mb={2}>1. Pick a prompt from the left-hand side “Prompt templates” panel, use the button to get a random prompt, or write one from scratch.</Typography>
                        <Typography mb={2}>2. Write or edit your prompt with what you want the AI to generate and hit the Generate button. </Typography>
                        <Typography mb={2}>3. Select the results you like and hit the Share on social button. </Typography>
                      </>
                    }
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <DynamicSnackbar />

        <SharedDialog id="createPostWithAI">
          <CreatePostDialog id="createPostWithAI" mode={'edit'} genai={true} />
        </SharedDialog>
        <SharedDialog id="reactionDialog">
          <ReactionPostDialog id="reactionDialog" mode={'Reaction'} />
        </SharedDialog>
        <DynamicSnackbar />
      </Box>
    </GenaiContext.Provider>
  );
};

export default GenerativeAILayout;