import { createSlice } from '@reduxjs/toolkit';

const genaiActiveStatusSlice = createSlice({
    name: 'genaiActiveStatus',
    initialState: {
        genaiActiveStatus: {},
    },
    reducers: {
        setGenaiActiveStatus: (state, action) => {
            state.genaiActiveStatus = action.payload;
        },
    },
});

export const { setGenaiActiveStatus } = genaiActiveStatusSlice.actions;
export default genaiActiveStatusSlice.reducer;
