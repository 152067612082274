import React, { useState, useEffect } from 'react'
import { Box, Grid, Chip, Button, Avatar, IconButton, Divider, Popover, Typography, TextField, MenuList, Menu, MenuItem, ListItemIcon } from '@mui/material'
import { Link } from 'react-router-dom';
import Card from '@mui/material/Card'
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
// import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import SaveIcon from '@mui/icons-material/Save';
import LinkIcon from '@mui/icons-material/Link';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
// import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import Like from '../../../src/assets/images/like.svg'
import Mood from '../../../src/assets/images/mood.svg'
import Clapping from '../../../src/assets/images/clapping.svg'
import Favourite from '../../../src/assets/images/favourite.svg'
import PhotoCollage from '../PhotoCollage';
import DOMPurify from 'dompurify';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SinglePost from './SinglePost';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import ReportIcon from '@mui/icons-material/Report';
import HideSourceIcon from '@mui/icons-material/HideSource';
import { setFormData } from '../../features/commonDataSlice/postSlice';
import { useSelector, useDispatch } from 'react-redux';
import { openDialog, setData } from "../../features/dialogBox/dialogBoxSlice";
import ConfirmDialogBox from '../../features/confirmDialogBox/confirmDialogBox';
import { closeConfirmDialog, openConfirmDialog } from '../../features/confirmDialogBox/confirmDialogBoxSlice';
import ApiService from '../../utils/ApiService';
// import { openSnackbar, closeSnackbar } from '../../features/';
// import DynamicSnackbar from '../../features/snackbar/Snackbar';
import { openSnackbar, closeSnackbar } from '../../features/snackbar/snackbarSlice';
import Skeleton from '@mui/material/Skeleton';
import encriptionHelper from '../../utils/encriptionHelper';
import ReportTextField from '../../component/forms/TextField';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#2563EB',
        color: 'white',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}));

const Post = ({ post, isHeader, isAssociateSpotlight, isMore, isEclipse, isAwardHeader, isCelebration, isAwardTo, isBirthday, isJoiner, isPromotions, isAccolades, isWorkAniversary, isWeddings, isbirthAnnouncement, isStories, user, isEvent, isLeaderSpeak, updateReaction, updateHidePost, indivisualAPI, reactPost, formTransform, reportPost, isOrganisationalFeed, isHiddenPost, isCompany, isFavoriteFeeds, updateUnsavedPost }) => {

    // console.log({isEvent, postType: post.type, post})
    const dispatch = useDispatch();
    // console.log('aaa', post.id);
    const [anchorE4, setAnchorE4] = useState(null);
    const [openPost, setOpenPost] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const feedPostName = useSelector(state => state.routing.currentRoute);
    const currentPost = useSelector((state) => state?.dialog?.data);
    const userData = useSelector((state) => state?.user?.userListName)
    const postData = useSelector((state) => state.confirmDialog.id);
    const [snackBar, setSnackBar] = useState({ dataLoad: false, message: '' });
    const [posts, setPostsData] = React.useState([]);
    const [reaction, setReaction] = React.useState([]);
    const [reportTextComment, setReportTextComment] = useState('');
    const isXsScreen = window.innerWidth < 370;

    const getUserReaction = () => {
        let reactions = post?.post_reactions?.reduce((prev, curr) => {
            if (prev == -1 && (curr.userId == userData.userId || curr.pivot?.user_id == userData.userId)) {
                prev = [curr];
            }
            return prev;
        }, -1)
        if (reactions && reactions != -1) {
            reactions = JSON.parse(JSON.stringify(reactions)).map(v => { v.reactionId = v.reactionId ?? parseInt(v.pivot?.reaction_id); return v; })[0]
        }
        // console.log({reactions})
        return reactions ?? -1;
    }

    const setReactionBTN = () => {
        // console.log({post})
        let findUserReaction = getUserReaction();
        // console.log("lkdsfj",{findUserReaction})
        if (findUserReaction != -1) {
            setSelectedOption(findUserReaction.reactionId ?? parseInt(findUserReaction.pivot?.reaction_id))
        } else {
            setSelectedOption(null)
        }
    }
console.debug("feedPostName",isOrganisationalFeed)
    const encodeUrl = () => {
        if (isHiddenPost) {
            return '#';
        }
        const queryStringObject = {
            indivisualAPI: isOrganisationalFeed ? post['indivisualAPI'] : isFavoriteFeeds ? 'FeedList' : indivisualAPI,
            reactPost: isOrganisationalFeed ? post['reactPost'] : reactPost,
            feedPostName: isOrganisationalFeed ? post['feedPostName'] : isFavoriteFeeds ? 'Home' : feedPostName,
        };
        const encodedQueryString = encriptionHelper().encodeAndEncryptURLParams(queryStringObject);
        // return `/feed/${post.id}?query=${encodedQueryString}`;
        const childPath =feedPostName=='/'?"/feed" : feedPostName;

        // return `/${childPath}/${post.id}`;
        return `${childPath}/${post.id}`;
    }

    useEffect(() => {
        setReactionBTN();
    }, [post])

    const handlePostClick = (event) => {
        if (isHiddenPost) {
            return;
        }
        setAnchorE4(event.currentTarget);
        setOpenPost(true);
    };

    const handlePostClose = () => {
        setAnchorE4(null);
        setOpenPost(false);
    };

    const [throttleOptionClick, setThrottleOptionClick] = useState(false);

    const handleOptionClick = (option) => {

        if (throttleOptionClick)
            return;
        setThrottleOptionClick(true);

        let reactionId = 4;

        if (option === 'Like') {
            reactionId = 1;
        } else if (option === 'Love') {
            reactionId = 2;
        } else if (option === 'Applaud') {
            reactionId = 3;
        }

        let caseHere = 'PUT';
        let findUserReaction = getUserReaction();
        if (findUserReaction == -1) {
            caseHere = "POST";
        } else if (findUserReaction.reactionId == reactionId) {
            caseHere = "DELETE";
        }
        let payload = { userId: userData?.userId, reactionId: reactionId, postId: (isHiddenPost) ? post.postId : post.id }
        if (reactPost != 'reactPost') {
            payload = { userId: userData?.userId, reactionId: reactionId, reactionType: post.type }
            // leaderId: post.id,
            payload[post.type + "Id"] = post.id;
            if (post.type == 'reward')
                payload['awardUserId'] = post.id;
            if (post.type == 'userWedding')
                payload['weddingId'] = post.id;
            if (post.type == 'userJoining')
                payload['joiningId'] = post.id;
        }
        switch (caseHere) {
            case "PUT": //Same Code required for PUT and POST
            case "POST": ApiService.post(reactPost, payload)
                .then((response) => {
                    let searchParam = null;
                    if (!isFavoriteFeeds) {
                        searchParam = post.id;
                    }
                    if (isOrganisationalFeed) {
                        searchParam = userData?.userId;
                    }
                    ApiService.get(indivisualAPI, { userId: userData?.userId }, searchParam).then((res) => {
                        if (isOrganisationalFeed) {
                            updateReaction(res.data)
                        }
                        const data = [res.data.data].map(v => formTransform(v))[0];
                        if (data) {
                            if (isFavoriteFeeds) {
                                updateReaction(res.data.data)
                            } else {
                                updateReaction(data);
                            }
                            setThrottleOptionClick(false);
                        }

                    })
                        .catch((error) => {
                            // console.error('Error:', error);
                            setSnackBar({ dataLoad: true, message: error.message, severity: 'error' });
                            setThrottleOptionClick(false);
                        });
                })
                .catch((error) => {
                    // console.error('Error:', error);
                    setSnackBar({ dataLoad: true, message: error.message, severity: 'error' });
                    setThrottleOptionClick(false);
                });
                break;
            case "DELETE": ApiService.delete(reactPost, { reactionId: reactionId }, reactPost == 'reactPost' ? findUserReaction.id : findUserReaction.pivot?.id)
                .then((response) => {
                    let searchParam = null;
                    if (!isFavoriteFeeds) {
                        searchParam = post.id;
                    }
                    if (isOrganisationalFeed) {
                        searchParam = userData?.userId;
                    }
                    ApiService.get(indivisualAPI, { userId: userData?.userId }, searchParam).then((res) => {
                        if (isOrganisationalFeed) {
                            updateReaction(res.data)
                        }
                        const data = [res.data.data].map(v => formTransform(v))[0];
                        if (data) {
                            if (isFavoriteFeeds) {
                                updateReaction(res.data.data)
                            } else {
                                updateReaction(data);
                            }
                            setThrottleOptionClick(false);
                        }

                    })
                        .catch((error) => {
                            //   console.error('Error:', error);
                            setSnackBar({ dataLoad: true, message: error.message, severity: 'error' });
                            setThrottleOptionClick(false);
                        });

                })
                .catch((error) => {
                    // console.error('Error:', error);
                    setSnackBar({ dataLoad: true, message: error.message, severity: 'error' });
                    setThrottleOptionClick(false);
                }
                );
                break;
            // case "PUT": ApiService.put(reactPost, { reactionId: reactionId }, reactPost == 'reactPost' ? findUserReaction.id : findUserReaction.pivot?.id)
            //     .then((response) => {
            //         ApiService.get(indivisualAPI, {}, post.id).then((res) => {
            //             const data = [res.data.data].map(v => formTransform(v))[0];
            //             //   console.log(res.data.data)
            //             if (data) {
            //                 updateReaction(data);
            //                 // setPostsData(post);
            //                 //   console.log('abc',post);
            //                 setThrottleOptionClick(false);
            //             }

            //         })
            //             .catch((error) => {
            //                 //   console.error('Error:', error);
            //                 setSnackBar({ dataLoad: true, message: error.message, severity: 'error' });
            //                 setThrottleOptionClick(false);
            //             });

            //     })
            //     .catch((error) => {
            //         // console.error('Error:', error);
            //         setSnackBar({ dataLoad: true, message: error.message, severity: 'error' });
            //         setThrottleOptionClick(false);
            //     });
            //     break;
            default: setThrottleOptionClick(false);
        }

    };


    //Date Format
    const formatDate = (dateOfBirth) => {
        const options = { day: 'numeric', month: 'long' };
        const date = new Date(dateOfBirth);
        return date.toLocaleDateString(undefined, options);
    };
    ////////

    // const openPost = Boolean(anchorE4);
    const id = openPost ? 'simple-popover' : undefined;

    const [showBox, setShowBox] = useState(false);

    const commentBox = () => {
        setShowBox(!showBox);
    };

    const createdAt = post.created_at;

    const formattedDate = new Date(createdAt).toLocaleDateString(undefined, {

        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
    });

    // console.log("Jaroorat", { formattedDate, createdAt })

    //////// show more comment////////
    const [showAllComments, setShowAllComments] = useState(false);
    const comments = post.comments;
    // console.log('aaa', post.comments)
    const commentsToDisplay = showAllComments ? comments : comments?.slice(0, 2);
    const handleLoadMore = () => {
        setShowAllComments(true);
    };
    ////////end//////////////////////////
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [menus, setMenus] = useState([]);

    const open = Boolean(anchorEl);
    const handleClick = (event, menus) => {
        // console.log("sfkdj", { currentTarget: event.currentTarget })
        setMenus(menus);
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const [form, setForm] = useState([]);

    const [showFullContent, setShowFullContent] = useState(false);

    const toggleShowFullContent = () => {
        setShowFullContent(!showFullContent);
    };

    const sanitizedPostBody = DOMPurify.sanitize(post?.postBody);
    const words = sanitizedPostBody.split(' ');

    const maxWords = 20;
    const truncatedContent = words.slice(0, maxWords).join(' ');
    const showReadMore = words.length > maxWords;
    const [showFullDescription, setShowFullDescription] = useState(false);

    const toggleDescription = () => {
        setShowFullDescription(!showFullDescription);
    };


    const stripHtmlTags = (htmlString) => {
        const doc = new DOMParser().parseFromString(htmlString, 'text/html');
        return doc.body.textContent || "";
    };

    const renderDescription = () => {
        console.log({ post })
        if (post?.postBody) {
            const words = stripHtmlTags(post.postBody).split(' ');

            if (words.length <= 100 || showFullDescription) {
                return (
                    <Typography
                        variant='body2'
                        color="secondary.Heading2"
                        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(post.postBody) }}
                    />
                );
            } else {
                const truncatedText = words.slice(0, 50).join(' ');
                return (
                    <Box>
                        <Typography variant='body2' color="secondary.Heading2">{truncatedText}</Typography>
                        <Button
                            text="text"
                            component={Link}
                            to={encodeUrl()}
                            state={{ feedPostData: post, feedPostName: feedPostName, indivisualAPI: indivisualAPI, reactPost: reactPost }}
                            sx={{ textTransform: 'unset !important' }}
                        >
                            {showFullContent ? 'Read less' : 'Read more'}
                        </Button>
                    </Box>
                );
            }
        }

        return null;
    };

    const handleSnakbarClick = () => {
        dispatch(openSnackbar({ dataLoad: true, message: 'This is a success message!', severity: "info" }));
    };


    const handleEditDialog = (postData) => {
        console.log({ postData })
        dispatch(setData(postData))
        dispatch(openDialog('editDialog'));
        setAnchorEl(null);
    }



    const handleReactionDialog = (post) => {
        dispatch(setData(post))
        dispatch(openDialog('reactionDialog'));
        setAnchorEl(null);
    }

    const handleConfirmDialog = (id, postData) => {
        dispatch(setData(postData));
        dispatch(openConfirmDialog('ConfirmDialog'));
        setAnchorEl(null);
    }

    const closeDialogPublish = (id) => {
        dispatch(closeConfirmDialog(id));
        setAnchorEl(null);
    }
    //////////////////////Report Post////////////////////////////////////////////

    const handleReportDialog = (id, postData) => {
        reportPost(postData, post)
        setAnchorEl(null);
        console.log('m1', post.id)
    };

    ///////////////////////////End Report Post///////////////////////////////////////////////////



    const handleHidePost = (currentPost) => {
        console.log('b1', currentPost)
        const api = 'HiddenPost';
        let payload = new FormData();
        payload.append('userId', userData.userId);
        payload.append('postId', currentPost.id);
        ApiService.postAdopter(api, payload).then((res) => {
            const data = [res.data.data].map(v => formTransform(v))[0];
            if (data) {
                updateHidePost(currentPost);
                dispatch(openSnackbar({ dataLoad: true, message: 'Your post has been hide successfully.!', severity: 'info' }));
            }
        })
            .catch((error) => {
                setSnackBar({ dataLoad: true, message: error.message, severity: 'error' });
            });
        setAnchorEl(null);
    };

    const handleUnhidePost = (currentPost) => {
        // console.log('b1', currentPost)
        const api = 'HiddenPost';
        let payload = new FormData();
        payload.append('userId', userData.userId);
        // payload.append('postId', currentPost.id);
        ApiService.delete(api, payload, currentPost.id).then((res) => {
            // const data = [res.data.data].map(v => formTransform(v))[0];
            // if (data) {
            updateHidePost(currentPost);
            dispatch(openSnackbar({ dataLoad: true, message: 'Your post has been unhide successfully.!', severity: 'info' }));
            // }
        })
            .catch((error) => {
                setSnackBar({ dataLoad: true, message: error.message, severity: 'error' });
            });
        setAnchorEl(null);
    };



    const handleCopyLink = (url) => {
        navigator.clipboard.writeText(url)
            .then(() => {
                dispatch(openSnackbar({ dataLoad: true, message: 'Your link has been copied successfully..', severity: "info" }));
            })
            .catch((error) => {
            });
    };

    const handleSavePost = (currerntPost) => {
        let api = reactPost == 'reactPost' ? 'savePostUser' : 'saveFavoriteFeeds';
        let payload = { userId: userData.userId, postId: currerntPost.id };
        const formData = new FormData();
        if (reactPost != 'reactPost') {
            // payload = {userId: userData.userId, type: post.type};
            if (post.type == 'reward')
                formData.append('awardUserId', post.id);
            else
                formData.append(post.type + "Id", post.id);
            formData.append('userId', userData.userId);
            formData.append('type', post.type);
        } else {
            formData.append('userId', userData.userId);
            formData.append('postId', currerntPost.id);
        }
        ApiService.postAdopter(api, formData)
            .then((response) => {
                let data = response.data.data;
                if (data) {
                    dispatch(openSnackbar({ dataLoad: true, message: 'Your post has been saved successfully!', severity: "info" }));
                    let searchParam = null;
                    if (!isFavoriteFeeds) {
                        searchParam = post.id;
                    }
                    if (isOrganisationalFeed) {
                        searchParam = userData?.userId;
                    }
                    ApiService.get(indivisualAPI, { userId: userData.userId }, searchParam).then((res) => {
                        if (isOrganisationalFeed) {
                            updateReaction(res.data)
                        }
                        const data = [res.data.data].map(v => formTransform(v))[0];
                        if (data) {
                            if (isFavoriteFeeds) {
                                updateReaction(res.data.data)
                            } else {
                                updateReaction(data);
                            }
                        }
                    })
                        .catch((error) => {
                        })
                    // .finally(() => {
                    //     setRenderMoreIconsOpen(false);
                    // });
                }
            })
            .catch((error) => {
                // Handle error
                setSnackBar({ dataLoad: true, message: error.message, severity: 'error' });
            });
        setAnchorEl(null);
    }

    const handleUnsavePost = (currerntPost) => {
        let api = reactPost == 'reactPost' ? 'unsavePostUser' : 'unsaveFavoriteFeeds';
        let payload = { userId: userData.userId, postId: currerntPost.id };
        if (reactPost != 'reactPost') {
            const formData = new FormData();
            formData.append('userId', userData.userId);
            formData.append('type', post.type);
            formData.append('postId', currerntPost.id);
            ApiService.postAdopter(api, formData)
                .then((response) => {
                    dispatch(openSnackbar({ dataLoad: true, message: 'Your post has been unsaved successfully!', severity: "info" }));
                    let searchParam = null;
                    if (!isFavoriteFeeds) {
                        searchParam = currerntPost.id;
                    }
                    if (isOrganisationalFeed) {
                        searchParam = userData?.userId;
                    }
                    ApiService.get(isFavoriteFeeds ? 'FeedList' : indivisualAPI, { userId: userData.userId }, searchParam).then((res) => {
                        if (isOrganisationalFeed) {
                            updateReaction(res.data)
                        }
                        const data = [res.data.data].map(v => formTransform(v))[0];
                        if (data) {
                            if (isFavoriteFeeds) {
                                updateReaction(res.data.data)
                            } else {
                                updateReaction(data);
                            }
                            updateUnsavedPost(currerntPost.id)
                        }
                    })
                        .catch((error) => {
                            // console.error('Error:', error);
                        });
                })
                .catch((error) => {
                    // console.error('Error:', error);
                    setSnackBar({ dataLoad: true, message: error.message, severity: 'error' });
                });
        } else {
            ApiService.get(api, payload)
                .then((response) => {
                    dispatch(openSnackbar({ dataLoad: true, message: 'Your post has been unsaved successfully!', severity: "info" }));
                    let searchParam = null;
                    if (!isFavoriteFeeds) {
                        searchParam = post.id;
                    }
                    if (isOrganisationalFeed) {
                        searchParam = userData?.userId;
                    }
                    ApiService.get(indivisualAPI, { userId: userData.userId }, searchParam).then((res) => {
                        if (isOrganisationalFeed) {
                            updateReaction(res.data)
                        }
                        const data = [res.data.data].map(v => formTransform(v))[0];
                        if (data) {
                            if (isFavoriteFeeds) {
                                updateReaction(res.data.data)
                            } else {
                                updateReaction(data);
                            }
                            updateUnsavedPost(currerntPost.id)
                        }
                    })
                        .catch((error) => {
                            // console.error('Error:', error);
                        });
                })
                .catch((error) => {
                    // console.error('Error:', error);
                    setSnackBar({ dataLoad: true, message: error.message, severity: 'error' });
                });
        }
        setAnchorEl(null);
    }

    const getCurrentMenu = (currPost) => {
        if (isHiddenPost) {
            return [{ type: 'Unhide', onClick: () => { handleUnhidePost(currPost) } }]
        }
        if (currPost.userId == post.userId) {
            let currentUserMenu = indivisualAPI == 'FeedList' && post.userId == userData.userId;
            // console.log("postSaved",{postSaved: post.postSaved})
            let saveOption = post.postSaved ? [{ type: 'Unsave', onClick: () => { handleUnsavePost(post) } }] : [{ type: 'Save', onClick: () => { handleSavePost(currPost) } }];
            let currentMenus = [...saveOption, { type: 'Copy', onClick: handleSnakbarClick }];

            if (currentUserMenu) {
                currentMenus = [{ type: 'Edit', onClick: () => handleEditDialog(post) }, { type: 'Delete', onClick: () => { handleConfirmDialog('ConfirmDialog', post) } }, ...currentMenus];
            } else {
                if (indivisualAPI == 'FeedList') {
                    currentMenus = [...currentMenus, { type: 'Report', onClick: () => { handleReportDialog('ReportDialog', currPost) } }, { type: 'Hide', onClick: () => { handleHidePost(currPost) } }]
                }
            }
            return currentMenus;
        }
    }

    const getNormalMenu = () => {
        if (post.postSaved)
            return [
                { type: 'Save', onClick: () => { handleSavePost(post) } },
                { type: 'Copy', onClick: handleSnakbarClick },
                { type: 'Hide', onClick: handleSnakbarClick },
                { type: 'Report', onClick: handleSnakbarClick },
            ]
        else
            return [
                { type: 'Unsave', onClick: () => { handleUnsavePost(post) } },
                { type: 'Copy', onClick: handleSnakbarClick }
            ]
    }

    //////////
    const getMenuItem = (menu) => {
        switch (menu.type) {
            case 'Save': return (
                <MenuItem key="Save" onClick={(e) => { console.log("clicked", { postId: post.id }); menu.onClick(); }}>
                    <ListItemIcon>
                        <BookmarkBorderIcon fontSize="medium" />
                    </ListItemIcon>
                    <Typography variant="body1">Save</Typography>
                </MenuItem>)
            case 'Unsave': return (<MenuItem key="Unsave" onClick={(e) => { console.log("clicked", { postId: post.id }); menu.onClick(); }}>
                <ListItemIcon>
                    <BookmarkIcon fontSize="medium" />
                </ListItemIcon>
                <Typography variant="body1">Unsave</Typography>
            </MenuItem>)
            case 'Copy': return (<MenuItem key="Copy" onClick={() => handleCopyLink(window.location.origin + encodeUrl())}>
                <ListItemIcon>
                    <LinkIcon fontSize="medium" />
                </ListItemIcon>
                <Typography variant="body1">Copy Link</Typography>
            </MenuItem>)
            case 'Edit': return (<MenuItem key="Edit" onClick={(e) => { console.log("clicked", { postId: post.id }); menu.onClick(); }}>
                <ListItemIcon>
                    <EditIcon fontSize="medium" />
                </ListItemIcon>
                <Typography variant="body1">Edit</Typography>
            </MenuItem>)
            case 'Delete': return (<MenuItem key="Delete" onClick={(e) => { console.log("clicked", { postId: post.id }); menu.onClick(); }}>
                <ListItemIcon>
                    <DeleteIcon fontSize="medium" />
                </ListItemIcon>
                <Typography variant="body1">Delete</Typography>
            </MenuItem>)
            case 'Report': return (<MenuItem key="Report" onClick={(e) => { console.log("clicked", { postId: post.id }); menu.onClick(); }}>
                <ListItemIcon >
                    <ReportIcon fontSize="medium" />
                </ListItemIcon>
                <Typography variant="body1">Report</Typography>
            </MenuItem>)
            case 'Hide': return (<MenuItem key="Hide" onClick={(e) => { console.log("clicked", { postId: post.id }); menu.onClick(); }}>
                <ListItemIcon>
                    <VisibilityOffIcon fontSize="medium" />
                </ListItemIcon>
                <Typography variant="body1">Hide</Typography>
            </MenuItem>)
            case 'Unhide': return (<MenuItem key="Unhide" onClick={(e) => { console.log("clicked", { postId: post.id }); menu.onClick(); }}>
                <ListItemIcon>
                    <VisibilityIcon fontSize="medium" />
                </ListItemIcon>
                <Typography variant="body1">Unhide</Typography>
            </MenuItem>)
        }
    }

    const getMenus = (menus) => {
        return menus.map(menu => {
            return getMenuItem(menu)
        });
    }

    const renderMoreIcons = (menus) => {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <IconButton
                    aria-label="more"
                    id="long-button"
                    onClick={(e) => handleClick(e, menus)}
                >
                    <MoreVertIcon />
                </IconButton>
            </Box>
        )
    }

    const showReaction = (reactions) => {
        return (<>
            {reactions.map(v => {
                switch (v) {
                    case 1: return (<img src={Like} alt="Like" title="Like" key="Like" className='icon-size-1' />)
                    case 2: return (<img src={Favourite} alt="Love" title="Love" key="Love" className='icon-size-1' />)
                    case 3: return (<img src={Clapping} alt="Applaud" title="Applaud" key="Applaud" className='icon-size-1' />)
                    case 4: return (<img src={Mood} alt="Curious" title="Curious" key="Curious" className='icon-size-1' />)
                }
            })}
        </>)
    }

    const showLikes = (post) => {
        if (!post?.reactionCount) {
            console.log('a1', post?.reactionCount);
            return (<></>)
        }

        let userName = post?.post_reactions?.filter(p => p.userId == user.userId || p.pivot?.user_id == user.userId).length ? 'You' : '';

        let reactions = post?.post_reactions?.reduce((prev, curr) => {
            let reactionId = curr.reactionId ?? curr.pivot?.reaction_id;
            if (prev.indexOf(parseInt(reactionId)) == -1) {
                prev.push(parseInt(reactionId))
            }
            return prev;
        }, []).sort();


        if (post.reactionCount == 1) {
            console.log('a1', post?.reactionCount);
            return (
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Box onClick={() => handleReactionDialog(post)} sx={{ mr: 0.5, cursor: 'pointer', marginTop: '8px' }}>{showReaction(reactions)}</Box>
                    <Box onClick={() => handleReactionDialog(post)} sx={{ cursor: 'pointer', '&:hover span': { color: 'black' } }}>
                        <Typography variant="defineCaption" color="secondary.semiHeading2">
                            {userName}
                        </Typography>
                    </Box>
                </Box>
            )
        }

        return (
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                fontSize: 'medium',
                wordBreak: 'break-word',
                '@media (min-width: 600px)': {
                    flexDirection: 'row',
                },
            }}>
                <Box sx={{ mr: 0.5, marginTop: '3px' }}>{showReaction(reactions)}</Box>
                <Box sx={{
                    display: 'flex',
                    cursor: 'pointer',// Center content horizontally
                    mb: 1,
                    '&:hover span': { color: 'black' },
                }} onClick={() => handleReactionDialog(post)}>
                    <Box>
                        <Typography variant="defineCaption" color="secondary.semiHeading2">
                            {userName.length ? userName + ' ' + 'and' + ' ' : ' '}
                        </Typography>
                    </Box>
                    <Box sx={{ ml: 0.5 }}>
                        <Typography variant="defineCaption" color="secondary.semiHeading2">
                            {post.reactionCount - (userName.length ? 1 : 0)} Others
                        </Typography>
                    </Box>
                </Box>
            </Box>)

    }

    const carouselAdopter = (album) => {
        album.images = album.carouselItems;
        return album;
        //  album.images = album.carouselItems.map(item => {item.images = item.carouselItems.map(v => {v.images = v.carouselItem; return v;}); return item;})
    }

    const getQuarterYear = (date) => {
        // Get the month of the date
        const month = date.getMonth();

        // Calculate the quarter
        let quarter;
        if (month < 3) {
            quarter = 4;
        } else if (month < 6) {
            quarter = 1;
        } else if (month < 9) {
            quarter = 2;
        } else {
            quarter = 3;
        }

        // Get the full year
        let year = date.getFullYear();

        // Adjust year for quarter 4 of previous year
        if (quarter === 4) {
            year -= 1;
        }

        // Format the output string
        return `Q${quarter} Updates ${year}`;
    }

    return (
        <Box>
            <Box sx={{ '.MuiPaper-root .MuiCard-root': { mt: 0 } }}>
                {isHeader &&
                    <Box sx={{}}>
                        <Grid container spacing={2}>
                            {/* User Avatar */}
                            {/* <Grid item xs={2.5} sm={2.5} md={1.5} lg={1.3}>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Avatar src={post.userAvatar}
                                        sx={{
                                            backgroundColor: 'transparent !Important',
                                            width: {xs:'45px',sm:'62px'},
                                            height: {xs:'45px',sm:'63px'},
                                            boxShadow: 'none',
                                            '& .MuiAvatar-img': {
                                                height: {xs:'45px',sm:'63px'},
                                                width: {xs:'45px',sm:'62px'},
                                                textAlign: 'center',
                                                objectFit: 'cover',
                                                color: 'transparent',
                                                textIndent: '10000px'
                                            }
                                        }}
                                    />
                                </Box>
                            </Grid> */}
                            <Grid item xs={10} sm={10} md={10} lg={10} display='flex'>
                                <Box sx={{ display: 'flex', alignItems: 'center', mr: 1 }}>
                                    <Avatar src={post.userAvatar}
                                        sx={{
                                            backgroundColor: 'transparent !Important',
                                            width: { xs: '45px', sm: '62px' },
                                            height: { xs: '45px', sm: '63px' },
                                            boxShadow: 'none',
                                            '& .MuiAvatar-img': {
                                                height: { xs: '45px', sm: '63px' },
                                                width: { xs: '45px', sm: '62px' },
                                                textAlign: 'center',
                                                objectFit: 'cover',
                                                color: 'transparent',
                                                textIndent: '10000px'
                                            }
                                        }}
                                    />
                                </Box>
                                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', marginLeft: isHiddenPost ? '1rem' : null }}>
                                    <Typography variant="defineSubtitle2" color="primary.heading">
                                        {post['userName']
                                            ? post['userName'] + (post['middleName'] && post['middleName'] !== '--' ? post['middleName'] : ' ') + (post['lastName'] ? post['lastName'] : ' ')
                                            : post['name']}
                                    </Typography>
                                    <Typography variant="defineCaption" color="secondary.Heading2">
                                        {post?.user?.designation?.designation || post?.designation}
                                    </Typography>
                                    {formattedDate !== 'Invalid Date' && (
                                        <Typography variant="Caption" color="secondary.semiHeading2">
                                            {formattedDate}
                                        </Typography>
                                    )}
                                </Box>

                            </Grid>
                            {/* {isPromotions &&( */}
                            <Grid item xs={2} sm={2} md={2} lg={2} key={"menu" + post.id}>
                                {renderMoreIcons(getCurrentMenu(post))}
                            </Grid>
                            {/* )} */}
                        </Grid>
                    </Box>
                }
                {isAwardHeader &&
                    <Box>
                        <Grid container spacing={2}>
                            <Grid item xs={10}>
                                <Box>
                                    <Chip label={getQuarterYear(new Date(post.created_at))} sx={{ backgroundColor: '#ED6C02', color: '#fff' }} />
                                </Box>
                            </Grid>
                            <Grid item xs={2} key={"menu" + post.id}>
                                {renderMoreIcons(getCurrentMenu(post))}
                            </Grid>
                        </Grid>
                    </Box>
                }
                {isCelebration &&
                    <Grid container spacing={2}>
                        <Grid item xs={10}>
                            {isJoiner &&
                                <Box>
                                    <Box>
                                        <Typography variant='defineSubtitle2' color="primary.heading">Welcome {post?.userName}</Typography>
                                    </Box>
                                    <Box>
                                        <Typography variant="defineCaption" color="secondary.Heading2">
                                            Designation-{post?.degination}
                                        </Typography>
                                    </Box>
                                </Box>
                            }
                            {isBirthday &&
                                <Box>
                                    <Box>
                                        <Typography variant='defineSubtitle2' color="primary.heading">Happy Birthday {post?.userName}</Typography>
                                    </Box>
                                    <Box>
                                        <Typography variant='defineCaption' color="secondary.Heading2">
                                            Born On {post?.dateOfBirth ? formatDate(post.dateOfBirth) : ''}
                                        </Typography>
                                    </Box>
                                </Box>
                            }
                            {isPromotions &&
                                <Box>
                                    <Box>
                                        <Typography variant='defineSubtitle2' color="primary.heading">Congratulation {post?.userName} {post?.middleName} {post?.lastName}</Typography>
                                    </Box>
                                    <Box>
                                        {console.log({ post })}
                                        <Typography variant='defineCaption' color="secondary.Heading2">Promoted as {post.designation} on {post?.promotedDate}</Typography>
                                    </Box>
                                    {/* <Box>
                                        <Typography variant='defineCaption' color="primary.semiHeading2">promoted on {post?.promotedDate}</Typography>
                                    </Box> */}
                                </Box>
                            }
                            {isWorkAniversary &&
                                <Box>
                                    <Box>
                                        <Typography variant='defineSubtitle2' color="primary.heading">Happy Work Anniversary {post?.userName} {post?.middleName} {post?.lastName}</Typography>
                                    </Box>
                                    <Box>
                                        <Typography variant='defineCaption' color="secondary.Heading2">Completed-{post.totalWorkExperience} Years</Typography>
                                    </Box>
                                </Box>
                            }
                            {isWeddings &&
                                <Box>
                                    <Box>
                                        <Typography variant='defineSubtitle2' color="primary.heading">{post?.userName} With {post?.lastName}</Typography>
                                    </Box>
                                    <Box>
                                        <Typography variant='defineCaption' color="secondary.Heading2">Married On-{post.weddingDate}</Typography>
                                    </Box>
                                </Box>
                            }
                            {isbirthAnnouncement &&
                                <Box>
                                    <Box>
                                        <Typography variant='defineSubtitle2' color="primary.heading">Child of {post?.userName} {post?.middleName} {post?.lastName}</Typography>
                                    </Box>

                                    <Box>
                                        <Typography variant='defineCaption' color="secondary.Heading2">Born on {post.dateOfBirth}</Typography>
                                    </Box>
                                </Box>
                            }

                        </Grid>
                        {((!isPromotions) && (!isbirthAnnouncement) && (!isBirthday) && (!isWorkAniversary) && (!isWeddings) && (isJoiner)) &&
                            <Grid item xs={2} key={"menu" + post.id}>
                                {renderMoreIcons(getCurrentMenu(post))}
                            </Grid>
                        }
                    </Grid>
                }
                {isStories &&
                    <Box>
                        {post?.userName ? (
                            <Box>
                                <Grid container spacing={2}>
                                    <Grid item xs={2} sm={1.5} md={1}>
                                        <Box sx={{ mt: 1, mr: 1 }}>
                                            <Avatar
                                                src={post.userAvatar}
                                                alt={(post?.userName)}
                                                sx={{
                                                    backgroundColor: 'transparent !Important',
                                                    height: '45px',
                                                    boxShadow: 'none',
                                                    width: '45px',
                                                    '& .MuiAvatar-img': {
                                                        height: '45px',
                                                        width: '45px',
                                                        textAlign: 'center',
                                                        objectFit: 'cover',
                                                        color: 'transparent',
                                                        textIndent: '10000px'
                                                    }
                                                }}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={8} sm={8.5} md={8}>
                                        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                            <Typography variant="defineSubtitle2">{post?.userName}</Typography>
                                            {console.log({ post })}
                                            <Typography variant="defineCaption" color="secondary.Heading2">
                                                {post?.user?.designation?.designation || post?.designation}
                                            </Typography>
                                            {/* <Typography variant="defineCaption">{post?.user?.designation?.designation || post?.designation}</Typography> */}
                                            {formattedDate != 'Invalid Date' && (

                                                <Typography variant="Caption" color="secondary.semiHeading2">
                                                    {formattedDate}
                                                </Typography>
                                            )}
                                        </Box>
                                    </Grid>

                                    <Grid item xs={2} sm={2} md={3}>
                                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }} key={"menu" + post.id}>
                                            {renderMoreIcons(getCurrentMenu(post))}
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        ) : null}
                    </Box>
                }
                <Box>
                    <Box sx={{ my: 1 }}>
                        <Box>
                            <Grid container spacing={2}>
                                <Grid item xs={10}>
                                    <Box>
                                        <Typography sx={{ mt: 1 }} color="primary.heading" variant='defineSubtitle2' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(post?.title) }} />
                                    </Box>

                                    {(isEvent || isCompany) &&
                                        (<Box>
                                            {formattedDate != 'Invalid Date' && (
                                                <Typography variant="Caption" color="secondary.semiHeading2">
                                                    {formattedDate}
                                                </Typography>
                                            )}
                                        </Box>)
                                    }

                                    {isAwardTo &&
                                        <Box>
                                            <Typography fontWeight='var(--font-weight-4)' color="secondary.Heading2" variant='body2'>
                                                Awarded to <Typography component="span" sx={{ color: 'blue' }}>{post?.awardName}</Typography> on {post?.receivedDate}
                                            </Typography>
                                        </Box>
                                    }
                                </Grid>
                                <Grid item xs={2}>
                                    {isMore &&
                                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }} key={"menu" + post.id}>
                                            {renderMoreIcons(getCurrentMenu(post))}
                                        </Box>
                                    }
                                    {isEvent && <Box sx={{ display: 'flex', justifyContent: 'flex-end' }} key={"menu" + post.id}>
                                        {renderMoreIcons(getCurrentMenu(post))}
                                    </Box>}
                                </Grid>
                            </Grid>
                        </Box>
                        <Box sx={{}}>
                            <Grid container>
                                <Grid id='topPad5' item xs={post.type == "accolade" ? 10 : 12}>
                                    <Box sx={{ wordWrap: 'break-word' }}>
                                        <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                                            <Typography
                                                sx={{ mt: 1, wordBreak: 'break-word' }}
                                                color="secondary.Heading2"
                                                variant="body2"
                                                dangerouslySetInnerHTML={{
                                                    __html: showFullContent ? sanitizedPostBody : truncatedContent,
                                                }}
                                            />
                                            <Box sx={{ marginTop: "1.2rem" }}>
                                                {/* {renderMoreIcons(getCurrentMenu(post))}7 */}
                                            </Box>
                                        </Box>
                                        {isHiddenPost &&

                                            <Box sx={{ pointerEvents: 'none', cursor: 'none' }}>
                                                {showReadMore && (
                                                    <Button text="text" state={{ feedPostData: post, feedPostName: feedPostName, indivisualAPI: indivisualAPI, reactPost: reactPost }} sx={{ textTransform: 'unset !important' }} >
                                                        {showFullContent ? 'Read less' : 'Read more'}
                                                    </Button>
                                                )}
                                            </Box>
                                        }
                                        {!isHiddenPost &&

                                            <Box>
                                                {showReadMore && (
                                                    <Button text="text" component={Link} to={encodeUrl()} state={{ feedPostData: post, feedPostName: feedPostName, indivisualAPI: indivisualAPI, reactPost: reactPost }} sx={{ textTransform: 'unset !important' }} >
                                                        {showFullContent ? 'Read less' : 'Read more'}
                                                    </Button>
                                                )}
                                            </Box>
                                        }
                                    </Box>
                                </Grid>
                                {(!post || isAccolades) &&
                                    <Grid item xs={2}>
                                        <Box sx={{ justifyContent: "flex-end", display: 'flex', marginTop: '1rem' }}>
                                            {(post.type == "accolade") && (
                                                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }} key={"menu" + post.id}>
                                                    {renderMoreIcons(getCurrentMenu(post))}
                                                </Box>
                                            )}
                                        </Box>
                                    </Grid>
                                }
                            </Grid>

                        </Box>
                        {/* {console.log({isLeaderSpeak})} */}
                        {!isLeaderSpeak &&
                            <Box sx={{ my: 1, pointerEvents: isHiddenPost ? 'none' : 'auto', cursor: isHiddenPost ? 'none' : 'auto' }} className="text-center">
                                {/* {console.log({"first": post?.album?.carouselItems?.length > 1, "second" : post?.album?.carouselItems?.length === 1 })}
                            {console.log({"third": post?.album?.images?.length > 1, "forth" : post?.album?.images?.length === 1 })}
                            {console.log({"fifth": post?.image, "sixth" : post })} */}
                                {post?.album?.carouselItems?.length > 1 ? (
                                    <Box>
                                        <PhotoCollage multipleImages={carouselAdopter(post.album)} postId={post.id} indivisualAPI={indivisualAPI} feedPostData={post} feedPostName={feedPostName} />
                                    </Box>
                                ) : post?.album?.carouselItems?.length === 1 ? (
                                    <Box
                                        component={Link} to={encodeUrl()} state={{ feedPostData: post, feedPostName: feedPostName, indivisualAPI: indivisualAPI, reactPost: reactPost }}>
                                        <img
                                            style={{
                                                maxWidth: isXsScreen ? '60%' : '60%',
                                                maxHeight: isXsScreen ? '340px' : '340px',
                                                borderRadius: isXsScreen ? '2px' : '2px',
                                            }}
                                            src={post?.album?.carouselItems?.[0]?.carouselItem} />
                                    </Box>
                                ) : post?.album?.images?.length > 1 ? (
                                    <Box>
                                        <PhotoCollage multipleImages={post.album} postId={post.id} indivisualAPI={indivisualAPI} feedPostData={post} feedPostName={feedPostName} />
                                    </Box>
                                ) : post?.album?.images?.length === 1 ? (
                                    <Box
                                        component={Link} to={encodeUrl()} state={{ feedPostData: post, feedPostName: feedPostName, indivisualAPI: indivisualAPI, reactPost: reactPost }}>
                                        <img
                                            style={{
                                                maxWidth: isXsScreen ? '60%' : '60%',
                                                maxHeight: isXsScreen ? '340px' : '340px',
                                                borderRadius: isXsScreen ? '2px' : '2px',
                                            }}
                                            src={post?.album?.images[0]?.image} />
                                    </Box>
                                ) : post?.image ? (
                                    <Box

                                        component={Link} to={encodeUrl()} state={{ feedPostData: post, feedPostName: feedPostName, indivisualAPI: indivisualAPI, reactPost: reactPost }}>
                                        <img
                                            style={{
                                                maxWidth: isXsScreen ? '60%' : '60%',
                                                maxHeight: isXsScreen ? '340px' : '340px',
                                                borderRadius: isXsScreen ? '2px' : '2px',
                                            }} src={post.image} />
                                    </Box>
                                ) : null}
                            </Box>}
                    </Box>
                </Box>
                {!isHiddenPost && (
                    <Grid container spacing={1}>
                        <Grid item xs={7}>
                            <Box>
                                <Box>
                                    {showLikes(post)}
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={5} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Box component={Link} to={encodeUrl()} state={{ feedPostData: post, feedPostName: feedPostName, indivisualAPI: indivisualAPI }}>

                                {post.comments && post.comments.length ? (
                                    <Button variant="text" sx={{ textTransform: 'unset !important' }}>
                                        <Typography align='right' variant='body2' color='secondary'>{post.comments && post.comments.length} Comment{post.comments && post.comments.length !== 1 ? 's' : ''}</Typography>
                                    </Button>
                                ) : (
                                    null
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                )}
                <Divider />

                <Box sx={{ mt: 1, display: "flex", justifyContent: 'center' }}>
                    <Grid container spacing={1}>
                        <Grid item xs={6} sx={{ marginLeft: '-0.5rem' }}>
                            <Box sx={{ display: 'flex' }}>
                                <Box sx={{ marginRight: '3px', pointerEvents: isHiddenPost ? 'none' : 'auto', cursor: isHiddenPost ? 'none' : 'auto' }}>
                                    <IconButton aria-describedby={id} onMouseEnter={handlePostClick}>
                                        {selectedOption ? (
                                            <>
                                                {selectedOption === 1 && <Box sx={{ display: 'flex', alignItems: 'center' }}><Box sx={{ mr: 'xs' ? 0.5 : 1 }}><img src={Like} alt="Like" title="Like" className='icon-size' /></Box><Box sx={{ mb: 'xs' ? 1 : 2 }}><Typography variant='subtitle2' color='secondary'>Like</Typography></Box></Box>}
                                                {selectedOption === 2 && <Box sx={{ display: 'flex', alignItems: 'center' }}><Box sx={{ mr: 'xs' ? 0.5 : 1 }}><img src={Favourite} alt="Love" title="Love" className='icon-size' /></Box><Box sx={{ mb: 'xs' ? 1 : 2 }}><Typography variant='subtitle2' color='secondary'>Love</Typography></Box></Box>}
                                                {selectedOption === 3 && <Box sx={{ display: 'flex', alignItems: 'center' }}><Box sx={{ mr: 'xs' ? 0.5 : 1 }}><img src={Clapping} alt="Applaud" title="Applaud" className='icon-size' /></Box><Box sx={{ mb: 'xs' ? 1 : 2 }}><Typography variant='subtitle2' color='secondary'>Applaud</Typography></Box></Box>}
                                                {selectedOption === 4 && <Box sx={{ display: 'flex', alignItems: 'center' }}><Box sx={{ mr: 'xs' ? 0.5 : 1 }}><img src={Mood} alt="Curious" title="Curious" className='icon-size' /></Box><Box sx={{ 1: 'xs' ? 1 : 2 }}><Typography variant='subtitle2' color='secondary'>Curious</Typography></Box></Box>}
                                            </>
                                        ) : (
                                            <Box sx={{ display: 'flex' }}>
                                                <Box sx={{ mr: 'xs' ? 0.5 : 1 }}><ThumbUpOffAltIcon /></Box>
                                                <Box><Typography variant='subtitle2' color='secondary'>Like</Typography></Box>
                                            </Box>

                                        )}
                                    </IconButton>
                                    {!isHiddenPost && (
                                        <Popover
                                            id={id}
                                            open={openPost}
                                            anchorEl={anchorE4}
                                            onClose={handlePostClose}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'left',
                                            }}
                                        >
                                            <Box sx={{ p: 1, display: 'flex', justifyContent: 'space-between', cursor: 'pointer' }} onMouseLeave={handlePostClose}>

                                                <LightTooltip title="Like" placement="top-end" >
                                                    <Box
                                                        aria-label="Like"
                                                        sx={{}}
                                                        onClick={() => handleOptionClick('Like')}
                                                    // title="Like"
                                                    >
                                                        <img src={Like} />

                                                    </Box>
                                                </LightTooltip>
                                                <LightTooltip title="Applaud" placement="top-end">
                                                    <Box
                                                        aria-label="Applaud"
                                                        sx={{ mx: 1 }}
                                                        onClick={() => handleOptionClick('Applaud')}
                                                    // title="Applaud"
                                                    >
                                                        <img src={Clapping} />
                                                    </Box>
                                                </LightTooltip>
                                                <LightTooltip title="Love" placement="top-end">
                                                    <Box
                                                        aria-label="Love"
                                                        sx={{}}
                                                        onClick={() => handleOptionClick('Love')}
                                                    // title="Love"
                                                    >
                                                        <img src={Favourite} />
                                                    </Box>
                                                </LightTooltip>
                                                <LightTooltip title="Curious" placement="top-end">
                                                    <Box
                                                        aria-label="Curious"
                                                        sx={{ mx: 1 }}
                                                        onClick={() => handleOptionClick('Curious')}
                                                    // title="Curious"
                                                    >
                                                        <img src={Mood} />
                                                    </Box>
                                                </LightTooltip>
                                            </Box>
                                        </Popover>
                                    )}
                                </Box>
                                <Box sx={{ marginTop: '3px', pointerEvents: isHiddenPost ? 'none' : 'auto', cursor: isHiddenPost ? 'none' : 'auto' }}>
                                    <Button variant="text" startIcon={<ChatBubbleOutlineIcon color='secondary' />} component={Link} to={encodeUrl()} state={{ feedPostData: post, feedPostName: feedPostName, indivisualAPI: indivisualAPI, reactPost: reactPost }} sx={{ textTransform: 'unset !important' }}>
                                        <Typography variant='subtitle2' color='secondary'>Comment</Typography>
                                    </Button>
                                </Box>
                            </Box>
                        </Grid>
                        {/* <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Box sx={{ marginTop: '3px' }}>
                                <Button variant="text" startIcon={<ChatBubbleOutlineIcon color='secondary' />} component={Link} to={encodeUrl()} state={{ feedPostData: post, feedPostName: feedPostName, indivisualAPI: indivisualAPI, reactPost: reactPost }} sx={{ textTransform: 'unset !important' }}>
                                    <Typography variant='subtitle2' color='secondary'>Comment</Typography>
                                </Button>
                            </Box>
                        </Grid> */}
                    </Grid>
                </Box>
            </Box>
            <Menu
                id={"long-menu" + post.id}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <MenuList>
                    {getMenus(menus)}
                </MenuList>
            </Menu>
        </Box>
    )
}

export default Post